import truba from '../assets/img/catalog/truba_kr.png'

export const roundPipe = {
    list: [
        {
            id: 1,
            img: truba,
            image: truba,

            title: 'Труба бесшовная',
            description: '',
            columnsHeader: [
                {title: 'roundPipe.Column1', field: 'Column1'},
                {title: 'roundPipe.Column2', field: 'Column2'},
                {title: 'roundPipe.Column3', field: 'Column3'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст20, 09Г2С',
            type: ['ГОСТ 8732-78 Трубы стальные бесшовные горячедеформированные',
                'EN 10210:2006 Европейские трубы бесшовные стальные горячедеформированные.',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'
            ],


            titleEn: 'Труба бесшовная',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст20, 09Г2С',
            typeEn: ['ГОСТ 8732-78 Трубы стальные бесшовные горячедеформированные',
                'EN 10210:2006 Европейские трубы бесшовные стальные горячедеформированные.',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'
            ],
            table: [
                {title: 'Труба бесшовная', titleEn: 'Труба бесшовная', data: 'data1'},
            ],


            data1: [
                {Column1: '21,3', Column2: '3,2', Column3: '1,43'},
                {Column1: '32', Column2: '3,0', Column3: '2,15'},
                {Column1: '33,7', Column2: '3,2', Column3: '2,41'},
                {Column1: '33,7', Column2: '4,0', Column3: '2,93'},
                {Column1: '42,4', Column2: '3,2', Column3: '3,09'},
                {Column1: '42,4', Column2: '4,0', Column3: '3,79'},
                {Column1: '48,3', Column2: '3,2', Column3: '3,56'},
                {Column1: '48,3', Column2: '4,0', Column3: '4,37'},
                {Column1: '48,3', Column2: '5,0', Column3: '5,34'},
                {Column1: '57', Column2: '5,0', Column3: '6,41'},
                {Column1: '57', Column2: '6,0', Column3: '7,55'},
                {Column1: '57', Column2: '7,0', Column3: '8,63'},
                {Column1: '57', Column2: '8,0', Column3: '9,67'},
                {Column1: '57', Column2: '10,0', Column3: '11,59'},
                {Column1: '57', Column2: '12,0', Column3: '13,32'},
                {Column1: '57', Column2: '14,0', Column3: '14,93'},
                {Column1: '60', Column2: '3,5', Column3: '4,88'},
                {Column1: '60', Column2: '4,0', Column3: '5,52'},
                {Column1: '60', Column2: '5,0', Column3: '6,78'},
                {Column1: '60', Column2: '6,0', Column3: '7,99'},
                {Column1: '60', Column2: '8,0', Column3: '10,26'},
                {Column1: '60', Column2: '10,0', Column3: '12,33'},
                {Column1: '60', Column2: '12,0', Column3: '14,21'},
                {Column1: '60', Column2: '14,0', Column3: '15,88'},
                {Column1: '60,3', Column2: '3,2', Column3: '4,51'},
                {Column1: '60,3', Column2: '4,0', Column3: '5,55'},
                {Column1: '60,3', Column2: '5,0', Column3: '6,82'},
                {Column1: '73', Column2: '6,0', Column3: '9,91'},
                {Column1: '73', Column2: '8,0', Column3: '12,82'},
                {Column1: '73', Column2: '10,0', Column3: '15,54'},
                {Column1: '73', Column2: '12,0', Column3: '18,05'},
                {Column1: '73', Column2: '16,0', Column3: '22,49'},
                {Column1: '73', Column2: '18,0', Column3: '24,42'},
                {Column1: '73', Column2: '20,0', Column3: '26,29'},
                {Column1: '76', Column2: '6,0', Column3: '10,42'},
                {Column1: '76', Column2: '8,0', Column3: '13,49'},
                {Column1: '76', Column2: '10,0', Column3: '16,37'},
                {Column1: '76', Column2: '12,0', Column3: '19,05'},
                {Column1: '76', Column2: '16,0', Column3: '23,81'},
                {Column1: '76', Column2: '18,0', Column3: '25,90'},
                {Column1: '76', Column2: '20,0', Column3: '27,78'},
                {Column1: '88,9', Column2: '4,0', Column3: '8,38'},
                {Column1: '88,9', Column2: '5,0', Column3: '10,30'},
                {Column1: '88,9', Column2: '6,3', Column3: '12,80'},
                {Column1: '89', Column2: '6,0', Column3: '12,35'},
                {Column1: '89', Column2: '8,0', Column3: '16,07'},
                {Column1: '89', Column2: '10,0', Column3: '19,60'},
                {Column1: '89', Column2: '12,0', Column3: '22,92'},
                {Column1: '89', Column2: '16,0', Column3: '28,97'},
                {Column1: '89', Column2: '18,0', Column3: '31,70'},
                {Column1: '89', Column2: '20,0', Column3: '34,23'},
                {Column1: '89', Column2: '22,0', Column3: '36,56'},
                {Column1: '89', Column2: '24,0', Column3: '38,70'},
                {Column1: '101,6', Column2: '5,0', Column3: '11,90'},
                {Column1: '101,6', Column2: '6,3', Column3: '14,80'},
                {Column1: '101,6', Column2: '8,0', Column3: '18,50'},
                {Column1: '101,6', Column2: '10,0', Column3: '22,60'},
                {Column1: '108', Column2: '6,0', Column3: '15,18'},
                {Column1: '108', Column2: '8,0', Column3: '19,84'},
                {Column1: '108', Column2: '10,0', Column3: '24,31'},
                {Column1: '108', Column2: '12,0', Column3: '28,58'},
                {Column1: '108', Column2: '16,0', Column3: '36,51'},
                {Column1: '108', Column2: '18,0', Column3: '40,19'},
                {Column1: '108', Column2: '20,0', Column3: '43,66'},
                {Column1: '108', Column2: '22,0', Column3: '46,93'},
                {Column1: '108', Column2: '25,0', Column3: '51,47'},
                {Column1: '108', Column2: '30,0', Column3: '58,05'},
                {Column1: '114', Column2: '6,0', Column3: '16,07'},
                {Column1: '114', Column2: '8,0', Column3: '21,04'},
                {Column1: '114', Column2: '10,0', Column3: '25,80'},
                {Column1: '114', Column2: '12,0', Column3: '30,36'},
                {Column1: '114', Column2: '16,0', Column3: '38,90'},
                {Column1: '114', Column2: '18,0', Column3: '42,86'},
                {Column1: '114', Column2: '20,0', Column3: '46,64'},
                {Column1: '114', Column2: '22,0', Column3: '50,21'},
                {Column1: '114', Column2: '25,0', Column3: '55,19'},
                {Column1: '114', Column2: '30,0', Column3: '62,51'},
                {Column1: '114,3', Column2: '4,0', Column3: '10,90'},
                {Column1: '114,3', Column2: '6,3', Column3: '16,80'},
                {Column1: '114,3', Column2: '8,0', Column3: '21,00'},
                {Column1: '114,3', Column2: '10,0', Column3: '25,70'},
                {Column1: '127', Column2: '8,0', Column3: '23,62'},
                {Column1: '127', Column2: '10,0', Column3: '29,02'},
                {Column1: '127', Column2: '12,0', Column3: '35,23'},
                {Column1: '127', Column2: '16,0', Column3: '44,06'},
                {Column1: '127', Column2: '18,0', Column3: '48,67'},
                {Column1: '127', Column2: '20,0', Column3: '53,08'},
                {Column1: '127', Column2: '22,0', Column3: '57,30'},
                {Column1: '127', Column2: '25,0', Column3: '63,26'},
                {Column1: '127', Column2: '30,0', Column3: '72,19'},
                {Column1: '133', Column2: '6,0', Column3: '18,90'},
                {Column1: '133', Column2: '8,0', Column3: '24,81'},
                {Column1: '133', Column2: '10,0', Column3: '30,51'},
                {Column1: '133', Column2: '12,0', Column3: '36,00'},
                {Column1: '133', Column2: '16,0', Column3: '46,44'},
                {Column1: '133', Column2: '18,0', Column3: '51,35'},
                {Column1: '133', Column2: '20,0', Column3: '56,06'},
                {Column1: '133', Column2: '22,0', Column3: '60,58'},
                {Column1: '133', Column2: '25,0', Column3: '67,00'},
                {Column1: '133', Column2: '30,0', Column3: '76,65'},
                {Column1: '133', Column2: '36,0', Column3: '86,62'},
                {Column1: '152', Column2: '6,0', Column3: '21,73'},
                {Column1: '152', Column2: '8,0', Column3: '28,58'},
                {Column1: '152', Column2: '10,0', Column3: '35,22'},
                {Column1: '152', Column2: '12,0', Column3: '41,67'},
                {Column1: '152', Column2: '16,0', Column3: '53,98'},
                {Column1: '152', Column2: '18,0', Column3: '59,83'},
                {Column1: '152', Column2: '20,0', Column3: '65,49'},
                {Column1: '152', Column2: '22,0', Column3: '70,95'},
                {Column1: '152', Column2: '25,0', Column3: '78,76'},
                {Column1: '152', Column2: '30,0', Column3: '90,79'},
                {Column1: '152', Column2: '36,0', Column3: '103,59'},
                {Column1: '152', Column2: '40,0', Column3: '111,13'},
                {Column1: '159', Column2: '5,0', Column3: '19,10'},
                {Column1: '159', Column2: '6,0', Column3: '22,77'},
                {Column1: '159', Column2: '8,0', Column3: '30,00'},
                {Column1: '159', Column2: '10,0', Column3: '37,00'},
                {Column1: '159', Column2: '12,0', Column3: '43,80'},
                {Column1: '159', Column2: '16,0', Column3: '56,80'},
                {Column1: '159', Column2: '18,0', Column3: '63,00'},
                {Column1: '159', Column2: '20,0', Column3: '69,00'},
                {Column1: '159', Column2: '22,0', Column3: '74,80'},
                {Column1: '159', Column2: '25,0', Column3: '83,00'},
                {Column1: '159', Column2: '30,0', Column3: '96,00'},
                {Column1: '159', Column2: '36,0', Column3: '110,00'},
                {Column1: '159', Column2: '40,0', Column3: '118,08'},
                {Column1: '168', Column2: '6,0', Column3: '24,11'},
                {Column1: '168', Column2: '8,0', Column3: '31,75'},
                {Column1: '168', Column2: '10,0', Column3: '39,20'},
                {Column1: '168', Column2: '12,0', Column3: '46,44'},
                {Column1: '168', Column2: '16,0', Column3: '60,33'},
                {Column1: '168', Column2: '18,0', Column3: '67,00'},
                {Column1: '168', Column2: '20,0', Column3: '73,50'},
                {Column1: '168', Column2: '22,0', Column3: '79,70'},
                {Column1: '168', Column2: '25,0', Column3: '88,70'},
                {Column1: '168', Column2: '30,0', Column3: '102,70'},
                {Column1: '168', Column2: '36,0', Column3: '118,00'},
                {Column1: '168', Column2: '40,0', Column3: '127,00'},
                {Column1: '219', Column2: '6,0', Column3: '31,70'},
                {Column1: '219', Column2: '8,0', Column3: '41,90'},
                {Column1: '219', Column2: '10,0', Column3: '51,90'},
                {Column1: '219', Column2: '12,0', Column3: '61,62'},
                {Column1: '219', Column2: '16,0', Column3: '80,60'},
                {Column1: '219', Column2: '18,0', Column3: '89,80'},
                {Column1: '219', Column2: '20,0', Column3: '98,80'},
                {Column1: '219', Column2: '22,0', Column3: '107,50'},
                {Column1: '219', Column2: '25,0', Column3: '120,30'},
                {Column1: '219', Column2: '30,0', Column3: '140,65'},
                {Column1: '219', Column2: '36,0', Column3: '163,40'},
                {Column1: '219', Column2: '40,0', Column3: '177,60'},
                {Column1: '273', Column2: '6,0', Column3: '39,74'},
                {Column1: '273', Column2: '8,0', Column3: '52,59'},
                {Column1: '273', Column2: '10,0', Column3: '65,24'},
                {Column1: '273', Column2: '12,0', Column3: '77,70'},
                {Column1: '273', Column2: '16,0', Column3: '102,00'},
                {Column1: '273', Column2: '18,0', Column3: '113,90'},
                {Column1: '273', Column2: '20,0', Column3: '125,50'},
                {Column1: '273', Column2: '22,0', Column3: '137,00'},
                {Column1: '273', Column2: '25,0', Column3: '153,80'},
                {Column1: '273', Column2: '30,0', Column3: '180,90'},
                {Column1: '273', Column2: '36,0', Column3: '211,64'},
                {Column1: '273', Column2: '40,0', Column3: '231,20'},
                {Column1: '325', Column2: '8,0', Column3: '62,91'},
                {Column1: '325', Column2: '10,0', Column3: '78,14'},
                {Column1: '325', Column2: '12,0', Column3: '93,20'},
                {Column1: '325', Column2: '16,0', Column3: '122,64'},
                {Column1: '325', Column2: '18,0', Column3: '137,10'},
                {Column1: '325', Column2: '20,0', Column3: '151,32'},
                {Column1: '325', Column2: '22,0', Column3: '165,40'},
                {Column1: '325', Column2: '25,0', Column3: '186,10'},
                {Column1: '325', Column2: '30,0', Column3: '219,50'},
                {Column1: '325', Column2: '36,0', Column3: '258,10'},
                {Column1: '325', Column2: '40,0', Column3: '282,80'},
                {Column1: '325', Column2: '45,0', Column3: '312,60'},
                {Column1: '402', Column2: '10,0', Column3: '97,24'},
                {Column1: '402', Column2: '16,0', Column3: '153,20'},
                {Column1: '402', Column2: '20,0', Column3: '189,50'},
                {Column1: '402', Column2: '30,0', Column3: '276,90'},
                {Column1: '402', Column2: '40,0', Column3: '360,00'},
                {Column1: '402', Column2: '50,0', Column3: '437,00'},
                {Column1: '426', Column2: '10,0', Column3: '103,20'},
                {Column1: '426', Column2: '12,0', Column3: '123,50'},
                {Column1: '426', Column2: '16,0', Column3: '162,80'},
                {Column1: '426', Column2: '20,0', Column3: '201,50'},
                {Column1: '426', Column2: '30,0', Column3: '295,00'},
                {Column1: '426', Column2: '40,0', Column3: '383,00'},
                {Column1: '426', Column2: '50,0', Column3: '467,00'},
                {Column1: '450', Column2: '10,0', Column3: '110,00'},
                {Column1: '450', Column2: '20,0', Column3: '214,00'},
                {Column1: '450', Column2: '25,0', Column3: '264,00'},
                {Column1: '450', Column2: '30,0', Column3: '312,56'},
                {Column1: '450', Column2: '40,0', Column3: '407,00'},
                {Column1: '450', Column2: '50,0', Column3: '496,00'},
                {Column1: '530', Column2: '25,0', Column3: '314,00'},
                {Column1: '530', Column2: '30,0', Column3: '372,00'},
                {Column1: '530', Column2: '40,0', Column3: '487,00'},

            ],
        },
        {
            id: 2,
            img: truba,
            image: truba,

            title: 'Труба круглая стальные водогазопроводная',
            description: '',
            columnsHeader: [
                {title: 'roundPipe1.Column1', field: 'Column1'},
                {title: 'roundPipe1.Column2', field: 'Column2'},
                {title: 'roundPipe1.Column3', field: 'Column3'},
                {title: 'roundPipe1.Column4', field: 'Column4'},
                {title: 'roundPipe1.Column5', field: 'Column5'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст2пс, Ст2сп',
            type: ['ГОСТ 3262-75 Трубы стальные водогазопроводные',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'
            ],


            titleEn: 'Труба круглая стальные водогазопроводная',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст2пс, Ст2сп',
            typeEn:  ['ГОСТ 3262-75 Трубы стальные водогазопроводные',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'
            ],
            table: [
                {title: 'Труба круглая стальные водогазопроводная', titleEn: 'Труба круглая стальные водогазопроводная', data: 'data1'},
            ],


            data1: [
                {Column1: '15', Column2: '21,3', Column3: '2,5', Column4: '6000/7800/8250', Column5: '1,17'},
                {Column1: '15', Column2: '21,3', Column3: '2,8', Column4: '6000/7800', Column5: '1,28'},
                {Column1: '15', Column2: '21,3', Column3: '3,2', Column4: '6100/7800', Column5: '1,44'},
                {Column1: '20', Column2: '26,8', Column3: '2,5', Column4: '6000', Column5: '1,51'},
                {Column1: '20', Column2: '26,8', Column3: '2,8', Column4: '6000/8700', Column5: '1,66'},
                {Column1: '20', Column2: '26,8', Column3: '3,2', Column4: '6000', Column5: '1,87'},
                {Column1: '25', Column2: '33,5', Column3: '2,5', Column4: '6000/8000/8250', Column5: '1,92'},
                {Column1: '25', Column2: '33,5', Column3: '2,8', Column4: '6000/7800/8250', Column5: '2,13'},
                {Column1: '25', Column2: '33,5', Column3: '3,2', Column4: '6000/7800/10000', Column5: '2,41'},
                {Column1: '25', Column2: '33,5', Column3: '4,0', Column4: '8000/8250', Column5: '2,79'},
                {Column1: '32', Column2: '42,3', Column3: '2,8', Column4: '6000/7800/10000', Column5: '2,73'},
                {Column1: '32', Column2: '42,3', Column3: '3,2', Column4: '6000/9500/10000', Column5: '3,09'},
                {Column1: '32', Column2: '42,3', Column3: '4,0', Column4: '9300/9500', Column5: '3,85'},
                {Column1: '40', Column2: '48', Column3: '3,0', Column4: '6000/7800/10000', Column5: '3,33'},
                {Column1: '40', Column2: '48', Column3: '3,5', Column4: '6000/7800/12000', Column5: '3,84'},
                {Column1: '50', Column2: '60', Column3: '3,0', Column4: '6000/9500', Column5: '4,22'},
                {Column1: '50', Column2: '60', Column3: '3,5', Column4: '6000/9500', Column5: '4,88'},
                {Column1: '50', Column2: '60', Column3: '4,5', Column4: '9000/10000', Column5: '6,2'},
                {Column1: '65', Column2: '75,5', Column3: '4,0', Column4: '9500/10500', Column5: '7,05'},
                {Column1: '80', Column2: '85,5', Column3: '4,0', Column4: '10500/12000', Column5: '8,34'},
                {Column1: '100', Column2: '114', Column3: '3,0', Column4: '12000', Column5: '8,26'},
                {Column1: '100', Column2: '114', Column3: '4,0', Column4: '12000', Column5: '10,85'},
                {Column1: '100', Column2: '114', Column3: '4,5', Column4: '12000', Column5: '12,15'},

            ],
        },
        {
            id: 3,
            img: truba,
            image: truba,

            title: 'Труба круглая стальная электросварная',
            description: '',
            columnsHeader: [
                {title: 'roundPipe2.Column1', field: 'Column1'},
                {title: 'roundPipe2.Column2', field: 'Column2'},
                {title: 'roundPipe2.Column3', field: 'Column3'},
                {title: 'roundPipe2.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст2пс, Ст2сп',
            type: ['ГОСТ 10704-91 Трубы стальные электросварные прямошовные.',
                'ГОСТ 10705-80 Трубы стальные электросварные',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.',
            ],


            titleEn: 'Труба круглая стальная электросварная',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст2пс, Ст2сп',
            typeEn:  ['ГОСТ 10704-91 Трубы стальные электросварные прямошовные.',
                'ГОСТ 10705-80 Трубы стальные электросварные',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.',
            ],
            table: [
                {title: 'Труба круглая стальная электросварная', titleEn: 'Труба круглая стальная электросварная', data: 'data1'},
            ],


            data1: [
                {Column1: '25', Column2: '1,5', Column3: '6000', Column4: '0,87'},
                {Column1: '25', Column2: '2,0', Column3: '6000', Column4: '1,13'},
                {Column1: '25', Column2: '2,5', Column3: '6000', Column4: '1,39'},
                {Column1: '30', Column2: '1,5', Column3: '6000', Column4: '1,05'},
                {Column1: '30', Column2: '2,0', Column3: '6000', Column4: '1,38'},
                {Column1: '32', Column2: '1,5', Column3: '6000', Column4: '1,13'},
                {Column1: '32', Column2: '2,0', Column3: '6000', Column4: '1,48'},
                {Column1: '38', Column2: '2,5', Column3: '6000', Column4: '2,19'},
                {Column1: '38', Column2: '3,0', Column3: '6000', Column4: '2,59'},
                {Column1: '38', Column2: '4,0', Column3: '6000', Column4: '3,27'},
                {Column1: '42', Column2: '2,0', Column3: '6000', Column4: '1,97'},
                {Column1: '42', Column2: '2,5', Column3: '6000', Column4: '2,44'},
                {Column1: '42', Column2: '3,0', Column3: '6000', Column4: '2,89'},
                {Column1: '42', Column2: '4,0', Column3: '6000', Column4: '3,77'},
                {Column1: '45', Column2: '3,0', Column3: '6000', Column4: '3,11'},
                {Column1: '45', Column2: '3,5', Column3: '6000', Column4: '3,60'},
                {Column1: '45', Column2: '4,0', Column3: '6000', Column4: '4,07'},
                {Column1: '51', Column2: '2,5', Column3: '6000', Column4: '2,99'},
                {Column1: '51', Column2: '3,0', Column3: '6000', Column4: '3,55'},
                {Column1: '51', Column2: '3,5', Column3: '6000', Column4: '4,10'},
                {Column1: '57', Column2: '2,0', Column3: '6000', Column4: '2,71'},
                {Column1: '57', Column2: '3,0', Column3: '6000', Column4: '4,00'},
                {Column1: '57', Column2: '3,5', Column3: '6000/9000/12000', Column4: '4,62'},
                {Column1: '57', Column2: '4,0', Column3: '6000/9000/12000', Column4: '5,26'},
                {Column1: '60', Column2: '2,5', Column3: '6000/12000', Column4: '3,55'},
                {Column1: '60', Column2: '3,0', Column3: '6000/12000', Column4: '4,22'},
                {Column1: '76', Column2: '3,0', Column3: '6000/12000', Column4: '5,40'},
                {Column1: '76', Column2: '3,5', Column3: '6000/12000', Column4: '6,26'},
                {Column1: '76', Column2: '4,0', Column3: '6000/10500', Column4: '7,10'},
                {Column1: '76', Column2: '4,5', Column3: '6000/12000', Column4: '7,93'},
                {Column1: '89', Column2: '3,0', Column3: '6000/12000', Column4: '6,36'},
                {Column1: '89', Column2: '3,5', Column3: '6000/12000', Column4: '7,38'},
                {Column1: '89', Column2: '4,0', Column3: '6000/12000', Column4: '8,38'},
                {Column1: '89', Column2: '4,5', Column3: '6000/12000', Column4: '9,38'},
                {Column1: '89', Column2: '5,0', Column3: '6000/12000', Column4: '10,36'},
                {Column1: '102', Column2: '3,0', Column3: '12000', Column4: '7,32'},
                {Column1: '102', Column2: '3,5', Column3: '12000', Column4: '8,50'},
                {Column1: '102', Column2: '4,0', Column3: '12000', Column4: '9,67'},
                {Column1: '102', Column2: '4,5', Column3: '12000', Column4: '10,82'},
                {Column1: '102', Column2: '5,0', Column3: '12000', Column4: '11,96'},
                {Column1: '108', Column2: '3,0', Column3: '12000', Column4: '7,77'},
                {Column1: '108', Column2: '3,5', Column3: '12000', Column4: '9,02'},
                {Column1: '108', Column2: '4,0', Column3: '12000', Column4: '10,26'},
                {Column1: '108', Column2: '5,0', Column3: '12000', Column4: '12,70'},
                {Column1: '108', Column2: '6,0', Column3: '12000', Column4: '15,18'},
                {Column1: '114', Column2: '3,0', Column3: '12000', Column4: '8,21'},
                {Column1: '114', Column2: '3,5', Column3: '12000', Column4: '9,54'},
                {Column1: '114', Column2: '4,0', Column3: '12000', Column4: '10,85'},
                {Column1: '114', Column2: '5,0', Column3: '12000', Column4: '13,44'},
                {Column1: '114', Column2: '6,0', Column3: '12000', Column4: '16,07'},
                {Column1: '114', Column2: '7,0', Column3: '12000', Column4: '18,58'},
                {Column1: '127', Column2: '4,0', Column3: '10000/12000', Column4: '12,20'},
                {Column1: '127', Column2: '4,5', Column3: '10000/12000', Column4: '13,59'},
                {Column1: '127', Column2: '6,0', Column3: '10000/12000', Column4: '18,01'},
                {Column1: '133', Column2: '4,0', Column3: '12000', Column4: '12,80'},
                {Column1: '133', Column2: '4,5', Column3: '12000', Column4: '14,30'},
                {Column1: '133', Column2: '5,0', Column3: '12000', Column4: '15,78'},
                {Column1: '133', Column2: '6,0', Column3: '12000', Column4: '18,90'},
                {Column1: '159', Column2: '4,0', Column3: '12000', Column4: '15,29'},
                {Column1: '159', Column2: '4,5', Column3: '12000', Column4: '17,15'},
                {Column1: '159', Column2: '5,0', Column3: '12000', Column4: '19,00'},
                {Column1: '159', Column2: '6,0', Column3: '12000', Column4: '22,64'},
                {Column1: '159', Column2: '7,0', Column3: '12000', Column4: '26,24'},
                {Column1: '159', Column2: '8,0', Column3: '12000', Column4: '29,97'},
                {Column1: '168', Column2: '4,0', Column3: '12000', Column4: '16,18'},
                {Column1: '168', Column2: '5,0', Column3: '12000', Column4: '20,10'},
                {Column1: '168', Column2: '6,0', Column3: '12000', Column4: '23,97'},
                {Column1: '168', Column2: '7,0', Column3: '12000', Column4: '27,79'},
                {Column1: '168', Column2: '8,0', Column3: '12000', Column4: '31,57'},
                {Column1: '219', Column2: '4,0', Column3: '12000', Column4: '21,21'},
                {Column1: '219', Column2: '5,0', Column3: '12000', Column4: '26,39'},
                {Column1: '219', Column2: '6,0', Column3: '12000', Column4: '31,52'},
                {Column1: '219', Column2: '7,0', Column3: '12000', Column4: '36,60'},
                {Column1: '219', Column2: '8,0', Column3: '12000', Column4: '41,63'},
                {Column1: '219', Column2: '10,0', Column3: '12000', Column4: '51,84'},
                {Column1: '273', Column2: '5,0', Column3: '12000', Column4: '33,05'},
                {Column1: '273', Column2: '6,0', Column3: '12000', Column4: '39,51'},
                {Column1: '273', Column2: '7,0', Column3: '12000', Column4: '45,92'},
                {Column1: '273', Column2: '8,0', Column3: '12000', Column4: '52,28'},
                {Column1: '273', Column2: '10,0', Column3: '12000', Column4: '65,24'},
                {Column1: '325', Column2: '5,0', Column3: '12000', Column4: '39,46'},
                {Column1: '325', Column2: '6,0', Column3: '12000', Column4: '47,20'},
                {Column1: '325', Column2: '7,0', Column3: '12000', Column4: '54,90'},
                {Column1: '325', Column2: '8,0', Column3: '12000', Column4: '62,54'},
                {Column1: '325', Column2: '10,0', Column3: '12000', Column4: '78,14'},
                {Column1: '426', Column2: '6,0', Column3: '12000', Column4: '62,15'},
                {Column1: '426', Column2: '8,0', Column3: '12000', Column4: '82,47'},
                {Column1: '426', Column2: '10,0', Column3: '12000', Column4: '102,59'},
                {Column1: '426', Column2: '12,0', Column3: '12000', Column4: '122,52'},
                {Column1: '530', Column2: '6,0', Column3: '12000', Column4: '77,54'},
                {Column1: '530', Column2: '8,0', Column3: '12000', Column4: '102,99'},
                {Column1: '530', Column2: '10,0', Column3: '12000', Column4: '128,24'},
                {Column1: '530', Column2: '12,0', Column3: '12000', Column4: '153,30'},
                {Column1: '630', Column2: '7,0', Column3: '12000', Column4: '107,55'},
                {Column1: '630', Column2: '8,0', Column3: '12000', Column4: '122,72'},
                {Column1: '630', Column2: '10,0', Column3: '12000', Column4: '152,90'},
                {Column1: '630', Column2: '12,0', Column3: '12000', Column4: '182,89'},
                {Column1: '630', Column2: '14,0', Column3: '12000', Column4: '212,68'},
                {Column1: '720', Column2: '8,0', Column3: '12000', Column4: '140,47'},
                {Column1: '720', Column2: '10,0', Column3: '12000', Column4: '175,10'},
                {Column1: '720', Column2: '12,0', Column3: '12000', Column4: '209,52'},
                {Column1: '720', Column2: '14,0', Column3: '12000', Column4: '243,75'},

            ],
        },
        {
            id: 4,
            img: truba,
            image: truba,

            title: 'Труба бесшовная по EN и ASTM',
            description: '',
            columnsHeader: [
                {title: 'roundPipe4.Column1', field: 'Column1'},
                {title: 'roundPipe4.Column2', field: 'Column2'},
                {title: 'roundPipe4.Column3', field: 'Column3'},
                {title: 'roundPipe4.Column4', field: 'Column4'},
                {title: 'roundPipe4.Column5', field: 'Column5'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: S275J0H, S275J2H, S355J0H, S355J2H, S275NH, S275NLH, S355NLH, ASTM A333 Gr6, A106GrB',
            type: [],


            titleEn: 'Труба бесшовная по EN и ASTM',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn:'Марки стали: S275J0H, S275J2H, S355J0H, S355J2H, S275NH, S275NLH, S355NLH, ASTM A333 Gr6, A106GrB',
            typeEn:  [],
            table: [
                {title: 'Труба бесшовная по EN и ASTM', titleEn: 'Труба бесшовная по EN и ASTM', data: 'data1'},
            ],

            data1: [
                {Column1: '1', Column2: '25', Column3: '33,4', Column4: '40/80/STD/XS', Column5: '6000'},
                {Column1: '2', Column2: '50', Column3: '60,3', Column4: '40/80/STD/XS', Column5: '6000'},
                {Column1: '3', Column2: '80', Column3: '88,9', Column4: '40/80/STD/XS', Column5: '6000'},
                {Column1: '4', Column2: '100', Column3: '114,3', Column4: '40/80/STD/XS', Column5: '6000'},
                {Column1: '6', Column2: '150', Column3: '168,3', Column4: '40/80/STD/XS', Column5: '6000'},
                {Column1: '8', Column2: '200', Column3: '219,1', Column4: '40/80/STD/XS', Column5: '6000'}
            ],
        },
        {
            id: 5,
            img: truba,
            image: truba,

            title: 'Труба круглая стальные водогазопроводная оцинкованная',
            description: '',
            columnsHeader: [
                {title: 'roundPipe5.Column1', field: 'Column1'},
                {title: 'roundPipe5.Column2', field: 'Column2'},
                {title: 'roundPipe5.Column3', field: 'Column3'},
                {title: 'roundPipe5.Column4', field: 'Column4'},
                {title: 'roundPipe5.Column5', field: 'Column5'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст2пс, Ст2сп',
            type: ['ГОСТ 3262-75 Трубы стальные водогазопроводные', 'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'],


            titleEn: 'Труба круглая стальные водогазопроводная оцинкованная',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст2пс, Ст2сп',
            typeEn:   ['ГОСТ 3262-75 Трубы стальные водогазопроводные', 'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'],
            table: [
                {title: 'Труба круглая стальные водогазопроводная оцинкованная', titleEn: 'Труба круглая стальные водогазопроводная оцинкованная', data: 'data1'},
            ],

            data1: [
                {Column1: '25', Column2: '33.5', Column3: '3,2', Column4: '7800', Column5: '2,41'},
                {Column1: '32', Column2: '42.3', Column3: '3,2', Column4: '7800', Column5: '3,09'},
                {Column1: '40', Column2: '48', Column3: '3,5', Column4: '7800', Column5: '3,84'},
                {Column1: '40', Column2: '48', Column3: '4,0', Column4: '7800', Column5: '4,15'},
                {Column1: '50', Column2: '60', Column3: '3,0', Column4: '7800', Column5: '4,22'},
                {Column1: '50', Column2: '60', Column3: '3,5', Column4: '7800', Column5: '4,88'},
                {Column1: '65', Column2: '75,5', Column3: '4,0', Column4: '7800', Column5: '7,05'},
                {Column1: '80', Column2: '88,5', Column3: '4,0', Column4: '7800', Column5: '8,34'},
                {Column1: '100', Column2: '114', Column3: '4,0', Column4: '6000', Column5: '10,85'},
                {Column1: '100', Column2: '114', Column3: '4,5', Column4: '6000', Column5: '12,15'}
            ],
        }
    ],
    table: {
        1: {},
        2: {},
        3: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
    }
}
