import React from "react";
import {connect} from "react-redux";
import Flooring from "../ui/Catalog/Flooring";


const FlooringController = (props) => {
    return <div>
        <Flooring {...props}/>
    </div>
};

const mapState = (state) => {
   return {
       data: state.catalogPage.flooring
   }
};

export default connect(mapState, null)(FlooringController)
