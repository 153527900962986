import React from 'react'
import {translate} from "react-i18next";

const WeldedDetails = (props) => {
    const {t, details} = props;
    return <div className={"content-form__list"} style={{textAlign: "center"}}>
        <div>
            <h2 style={{fontSize: 36}}>{details[t('welded.title1')]}</h2>
            <div>
                <span style={{fontSize: 20}}>{details[t('welded.description1')]}</span>
            </div>
            <div>
                <h3 style={{fontSize: 18}}>{details[t('welded.tableHeader')]}</h3>
            </div>
            <div style={{justifyContent: 'center',alignItems:'center', display:"flex",flexDirection:'column'}}>
            {details[t('welded.tableData1')].map((d, index) => {
                return index === 0 || index === 2 ? <div
                    style={{background: '#cbdcff', width: 400, textAlign: "center"}}><span style={{fontSize:20}}
                    >{d}</span></div>
                : <div
                        style={{background: 'white', width: 400, textAlign: "center"}}><span style={{fontSize:20}}
                        >{d}</span></div>
            })}
            </div>
            <h2 style={{fontSize: 36}}>{details[t('welded.title2')]}</h2>
            <div>
                <span style={{fontSize: 20}}>{details[t('welded.description2')]}</span>
            </div>
            <div>
                <h3 style={{fontSize: 18}}>{details[t('welded.tableHeader')]}</h3>
            </div>
            <div style={{justifyContent: 'center',alignItems:'center', display:"flex",flexDirection:'column'}}>
            {details[t('welded.tableData2')].map((d, index) => {
                return index === 0 || index === 2 ? <div
                        style={{background: '#cbdcff', width: 400, textAlign: "center"}}><span style={{fontSize:20}}
                    >{d}</span></div>
                    : <div
                        style={{background: 'white', width: 400, textAlign: "center"}}><span style={{fontSize:20}}
                    >{d}</span></div>
            })}
            </div>
            <h2 style={{fontSize: 36}}>{details[t('welded.title3')]}</h2>
            <div>
                <span style={{fontSize: 20}}>{details[t('welded.description3')]}</span>
            </div>
            <div>
                <h3 style={{fontSize: 18}}>{details[t('welded.tableHeader')]}</h3>
            </div>
            <div style={{justifyContent: 'center',alignItems:'center', display:"flex",flexDirection:'column'}}>
            {details[t('welded.tableData3')].map((d, index) => {
                return index === 0 || index === 2 ? <div
                        style={{background: '#cbdcff', width: 400, textAlign: "center"}}><span style={{fontSize:20}}
                    >{d}</span></div>
                    : <div
                        style={{background: 'white', width: 400, textAlign: "center"}}><span style={{fontSize:20}}
                    >{d}</span></div>
            })}
            </div>
        </div>
    </div>
};

export default translate("common")(WeldedDetails);
