import React, {Fragment} from 'react';
import {Header} from "./ui/Header/";
import {Route} from "react-router-dom";
import './resources/less/styles.less'
import {translate} from "react-i18next";
import {CatalogWrapper} from "./ui/Catalog";
import HomePage from "./ui/HomePage/HomePage";
import {Footer} from "./ui/Footer";
import {BeamController, ChannelController, PipeController, WeldedGridController} from "./controllers/";
import {Delivery} from "./ui/Delivery";
import {About} from "./ui/About/";
import {Services} from "./ui/Services";
import CornerController from "./controllers/CornerController";
import RoundPipeController from "./controllers/RoundPipeController";
import FlooringContainer from "./controllers/FlooringContainer";
import Slide from "./ui/Slide/Slide";
import BlackSteel from "./ui/Catalog/BlackSteel";
import SheetSteelController from "./controllers/SheetSteelController";
import NoCorrosionSteelController from "./controllers/NoCorosionSteelController";
import PanelController from "./controllers/PanelController";
import ProfFlooringlController from "./controllers/ProfFlooringlController";
import ShtripsController from "./controllers/ShtripsController";
import Contacts from "./ui/Contacts/Contacts";
import Certificates from "./ui/Certificates/Certificates";

const App = () => {
    return <Fragment>
        <div>
            <Header/>
        </div>
        <section className="content home">
            <section className="content">
                <div className="content-form">

                    <div className={"content-form__sidebar"}>
                        <CatalogWrapper/>
                        <Route exact path={'/'} render={() => <Slide/>}/>
                        <Route exact path={'/beam/:id?'} render={() => <BeamController/>}/>
                        <Route exact path={'/channel/:id?'} render={() => <ChannelController/>}/>
                        <Route exact path={'/profilePipe/:id?'} render={() => <PipeController/>}/>
                        <Route exact path={'/corner/:id?'} render={() => <CornerController/>}/>
                        <Route exact path={'/roundpipe/:id?'} render={() => <RoundPipeController/>}/>
                        <Route exact path={'/flooring'} render={() => <FlooringContainer/>}/>
                        <Route exact path={'/weldedgrid'} render={() => <WeldedGridController/>}/>
                        <Route exact path={'/delivery'} render={() => <Delivery/>}/>
                        <Route exact path={'/about'} render={() => <About/>}/>
                        <Route exact path={'/services'} render={() => <Services/>}/>
                        <Route exact path={'/blacksteel'} render={() => <BlackSteel/>}/>
                        <Route exact path={'/no-corrosion-steel/:id?'} render={() => <NoCorrosionSteelController/>}/>
                        <Route exact path={'/sheet-steel/:id?'} render={() => <SheetSteelController/>}/>
                        <Route exact path={'/panel/:id?'} render={() => <PanelController/>}/>
                        <Route exact path={'/prof-flooring'} render={() => <ProfFlooringlController/>}/>
                        <Route exact path={'/shtrips'} render={() => <ShtripsController/>}/>
                        <Route exact path={'/contacts'} render={() => <Contacts/>}/>
                        <Route exact path={'/certificates'} render={() => <Certificates/>}/>

                    </div>

                    <Route exact path={['/', '/blacksteel', '/nocorrosionsteel']} render={() => <HomePage/>}/>


                </div>


            </section>
        </section>

        <Footer/>
    </Fragment>
};

export default translate("common")(App);

