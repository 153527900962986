import React from 'react'
import {translate} from "react-i18next";

const FlooringDetails = (props) => {
    const {t, details} = props;
    return <div className={"content-form__list"}>
        <div>
            <h2>{details[t('flooring.title1')]}</h2>
            <div>
                <span>{details[t('flooring.description1')]}</span>
            </div>
            <h2>{details[t('flooring.title2')]}</h2>
            <div>
                <span>{details[t('flooring.description2')]}</span>
            </div>
            <h2>{details[t('flooring.title3')]}</h2>
            <div>
                <span>{details[t('flooring.description3')]}</span>
            </div>
            <h2>{details[t('flooring.title4')]}</h2>
            <div>
                <span>{details[t('flooring.description4')]}</span>
            </div>
            <h2>{details[t('flooring.title5')]}</h2>
            <ul inlist={'disc'}>
                {details[t('flooring.list5')].map(l => {
                    return <li>
                       <span style={{color: "red"}}>*</span> {l}
                    </li>
                })}
            </ul>
            <h2>{details[t('flooring.title6')]}</h2>
            <ul>
                {details[t('flooring.list6')].map(l => {
                    return <li>
                        <span style={{color: "red"}}>*</span> {l}
                    </li>
                })}
            </ul>

        </div>
    </div>
};

export default translate("common")(FlooringDetails);
