import React, {Fragment} from 'react'
import {translate} from "react-i18next";
import '../../resources/less/styles.less'
import {compose} from "redux";

const HeaderTop = (props) => {
    const {t,  i18n} = props;
    return <Fragment>
                <div className={'header-wrap__top'}>

                </div>
    </Fragment>
};

export default compose(
    translate("common"),
)(HeaderTop);