import profFlooringImg from '../assets/img/catalog/profFlooringIMG.jpg'


export const profFlooring = {
    list: [
        {
            id: 1,
            img: profFlooringImg,
            image: profFlooringImg,

            title: 'Профилированный настил',
            description: '',
            columnsHeader: [
                {title: 'panel.Column1', field: 'Column1'},
                {title: 'panel.Column2', field: 'Column2'},
                {title: 'panel.Column3', field: 'Column3'},
                {title: 'panel.Column4', field: 'Column4'},
                {title: 'panel.Column5', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Примечание: *Длины подрезаются под любой индивидуальный размер',
            type: ['Цвета RAL:', '- RAL 5002 - Ультрамарин; RAL 5005 - Сигнально-синий;', '- RAL 6005 - Зеленый мох; RAL 7004 - Сигнально-серый;', '- RAL 7035 - Светло-серый; RAL 8017 - Шоколад;', '- RAL 9003 - Сигнально-белый; RAL 9006 - Светло-алюминий;', '- Оцинкованный'],


            titleEn: 'Профилированный настил',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Примечание: *Длины подрезаются под любой индивидуальный размер',
            typeEn: ['Цвета RAL:', '- RAL 5002 - Ультрамарин; RAL 5005 - Сигнально-синий;', '- RAL 6005 - Зеленый мох; RAL 7004 - Сигнально-серый;', '- RAL 7035 - Светло-серый; RAL 8017 - Шоколад;', '- RAL 9003 - Сигнально-белый; RAL 9006 - Светло-алюминий;', '- Оцинкованный'],
            table: [
                {title: 'Профилированный настил', titleEn: 'Профилированный настил', data: 'data1'},
            ],


            data1: [
                {Column1: 'С-8', Column2: '0,45-1', Column3: '8', Column4: '1200/1150',  Column5: '3000-12000'},
                {Column1: 'С-10', Column2: '0,45-1', Column3: '10', Column4: '1150/1100',  Column5: '3000-12000'},
                {Column1: 'С-21', Column2: '0,45-1', Column3: '21', Column4: '1050/1000',  Column5: '3000-12000'},
                {Column1: 'МП-20', Column2: '0,45-1', Column3: '18', Column4: '1150/1100',  Column5: '3000-12000'},
                {Column1: 'НС-35', Column2: '0,45-1', Column3: '35', Column4: '1060/100',  Column5: '3000-12000'},
                {Column1: 'Н-44', Column2: '0,45-1', Column3: '44', Column4: '1050/1000',  Column5: '3000-12000'},
                {Column1: 'Н-60', Column2: '0,45-1', Column3: '60', Column4: '902/845',  Column5: '3000-12000'},
                {Column1: 'Н-75', Column2: '0,45-1', Column3: '75', Column4: '800/750',  Column5: '3000-12000'},

            ]
        }
    ],
    table: {
        1: {},
        2: {},
        3: {},
        4: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
        4: {},
    },

};
