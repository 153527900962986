export const welded = {
    title1: 'Арматурная сетка 100x100',
    title1En: "Арматурная сетка 100x100",
    description1: 'Размер карты 2000x6000 мм. Марка стали B500C.',
    description1En: 'Размер карты 2000x6000 мм. Марка стали B500C.',


    title2: 'Арматурная сетка 150x150',
    title2En: 'Арматурная сетка 150x150',
    description2: 'Размер карты 2000x6000 мм. Марка стали B500C.',
    description2En: 'Размер карты 2000x6000 мм. Марка стали B500C.',

    title3: 'Арматурная сетка 200x200',
    title3En: 'Арматурная сетка 200x200',
    description3: 'Размер карты 2000x6000 мм. Марка стали B500C.',
    description3En: 'Размер карты 2000x6000 мм. Марка стали B500C.',


    tableHeader: "Размеры ячеек и арматуры, мм.",
    tableHeaderEn: "Размеры ячеек и арматуры, мм.",

    tableData1: ['Сварная сетка 100х100х6', 'Сварная сетка 100х100х8', 'Сварная сетка 100х100х10', 'Сварная сетка 100х100х12'],
    tableData1En: ['Сварная сетка 100х100х 6', 'Сварная сетка 100х100х 8', 'Сварная сетка 100х100х 10', 'Сварная сетка 100х100х 12'],

    tableData2: ['Сварная сетка 150х150х6', 'Сварная сетка 150х150х8', 'Сварная сетка 150х150х10', 'Сварная сетка 150х150х12'],
    tableData2En: ['Сварная сетка 100х100х 6', 'Сварная сетка 100х100х 8', 'Сварная сетка 100х100х 10', 'Сварная сетка 100х100х 12'],

    tableData3: ['Сварная сетка 200х200х6', 'Сварная сетка 200х200х8', 'Сварная сетка 200х200х10', 'Сварная сетка 200х200х12'],
    tableData3En: ['Сварная сетка 100х100х 6', 'Сварная сетка 100х100х 8', 'Сварная сетка 100х100х 10', 'Сварная сетка 100х100х 12'],

};
