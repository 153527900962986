import shveller from '../assets/img/catalog/shveller.png'
import zzz_shveller_model from '../assets/img/catalog/zzz_shveller_model_nw.gif'
import schveller_sheme from '../assets/img/catalog/schveller_sheme.gif'

export const channel = {
    list: [
        {
            id: 1,
            img: shveller,
            image: zzz_shveller_model,

            title: 'Швеллер UPE',
            description: 'ШВЕЛЛЕР UPE – швеллер стальной с параллельными гранями полок.',
            columnsHeader: [
                {title: 'chanelUPE.Column1', field: 'Column1'},
                {title: 'chanelUPE.Column2', field: 'Column2'},
                {title: 'chanelUPE.Column3', field: 'Column3'},
                {title: 'chanelUPE.Column4', field: 'Column4'},
                {title: 'chanelUPE.Column5', field: 'Column5'},
                {title: 'chanelUPE.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Двутавры горячекатаные с параллельными гранями полок',
            listDetails: [''],
            steel: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            type: ['DIN 1026-1: 2000, NF A 45-202: 1986',
                'EN 10163-3: 2004, класс C, субкласс 1 (Качество поверхности)'
            ],

            titleEn: 'Швеллер UPE',
            descriptionEn: 'ШВЕЛЛЕР UPE – швеллер стальной с параллельными гранями полок.',
            descriptionDetailsEn: 'Двутавры горячекатаные с параллельными гранями полок',
            listDetailsEn: [''],
            steelEn: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            typeEn:['DIN 1026-1: 2000, NF A 45-202: 1986',
                'EN 10163-3: 2004, класс C, субкласс 1 (Качество поверхности)'
            ],
            table: [
                {title: 'Швеллер UPE', titleEn: 'Швеллер UPE', data: 'data1'},
            ],

            data1: [
                {Column1: 'UPE 80', Column2: '7,9', Column3: 80, Column4: 50, Column5: '4,0', Column6: '7,0'},
                {Column1: 'UPE 100', Column2: '9,82', Column3: 100, Column4: 55, Column5: '4,5', Column6: '7,5'},
                {Column1: 'UPE 120', Column2: '12,1', Column3: 120, Column4: 60, Column5: '5,0', Column6: '8,0'},
                {Column1: 'UPE 140', Column2: '14,5', Column3: 140, Column4: 65, Column5: '5,0', Column6: '9,0'},
                {Column1: 'UPE 160', Column2: '17,0', Column3: 160, Column4: 70, Column5: '5,5', Column6: '9,5'},
                {Column1: 'UPE 180', Column2: '19,7', Column3: 180, Column4: 75, Column5: '5,5', Column6: '10,5'},
                {Column1: 'UPE 200', Column2: '22,8', Column3: 200, Column4: 80, Column5: '6,0', Column6: '11,0'},
                {Column1: 'UPE 220', Column2: '26,6', Column3: 220, Column4: 85, Column5: '6,5', Column6: '12,0'},
                {Column1: 'UPE 240', Column2: '30,2', Column3: 240, Column4: 90, Column5: '7,0', Column6: '12,5'},
                {Column1: 'UPE 270', Column2: '35,2', Column3: 270, Column4: 95, Column5: '7,5', Column6: '13,5'},
                {Column1: 'UPE 300', Column2: '44,4', Column3: 300, Column4: 100, Column5: '9,5', Column6: '15,0'},
                {Column1: 'UPE 330', Column2: '53,2', Column3: 330, Column4: 105, Column5: '11,0', Column6: '16,0'},
                {Column1: 'UPE 360', Column2: '61,2', Column3: 360, Column4: 110, Column5: '12,0', Column6: '17,0'},
                {Column1: 'UPE 400', Column2: '72,2', Column3: 400, Column4: 115, Column5: '13,5', Column6: '18,0'}
            ],
        },
        {
            id: 2,
            img: shveller,
            image: zzz_shveller_model,

            title: 'ШВЕЛЛЕР UPN ',
            description: 'ШВЕЛЛЕР UPN – швеллер стальной с уклоном внутренних граней полок.',
            columnsHeader: [
                {title: 'chanelGOST824089.Column1', field: 'Column1'},
                {title: 'chanelGOST824089.Column2', field: 'Column2'},
                {title: 'chanelGOST824089.Column3', field: 'Column3'},
                {title: 'chanelGOST824089.Column4', field: 'Column4'},
                {title: 'chanelGOST824089.Column5', field: 'Column5'},
                {title: 'chanelGOST824089.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'ШВЕЛЛЕР UPN – швеллер стальной с уклоном внутренних граней полок.',
            listDetails: [''],
            steel: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            type: ['DIN 1026-1: 2000',
                'NF A 45-202: 1986',
                'EN 10163-3: 2004, класс C, субкласс 1 (Качество поверхности)'
            ],


            titleEn: 'ШВЕЛЛЕР UPN',
            descriptionEn: 'ШВЕЛЛЕР UPN – швеллер стальной с уклоном внутренних граней полок.',
            descriptionDetailsEn: 'ШВЕЛЛЕР UPN – швеллер стальной с уклоном внутренних граней полок.',
            listDetailsEn: [''],
            steelEn: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            typeEn:['DIN 1026-1: 2000',
                'NF A 45-202: 1986',
                'EN 10163-3: 2004, класс C, субкласс 1 (Качество поверхности)'
            ],
            table: [
                {title: 'ШВЕЛЛЕР UPN', titleEn: 'ШВЕЛЛЕР UPN', data: 'data1'},
            ],

            data1: [
                {Column1: 'UPN 50', Column2: '5,59', Column3: '50', Column4: 38, Column5: '5,0', Column6: '7,0'},
                {Column1: 'UPN 60', Column2: '5,07', Column3: '60', Column4: 30, Column5: '6,0', Column6: '6,0'},
                {Column1: 'UPN 80', Column2: '8,64', Column3: '80', Column4: 45, Column5: '6,0', Column6: '8,0'},
                {Column1: 'UPN 100', Column2: '10,60', Column3: '100', Column4: 50, Column5: '6,0', Column6: '8,5'},
                {Column1: 'UPN 120', Column2: '13,40', Column3: '120', Column4: 55, Column5: '7,0', Column6: '9,0'},
                {Column1: 'UPN 140', Column2: '16,00', Column3: '140', Column4: 60, Column5: '7,0', Column6: '10,0'},
                {Column1: 'UPN 160', Column2: '18,80', Column3: '160', Column4: 65, Column5: '7,5', Column6: '10,5'},
                {Column1: 'UPN 180', Column2: '22,00', Column3: '180', Column4: 70, Column5: '8,0', Column6: '11,0'},
                {Column1: 'UPN 200', Column2: '25,30', Column3: '200', Column4: 75, Column5: '8,5', Column6: '11,5'},
                {Column1: 'UPN 220', Column2: '29,40', Column3: '220', Column4: 80, Column5: '9,0', Column6: '12,5'},
                {Column1: 'UPN 240', Column2: '33,20', Column3: '240', Column4: 85, Column5: '9,5', Column6: '13,0'},
                {Column1: 'UPN 260', Column2: '37,90', Column3: '260', Column4: 90, Column5: '10,0', Column6: '14,0'},
                {Column1: 'UPN 280', Column2: '41,80', Column3: '280', Column4: 95, Column5: '10,0', Column6: '15,0'},
                {Column1: 'UPN 300', Column2: '46,20', Column3: '300', Column4: 100, Column5: '10,0', Column6: '16,0'},
                {Column1: 'UPN 320', Column2: '59,50', Column3: '320', Column4: 100, Column5: '14,0', Column6: '17,0'},
                {Column1: 'UPN 350', Column2: '60,60', Column3: '350', Column4: 100, Column5: '14,0', Column6: '16,0'},
                {Column1: 'UPN 380', Column2: '63,10', Column3: '380', Column4: 102, Column5: '13,5', Column6: '16,0'},
                {Column1: 'UPN 400', Column2: '71,80', Column3: '400', Column4: 110, Column5: '14,0', Column6: '18,0'}
            ],
        },
        {
            id: 3,
            img: shveller,
            image: schveller_sheme,

            title: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89',
            description: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89',
            columnsHeader: [
                {title: 'chanelGOST824089.Column1', field: 'Column1'},
                {title: 'chanelGOST824089.Column2', field: 'Column2'},
                {title: 'chanelGOST824089.Column3', field: 'Column3'},
                {title: 'chanelGOST824089.Column4', field: 'Column4'},
                {title: 'chanelGOST824089.Column5', field: 'Column5'},
                {title: 'chanelGOST824089.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89',
            listDetails: [''],
            steel: 'Марки стали: Ст3кп, Ст3пс, Ст3сп, С235; С245; С255; С275; С285; С345.',
            type: ['ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой ',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.'
            ],

            titleEn: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89',
            descriptionEn: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89',
            descriptionDetailsEn: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст3кп, Ст3пс, Ст3сп, С235; С245; С255; С275; С285; С345.',
            typeEn:['ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой ',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.'
            ],
            table: [
                {title: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89', titleEn: 'Швеллеры с уклоном внутренних граней полок по ГОСТ 8240-89', data: 'data1'},
            ],

            data1: [
                {Column1: '5', Column2: '4,84', Column3: 50, Column4: 32, Column5: '4,4', Column6: '7,0'},
                {Column1: '6,5', Column2: '5,9', Column3: 65, Column4: 36, Column5: '4,4', Column6: '7,2'},
                {Column1: '8', Column2: '7,05', Column3: 80, Column4: 40, Column5: '4,5', Column6: '7,4'},
                {Column1: '10', Column2: '8,59', Column3: 100, Column4: 46, Column5: '4,5', Column6: '7,6'},
                {Column1: '12', Column2: '10,4', Column3: 120, Column4: 52, Column5: '4,8', Column6: '7,8'},
                {Column1: '14', Column2: '12,3', Column3: 140, Column4: 58, Column5: '4,9', Column6: '8,1'},
                {Column1: '16', Column2: '14,2', Column3: 160, Column4: 64, Column5: '5,0', Column6: '8,4'},
                {Column1: '16а', Column2: '15,3', Column3: 160, Column4: 68, Column5: '5,0', Column6: '9,0'},
                {Column1: '18', Column2: '16,3', Column3: 180, Column4: 70, Column5: '5,1', Column6: '8,7'},
                {Column1: '18а', Column2: '17,4', Column3: 180, Column4: 74, Column5: '5,1', Column6: '9,3'},
                {Column1: '20', Column2: '18,4', Column3: 200, Column4: 76, Column5: '5,2', Column6: '9,0'},
                {Column1: '22', Column2: '21,0', Column3: 220, Column4: 82, Column5: '5,4', Column6: '9,5'},
                {Column1: '24', Column2: '24,0', Column3: 240, Column4: 90, Column5: '5,6', Column6: '10,0'},
                {Column1: '27', Column2: '27,7', Column3: 270, Column4: 95, Column5: '6,0', Column6: '10,5'},
                {Column1: '30', Column2: '31,8', Column3: 300, Column4: 100, Column5: '6,5', Column6: '11,0'},
                {Column1: '33', Column2: '36,5', Column3: 330, Column4: 105, Column5: '7,0', Column6: '11,7'},
                {Column1: '36', Column2: '41,9', Column3: 360, Column4: 110, Column5: '7,5', Column6: '12,6'},
                {Column1: '40', Column2: '48,3', Column3: 400, Column4: 115, Column5: '8,0', Column6: '13,5'}
            ],
        },
        {
            id: 4,
            img: shveller,
            image: zzz_shveller_model,

            title: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89',
            description: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89',
            columnsHeader: [
                {title: 'chanelGOST824089.Column1', field: 'Column1'},
                {title: 'chanelGOST824089.Column2', field: 'Column2'},
                {title: 'chanelGOST824089.Column3', field: 'Column3'},
                {title: 'chanelGOST824089.Column4', field: 'Column4'},
                {title: 'chanelGOST824089.Column5', field: 'Column5'},
                {title: 'chanelGOST824089.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89',
            listDetails: [''],
            steel: 'Марки стали: Ст3кп, Ст3пс, Ст3сп, С235; С245; С255; С275; С285; С345.',
            type: ['ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой ',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.'
            ],


            titleEn: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89',
            descriptionEn: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89',
            descriptionDetailsEn: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст3кп, Ст3пс, Ст3сп, С235; С245; С255; С275; С285; С345.',
            typeEn:['ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой ',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.'
            ],
            table: [
                {title: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89', titleEn: 'Швеллеры с параллельными гранями полок по ГОСТ 8240-89', data: 'data1'},
            ],


            data1: [
                {Column1: '5П', Column2: '4,84', Column3: 50, Column4: 32, Column5: '4,4', Column6: '7,0'},
                {Column1: '6,5П', Column2: '5,9', Column3: 65, Column4: 36, Column5: '4,4', Column6: '7,2'},
                {Column1: '8П', Column2: '7,05', Column3: 80, Column4: 40, Column5: '4,5', Column6: '7,4'},
                {Column1: '10П', Column2: '8,59', Column3: 100, Column4: 46, Column5: '4,5', Column6: '7,6'},
                {Column1: '12П', Column2: '10,4', Column3: 120, Column4: 52, Column5: '4,8', Column6: '7,8'},
                {Column1: '14П', Column2: '12,3', Column3: 140, Column4: 58, Column5: '4,9', Column6: '8,1'},
                {Column1: '16П', Column2: '14,2', Column3: 160, Column4: 64, Column5: '5,0', Column6: '8,4'},
                {Column1: '16аП', Column2: '15,3', Column3: 160, Column4: 68, Column5: '5,0', Column6: '9,0'},
                {Column1: '18П', Column2: '16,3', Column3: 180, Column4: 70, Column5: '5,1', Column6: '8,7'},
                {Column1: '18аП', Column2: '17,4', Column3: 180, Column4: 74, Column5: '5,1', Column6: '9,3'},
                {Column1: '20П', Column2: '18,4', Column3: 200, Column4: 76, Column5: '5,2', Column6: '9,0'},
                {Column1: '22П', Column2: '21,0', Column3: 220, Column4: 82, Column5: '5,4', Column6: '9,5'},
                {Column1: '24П', Column2: '24,0', Column3: 240, Column4: 90, Column5: '5,6', Column6: '10,0'},
                {Column1: '27П', Column2: '27,7', Column3: 270, Column4: 95, Column5: '6,0', Column6: '10,5'},
                {Column1: '30П', Column2: '31,8', Column3: 300, Column4: 100, Column5: '6,5', Column6: '11,0'},
                {Column1: '33П', Column2: '36,5', Column3: 330, Column4: 105, Column5: '7,0', Column6: '11,7'},
                {Column1: '36П', Column2: '41,9', Column3: 360, Column4: 110, Column5: '7,5', Column6: '12,6'},
                {Column1: '40П', Column2: '48,3', Column3: 400, Column4: 115, Column5: '8,0', Column6: '13,5'}
            ],
        }
    ],
    table: {
        1: {},
        2: {},
        3: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
    }
}
