import sheetSteel from '../assets/img/catalog/sheet-steel.jpg'
import sheetSteel2 from '../assets/img/catalog/sheetSteel2.jpg'
import sheetSteel3 from '../assets/img/catalog/sheetSteel3.jpg'
import sheetSteel4 from '../assets/img/catalog/sheetSteel4.jpg'
import sheetSteel5 from '../assets/img/catalog/sheetSteel5.jpg'

export const sheet = {
    list: [
        {
            id: 1,
            img: sheetSteel,
            image: sheetSteel,


            title: 'Листовая сталь конструкционная по EN 10025',
            description: '',
            columnsHeader: [
                {title: 'sheetSteelEN10025.Column1', field: 'Column1'},
                {title: 'sheetSteelEN10025.Column2', field: 'Column2'},
                {title: 'sheetSteelEN10025.Column3', field: 'Column3'},
                {title: 'sheetSteelEN10025.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: S235JR, S235J2, S275JR, S275J2, S355JR, S355J2, S355NL, S355ML.',
            type: ['Применение: Cварные конструкции, строительные конструкции.', ' Примечание: * Данные марки стали по требованию заказчика могут поставляться с испытанием на ударную вязкость по Шарпи при -50 гр.Цельсия.'],


            titleEn: 'Листовая сталь конструкционная по EN 10025',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: S235JR, S235J2, S275JR, S275J2, S355JR, S355J2, S355NL, S355ML.',
            typeEn:   ['Применение: Cварные конструкции, строительные конструкции.', ' Примечание: * Данные марки стали по требованию заказчика могут поставляться с испытанием на ударную вязкость по Шарпи при -50 гр.Цельсия.'],
            table: [
                {title: 'Листовая сталь конструкционная по EN 10025', titleEn: 'Листовая сталь конструкционная по EN 10025', data: 'data1'},
            ],


            data1: [
                {Column1: 'Лист S235JR', Column2: '2-100', Column3: '1000-2000', Column4: '3000-12000'},
                {Column1: 'Лист S235J2', Column2: '2-100', Column3: '1000-2000', Column4: '3000-12000'},
                {Column1: 'Лист S275JR', Column2: '2-100', Column3: '1000-2000', Column4: '3000-12000'},
                {Column1: 'Лист S275JR', Column2: '1', Column3: '1250', Column4: '2500'},
                {Column1: 'Лист S275J2', Column2: '2-100', Column3: '1000-2000', Column4: '3000-12000'},
                {Column1: 'Лист S355JR', Column2: '2-100', Column3: '1000-2000', Column4: '3000-12000'},
                {Column1: 'Лист S355J2', Column2: '2-100', Column3: '1000-2000', Column4: '3000-12000'},
                {Column1: 'Лист S355NL*', Column2: '5-100', Column3: '1500-2000', Column4: '3000-12000'},
                {Column1: 'Лист S355ML*', Column2: '5-100', Column3: '1500-2000', Column4: '3000-12000'},

            ]
        },
        {
            id: 2,
            img: sheetSteel,
            image: sheetSteel,


            title: 'Листовая сталь горячекатанная Ст3 и Ст09Г2С',
            description: '',
            columnsHeader: [
                {title: 'sheetSteelEN10025.Column1', field: 'Column1'},
                {title: 'sheetSteelEN10025.Column2', field: 'Column2'},
                {title: 'sheetSteelEN10025.Column3', field: 'Column3'},
                {title: 'sheetSteelEN10025.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст3пс/сп, Ст09Г2С, Ст09Г2С-6, Ст09Г2С-14',
            type: ['Применение: Cварные конструкции, строительные конструкции.'],


            titleEn: 'Листовая сталь горячекатанная Ст3 и Ст09Г2С',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn:  'Марки стали: Ст3пс/сп, Ст09Г2С, Ст09Г2С-6, Ст09Г2С-14',
            typeEn:  ['Применение: Cварные конструкции, строительные конструкции.'],
            table: [
                {title: 'Листовая сталь горячекатанная Ст3 и Ст09Г2С', titleEn: 'Листовая сталь горячекатанная Ст3 и Ст09Г2С', data: 'data1'},
            ],


            data1: [
                {Column1: 'Лист Ст3пс/сп', Column2: '1.5-3', Column3: '1250', Column4: '2500'},
                {Column1: 'Лист Ст3пс/сп', Column2: '4-120', Column3: '1500-2000', Column4: '3000-6000'},
                {Column1: 'Лист Ст09Г2С', Column2: '2', Column3: '1250', Column4: '2500'},
                {Column1: 'Лист Ст09Г2С', Column2: '3-120', Column3: '1500-2000', Column4: '6000'},


            ]
        },
        {
            id: 3,
            img: sheetSteel2,
            image: sheetSteel2,


            title: 'Лист с чечевичным рифлением',
            description: '',
            columnsHeader: [
                {title: 'sheetSteel2.Column1', field: 'Column1'},
                {title: 'sheetSteel2.Column2', field: 'Column2'},
                {title: 'sheetSteel2.Column3', field: 'Column3'},
                {title: 'sheetSteel2.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали:  Ст3сп',
            type: ['Применение: его используют при изготовлении противоскользящих покрытий, в местах, где существует повышенная травматическая опасность. Лестничные ступени, сходни, площадки, мостки, трапы, особенно если они находятся вне помещений (на открытом воздухе), изготовленные из этого металлопроката исключают скольжение и минимизируют риски получения травм. Исходя из таких его особенностей, рифленый лист используют на нефтяных платформах, гидро- и электростанциях'],


            titleEn: 'Лист с чечевичным рифлением',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn:   'Марки стали:  Ст3сп',
            typeEn:  ['Применение: его используют при изготовлении противоскользящих покрытий, в местах, где существует повышенная травматическая опасность. Лестничные ступени, сходни, площадки, мостки, трапы, особенно если они находятся вне помещений (на открытом воздухе), изготовленные из этого металлопроката исключают скольжение и минимизируют риски получения травм. Исходя из таких его особенностей, рифленый лист используют на нефтяных платформах, гидро- и электростанциях'],
            table: [
                {title: 'Лист с чечевичным рифлением', titleEn: 'Лист с чечевичным рифлением', data: 'data1'},
            ],


            data1: [
                {Column1: '2,5', Column2: '1250', Column3: '2500', Column4: '20,01'},
                {Column1: '3', Column2: '1500', Column3: '6000', Column4: '24,2'},
                {Column1: '4', Column2: '1500', Column3: '6000', Column4: '32,2'},
                {Column1: '5', Column2: '1500', Column3: '6000', Column4: '40,5'},
                {Column1: '6', Column2: '1500', Column3: '6000', Column4: '48,5'},
                {Column1: '8', Column2: '1500', Column3: '6000', Column4: '64,9'},
                {Column1: '10', Column2: '1500', Column3: '6000', Column4: '80,9'},
                {Column1: '12', Column2: '1500', Column3: '6000', Column4: '96,8'},


            ]
        },
        {
            id: 4,
            img: sheetSteel3,
            image: sheetSteel3,


            title: 'Лист с ромбическим рифлением',
            description: '',
            columnsHeader: [
                {title: 'sheetSteel2.Column1', field: 'Column1'},
                {title: 'sheetSteel2.Column2', field: 'Column2'},
                {title: 'sheetSteel2.Column3', field: 'Column3'},
                {title: 'sheetSteel2.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали:  Ст3сп',
            type: ['Применение: его используют при изготовлении противоскользящих покрытий, в местах, где существует повышенная травматическая опасность. Лестничные ступени, сходни, площадки, мостки, трапы, особенно если они находятся вне помещений (на открытом воздухе), изготовленные из этого металлопроката исключают скольжение и минимизируют риски получения травм. Исходя из таких его особенностей, рифленый лист используют на нефтяных платформах, гидро- и электростанциях'],


            titleEn: 'Лист с ромбическим рифлением',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn:   'Марки стали:  Ст3сп',
            typeEn: ['Применение: его используют при изготовлении противоскользящих покрытий, в местах, где существует повышенная травматическая опасность. Лестничные ступени, сходни, площадки, мостки, трапы, особенно если они находятся вне помещений (на открытом воздухе), изготовленные из этого металлопроката исключают скольжение и минимизируют риски получения травм. Исходя из таких его особенностей, рифленый лист используют на нефтяных платформах, гидро- и электростанциях'],
            table: [
                {title: 'Лист с ромбическим рифлением', titleEn: 'Лист с ромбическим рифлением', data: 'data1'},
            ],


            data1: [
                {Column1: '2,5', Column2: '1250', Column3: '2500', Column4: '21,0'},
                {Column1: '3', Column2: '1500', Column3: '6000', Column4: '25,1'},
                {Column1: '4', Column2: '1500', Column3: '6000', Column4: '33,5'},
                {Column1: '5', Column2: '1500', Column3: '6000', Column4: '41,8'},
                {Column1: '6', Column2: '1500', Column3: '6000', Column4: '59,0'},
                {Column1: '8', Column2: '1500', Column3: '6000', Column4: '66,0'},
                {Column1: '10', Column2: '1500', Column3: '6000', Column4: '83,0'},
                {Column1: '12', Column2: '1500', Column3: '6000', Column4: '99,3'},


            ]
        },
        {
            id: 5,
            img: sheetSteel4,
            image: sheetSteel4,


            title: 'Лист стальной оцинкованный',
            description: '',
            columnsHeader: [
                {title: 'sheetSteel3.Column1', field: 'Column1'},
                {title: 'sheetSteel3.Column2', field: 'Column2'},
                {title: 'sheetSteel3.Column3', field: 'Column3'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Лист стальной оцинкованный — это вид металлопроката, стальной лист, покрытый толстым слоем цинка для защиты от коррозии. Наиболее стойким, легким и экономичным материалом с высокими антикоррозийными характеристиками принято считать лист стальной оцинкованный. Такой материал широко применяется для изготовления кровельных элементов, вентиляционных коробов, а также водосточных труб и др.',
            type: ['Поставка осуществляется как по листовой, так и по рулонной.'],


            titleEn: 'Лист стальной оцинкованный',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn:  'Лист стальной оцинкованный — это вид металлопроката, стальной лист, покрытый толстым слоем цинка для защиты от коррозии. Наиболее стойким, легким и экономичным материалом с высокими антикоррозийными характеристиками принято считать лист стальной оцинкованный. Такой материал широко применяется для изготовления кровельных элементов, вентиляционных коробов, а также водосточных труб и др.',
            typeEn: ['Поставка осуществляется как по листовой, так и по рулонной.'],
            table: [
                {title: 'Лист стальной оцинкованный', titleEn: 'Лист стальной оцинкованный', data: 'data1'},
            ],


            data1: [
                {Column1: '0,45', Column2: '1250', Column3: '2500'},
                {Column1: '0,5', Column2: '1000-1250', Column3: '2000-2500'},
                {Column1: '0,55', Column2: '1000-1250', Column3: '2000-2500'},
                {Column1: '0,7', Column2: '1250', Column3: '2500'},
                {Column1: '0,8', Column2: '1250', Column3: '2500'},
                {Column1: '1', Column2: '1250', Column3: '2500'},
                {Column1: '1,2', Column2: '1250', Column3: '2500'},
                {Column1: '1,5', Column2: '1250', Column3: '2500'},
                {Column1: '2', Column2: '1250', Column3: '2500'},
                {Column1: '2,5', Column2: '1250', Column3: '2500'},
                {Column1: '3', Column2: '1250', Column3: '2500'},
            ]
        },
        {
            id: 6,
            img: sheetSteel5,
            image: sheetSteel5,


            title: 'Сталь полосовая горячекатанная',
            description: '',
            columnsHeader: [
                {title: 'sheetSteel3.Column1', field: 'Column1'},
                {title: 'sheetSteel3.Column2', field: 'Column2'},
                {title: 'sheetSteel3.Column3', field: 'Column3'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марка стали: Ст3сп/пс, Ст09Г2С, S275JR/J0/J2, S355ML/NL',
            type: ['Применения:','- в изготовлении различных металлоконструкций и кованых изделий, в том числе ограждений и заборов;', '- в машиностроении;', '- при прокладке инженерных коммуникаций;', '- в гражданском и промышленном строительстве и т.д.', '____________________________', 'По требованию заказчика есть возможность горячего оцинкования металлических полос.'],


            titleEn: 'Сталь полосовая горячекатанная',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn:  'Марка стали: Ст3сп/пс, Ст09Г2С, S275JR/J0/J2, S355ML/NL',
            typeEn: ['Применения:','- в изготовлении различных металлоконструкций и кованых изделий, в том числе ограждений и заборов;', '- в машиностроении;', '- при прокладке инженерных коммуникаций;', '- в гражданском и промышленном строительстве и т.д.', '____________________________', 'По требованию заказчика есть возможность горячего оцинкования металлических полос.'],
            table: [
                {title: 'Сталь полосовая горячекатанная', titleEn: 'Сталь полосовая горячекатанная', data: 'data1'},
            ],


            data1: [
                {Column1: '4', Column2: '25-300', Column3: '6000'},
                {Column1: '5', Column2: '25-300', Column3: '6000'},
                {Column1: '6', Column2: '25-300', Column3: '6000'},
                {Column1: '8', Column2: '25-300', Column3: '6000'},
                {Column1: '10', Column2: '25-300', Column3: '6000'},
                {Column1: '12', Column2: '50-300', Column3: '6000'},
                {Column1: '16', Column2: '50-300', Column3: '6000'},
                {Column1: '20', Column2: '50-300', Column3: '6000'},
                {Column1: '25', Column2: '50-300', Column3: '6000'},

            ]
        },
    ],
    table: {
        1: {},
        2: {},
        3: {},
        4: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
        4: {},
    },

};
