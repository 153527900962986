import React from 'react';
import CertificatesShow from "./CertificatesShow";
import image1 from "../../assets/img/cert/9001.jpg";
import image2 from "../../assets/img/cert/14001.jpg";
import image3 from "../../assets/img/cert/18001.jpg";
import {translate} from "react-i18next";
import {withStyles} from "@material-ui/core";
import CertificateModal from "./CertificateModal";

const styles = {
    certWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        alignItems: 'center',
        '& div': {
            color: 'black',
            fontWeight: 'bold'
        },
    },
    certShowWrapper: {
        width: 760,
        marginLeft: 20,
        marginRight: 20,
        marginTop: 50,
        marginBottom: 80
    }
};

const Certificates = ({t, classes}) => {
    return <div>
        <h2 style={{color: '#213e7e', marginLeft: 20, marginRight: 20}}>{t('navbar.certificates')}</h2>
        <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around'}}>
            <div className={classes.certWrapper}>
                <CertificateModal image={image1}/>
                <div>ISO 9001</div>
            </div>
            <div className={classes.certWrapper}>
                <CertificateModal image={image2}/>
                <div>ISO 14001</div>
            </div>
            <div className={classes.certWrapper}>
                <CertificateModal image={image3}/>
                <div>OHSAS 18001</div>
            </div>
        </div>
        <div className={classes.certShowWrapper}>
            <CertificatesShow/>
        </div>

    </div>
};

export default withStyles(styles)(translate('common')(Certificates));
