export const flooring = {
    title1: 'Тип SP (гладкий 2 + 3 + 4 + 5 mm)',
    title1En: "Тип SP (гладкий 2 + 3 + 4 + 5 mm)",
    description1: 'Стандартная решетка-разнообразное применение.',
    description1En: 'Стандартная решетка-разнообразное применение.',

    title2: 'Тип SP-безопасность (с зубьями противоскольжения 2 + 3 + 4 + 5 mm)',
    title2En: 'Тип SP-безопасность (с зубьями противоскольжения 2 + 3 + 4 + 5 mm)',
    description2: 'Защиту против скольжения гарантируют зубья противоскольжения на несущих полосах. Настил с зубьями особенно применяется в местах где риск скольжения увеличивает дождь, лед, масло. Изготавливаются по индивидуальным заказам.',
    description2En: 'Защиту против скольжения гарантируют зубья противоскольжения на несущих полосах. Настил с зубьями особенно применяется в местах где риск скольжения увеличивает дождь, лед, масло. Изготавливаются по индивидуальным заказам.',

    title3: 'Безопасность решеток типа SP - тип offshore (противоскользящее исполнение 3 + 4 + 5 mm)',
    title3En: 'Безопасность решеток типа SP - тип offshore (противоскользящее исполнение 3 + 4 + 5 mm)',
    description3: 'Чтобы через решетку не проваливались предметы, между несущими полосами устанавливается один или два дополнительных прутка. Размеры ячеек отвечают требованиям, что через решетку не могут проваливаться предметы диаметром больше 15 mm. ',
    description3En: 'Чтобы через решетку не проваливались предметы, между несущими полосами устанавливается один или два дополнительных прутка. Размеры ячеек отвечают требованиям, что через решетку не могут проваливаться предметы диаметром больше 15 mm. ',

    title4: 'Ступени из сварного решетчатого настила типа SP',
    title4En: 'Ступени из сварного решетчатого настила типа SP',
    description4: 'Из всех типов сварных решеток можно изготовить лестничные ступени. В станадартном исполнении ступении имеют противоскользящий уголок и боковые планки.',
    description4En: 'Из всех типов сварных решеток можно изготовить лестничные ступени. В станадартном исполнении ступении имеют противоскользящий уголок и боковые планки.',

    title5: 'Материал',
    title5En: 'Материал',
    list5: ['сталь S235JR, толщина несущей полосы от 2 до 5 mm','нержавеющая сталь 1.4301 (V2A)', 'нержавеющая сталь 1.4571 (V4A)'],
    list5En:  ['сталь S235JR, толщина несущей полосы от 2 до 5 mm','нержавеющая сталь 1.4301 (V2A)', 'нержавеющая сталь 1.4571 (V4A)'],

    title6: 'Приемущества сварного решетчатого настила SP',
    title6En: 'Приемущества сварного решетчатого настила SP',
    list6: ['долговечность, несмотря на жесткие условия', 'рифленные прутки улучшают счепление с поверхностью', 'устойчивые к воздействию окружающей среды, даже в неоцинкованном исполнении', 'очень длинные несущие полосы', 'применяются в качестве платформ для обслуживания грузовых автомобилей и погрузчиков'],
    list6En: ['долговечность, несмотря на жесткие условия', 'рифленные прутки улучшают счепление с поверхностью', 'устойчивые к воздействию окружающей среды, даже в неоцинкованном исполнении', 'очень длинные несущие полосы', 'применяются в качестве платформ для обслуживания грузовых автомобилей и погрузчиков'],

};
