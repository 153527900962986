import React from 'react'
import flooring1 from '../../assets/img/catalog/flooring1.jpg'
import flooring2 from '../../assets/img/catalog/flooring2.jpg'
import flooring3 from '../../assets/img/catalog/flooring3.jpg'
import flooring4 from '../../assets/img/catalog/flooring4.jpg'
import {translate} from "react-i18next";
import FlooringDetails from "./FlooringDetails";

const Flooring = (props) => {
    const {t, data} = props;
    return <div className={"content-form__list"}>
        <div style={{display: "flex", flexDirection: 'column', marginBottom: 30, paddingRight: 20}}>
            <div style={{marginLeft: 20, marginRight: 20}}>
                <h2>{data[t('catalog.title')]}</h2>
                <p>{data[t('catalog.description')]}</p>
            </div>
            <div style={{display: "flex", marginBottom: 30, marginRight: 20}}>
                <div>
                    <img style={{width: 500}} src={flooring1} alt=""/>
                </div>
                <div style={{display: "flex", flexDirection: 'column'}}>
                    <img style={{width: 150, marginBottom: 10}} src={flooring2} alt=""/>
                    <img style={{width: 150, marginBottom: 10}} src={flooring3} alt=""/>
                    <img style={{width: 150, marginBottom: 10}} src={flooring4} alt=""/>
                </div>
            </div>
        </div>
        <div style={{marginBottom: 50, marginLeft: 20, marginRight: 20 , paddingRight: 30}}>
            <FlooringDetails details={data.details}/>
        </div>
    </div>
};

export default  translate("common")(Flooring);
