import React from "react";

const Services = (props) => {
    return <div className={'wrapperComponent'}>

        <p>Услуги</p>
        <div className={'wrapperComponent-description'}>
            <ul>
                <li>- Плазменная резка</li>
                <li>- Резка на лентопильном станке</li>
                <li>- Дробеструйная обработка металла</li>
                <li>- Токарная обработка</li>
                <li>- Сборочно-сварные работы</li>
                <li>- Покрасочная работа (воздушное и безвоздушное распыление)</li>
                <li>- Проектирование и изготовление любой сложности металлических конструкций по "СНиП" и требованию
                    заказчика
                </li>
                <li>- Горячее оцинкование металла</li>
            </ul>
        </div>

    </div>
};

export default Services
