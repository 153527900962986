import {beam} from "../repository/beam";
import {channel} from "../repository/channel";
import {profilePipe} from "../repository/profilePipe";
import {corner} from "../repository/corner";
import {roundPipe} from "../repository/round-pipe";
import {flooring} from "../repository/flooring";
import {welded} from "../repository/welded";
import {sheet} from "../repository/sheet-steel";
import {noCorrosionSteel} from "../repository/no-corrosion-steel";
import {panel} from "../repository/panel";


const initState = {
    beam: {
        title: 'Балка',
        description: 'В этом разделе вы можете найти стальные двутавровые балки различной геометрической формы, как с параллельными, так и с уклоном внутренних граней. Применяются для усиления строительных конструкций от механических воздействий. Чаще всего двутавровую балку используют при устройстве перекрытий гражданских и промышленных объектов, обеспечивая запас прочности за счёт эластичности балок. Также возможно использование в опорах мостов, тоннелей и других ответственных сооружениях.',
        titleEn: 'Балка',
        descriptionEn: 'В этом разделе вы можете найти стальные двутавровые балки различной геометрической формы, как с параллельными, так и с уклоном внутренних граней. Применяются для усиления строительных конструкций от механических воздействий. Чаще всего двутавровую балку используют при устройстве перекрытий гражданских и промышленных объектов, обеспечивая запас прочности за счёт эластичности балок. Также возможно использование в опорах мостов, тоннелей и других ответственных сооружениях.',
        list: beam.list,
        table: beam.table
    },
    channel: {
        title: 'Швеллер',
        description: 'Швеллеры используются в строительстве высотных домов, мостов и других ответственных конструкций, подверженных постоянным нагрузкам. Обычно имеют П-образную фыорму и изготавливаются из стали и алюминия.',
        titleEn: 'Швеллер',
        descriptionEn: 'Швеллеры используются в строительстве высотных домов, мостов и других ответственных конструкций, подверженных постоянным нагрузкам. Обычно имеют П-образную фыорму и изготавливаются из стали и алюминия.',
        list: channel.list,
        table: channel.table
    },
    profilePipe: {
        title: 'Труба профильная',
        description: 'Профильные трубы еще называют квадратыми или прямоугольными за их геометрию. От круглых труб их отличает малый вес, что расширяет область её применения в строительстве. В основном их используют в качестве элементов металлоконструкций как обычный брус, но облегчённый и жесткий, с высокой прочностью на изгиб. Профильные трубы - это выигрыш по массе, экономия металла и удешевление конструкции без потери прочности..',
        titleEn: 'Труба профильная',
        descriptionEn: 'Профильные трубы еще называют квадратыми или прямоугольными за их геометрию. От круглых труб их отличает малый вес, что расширяет область её применения в строительстве. В основном их используют в качестве элементов металлоконструкций как обычный брус, но облегчённый и жесткий, с высокой прочностью на изгиб. Профильные трубы - это выигрыш по массе, экономия металла и удешевление конструкции без потери прочности..',
        list: profilePipe.list,
        table: profilePipe.table
    },
    corner: {
        title: 'Уголок',
        description: 'Стальной уголок - одно из самых распространённых изделий металлопроката, ведь его используют почти во всех областях хозяйственной деятельности. Спрос на уголок породил и широкий ассортимент форм: уголки бывают равнополочные и неравнополочные, холоднокатаннае и горячекатанные. Также используется различная сталь в зависимости от требуемой прочности. Основная задача уголка - принимать на себя нагрузку, однако они могут быть и просто элементом конструкции.',
        titleEn: 'Уголок',
        descriptionEn: 'Стальной уголок - одно из самых распространённых изделий металлопроката, ведь его используют почти во всех областях хозяйственной деятельности. Спрос на уголок породил и широкий ассортимент форм: уголки бывают равнополочные и неравнополочные, холоднокатаннае и горячекатанные. Также используется различная сталь в зависимости от требуемой прочности. Основная задача уголка - принимать на себя нагрузку, однако они могут быть и просто элементом конструкции.',
        list: corner.list,
        table: corner.table
    },
    roundPipe: {
        title: 'Труба круглая',
        description: 'Трубы мы встречаем каждый день, их используют как в быту, так и в промышленных целях. Спектр применения этих изделий настолько широк, что они встречаются практически во всех областях хозяйственной деятельности. Каждый день мы проходим и проезжаем над сотнями труб. В зависимости от назначения трубы выпускаются разных геометрических размеров и могут обладать повышенной устойчивостью к экстремальным температурам, воздействию химических веществ или других проблем в эксплуатации.',
        titleEn: 'Труба круглая',
        descriptionEn: 'Трубы мы встречаем каждый день, их используют как в быту, так и в промышленных целях. Спектр применения этих изделий настолько широк, что они встречаются практически во всех областях хозяйственной деятельности. Каждый день мы проходим и проезжаем над сотнями труб. В зависимости от назначения трубы выпускаются разных геометрических размеров и могут обладать повышенной устойчивостью к экстремальным температурам, воздействию химических веществ или других проблем в эксплуатации.',
        list: roundPipe.list,
        table: roundPipe.table
    },
    flooring: {
        title: 'Сварной решетчатый настил типа SP',
        description: 'Сварной решетчатый настил представляет собой прочную решетку, состоящую из несущих полос, сваренных с покровными прутками. Несущие полосы-цельные стальные ленты без высеченных в них профильных пазов. В отличие от прессованных настилов, покровные прутки свариваются с несущими полосами методом кузнечно-прессовой сварки, одновременно по всем точкам соединения. Сварные соединения обеспечивают очень высокую прочность решетки. Сварные решетки используются как основа для изготовления настилов точно заданного размера, которые имеют самое разнообразное применение. Чтобы через решетку не проваливались предметы, между несущими полосами можно установить один или два дополнительных связующих прутка. Чтобы настилы, применяемые, например, в морских буровых платформах не были скользкими, на несущих полосах могут быть высечены зубья противоскольженеия.',
        titleEn: 'Сварной решетчатый настил типа SP',
        descriptionEn: 'Сварной решетчатый настил представляет собой прочную решетку, состоящую из несущих полос, сваренных с покровными прутками. Несущие полосы-цельные стальные ленты без высеченных в них профильных пазов. В отличие от прессованных настилов, покровные прутки свариваются с несущими полосами методом кузнечно-прессовой сварки, одновременно по всем точкам соединения. Сварные соединения обеспечивают очень высокую прочность решетки. Сварные решетки используются как основа для изготовления настилов точно заданного размера, которые имеют самое разнообразное применение. Чтобы через решетку не проваливались предметы, между несущими полосами можно установить один или два дополнительных связующих прутка. Чтобы настилы, применяемые, например, в морских буровых платформах не были скользкими, на несущих полосах могут быть высечены зубья противоскольженеия.',
        details: flooring
    },
    welded: {
        title: 'Сварная арматурная сетка B500C',
        description: 'Как хорошо известно, сетка сварная заводского производства имеет для прямого потребителя в его экономике высокий логистический потенциал, выражающийся, в конечном итоге, в существенных экономических преференциях, по сравнению, например, с ручным послойным армированием путем нарезки, раскладки и вязки отдельных стержней непосредственно на объектах и строительных площадках',
        titleCenter: "Сетка изготавливается в картах из арматуры класса А3, с размерами ячеек, мм:",
        titleEn: 'Сварная арматурная сетка B500C',
        descriptionEn: 'Как хорошо известно, сетка сварная заводского производства имеет для прямого потребителя в его экономике высокий логистический потенциал, выражающийся, в конечном итоге, в существенных экономических преференциях, по сравнению, например, с ручным послойным армированием путем нарезки, раскладки и вязки отдельных стержней непосредственно на объектах и строительных площадках',
        titleCenterEn: "Сетка изготавливается в картах из арматуры класса А3, с размерами ячеек, мм:",
        details: welded
    },
    sheet: {
        title: 'Листовая сталь',
        description: '',
        titleCenter: "",
        titleEn: 'Листовая сталь',
        descriptionEn: '',
        list: sheet.list,
        details: sheet.table
    },
    noCorrosionSteel: {
        title: 'Нержавеющий и алюминиевый',
        description: '',
        titleCenter: "",
        titleEn: 'Нержавеющий и алюминиевый',
        descriptionEn: '',
        list: noCorrosionSteel.list,
        details: noCorrosionSteel.table
    },
    panel: {
        title: 'Сэндвич-панели стеновые и кровельные',
        description: '',
        titleCenter: "",
        titleEn: 'Сэндвич-панели стеновые и кровельные',
        descriptionEn: '',
        list: panel.list,
        details: panel.table
    },
    shtrips: {
        title: 'Металлические штрипсы SS Band',
        description: [
            '- Архитектура, строительство и оформление инфраструктуры. ',
            '- Транспорт и автомобилестроение.',
            '- Пищевая промышленность, общественное питание (кейтеринг).',
            '- Бытовая техника. ',
            '- Энергетика и тяжелая промышленность, машиностроение.',
            '- Химическая промышленность.',
            '- Системы отопления, кондиционирования и вентиляции.',
            '- Нефтяная и газовая промышленность.',
            '- Целлюлозно-бумажная промышленность.'
        ],
        catalog: [
            {title: 'Лента из нержавейки 430', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 201', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 304', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 321', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 316', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 310', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
        ],
        catalogEn: [
            {title: 'Лента из нержавейки 430', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 201', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 304', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 321', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 316', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
            {title: 'Лента из нержавейки 310', desc: 'Порезка, перемотка, шлифование', width: '0,4 - 3,0 мм', weight: 'от 10 кг'},
        ],
        titleCenter: "Сферы применения",

        titleEn: 'Металлические штрипсы SS Band',
        descriptionEn:  [
            '- Архитектура, строительство и оформление инфраструктуры. ',
            '- Транспорт и автомобилестроение.',
            '- Пищевая промышленность, общественное питание (кейтеринг).',
            '- Бытовая техника. ',
            '- Энергетика и тяжелая промышленность, машиностроение.',
            '- Химическая промышленность.',
            '- Системы отопления, кондиционирования и вентиляции.',
            '- Нефтяная и газовая промышленность.',
            '- Целлюлозно-бумажная промышленность.'
        ],
        titleCenterEn: "Сферы применения",
    }




};

const catalogReducer = (state = initState, action) => {
    switch (action.type) {
        default:
            return state;
    }

};

export default catalogReducer;
