import React from "react";
import factory from '../../assets/img/factory_115122.png'
import customs from '../../assets/img/customs_88295.png'
import warehouse from '../../assets/img/warehouse_116463.png'
import arrow from '../../assets/img/rightarrow_78472.png'
import warehouseClient from '../../assets/img/conveyorbelt_118075.png'

const Delivery = (props) => {
    return <div className={'wrapperComponent'}>

        <p>Информация о доставке</p>
        <div className={'wrapperComponent-description'}>
        <span>
                В регионы РК</span>
            <ul>
                <li>- г. Атырау</li>
                <li>- месторождение "Тенгиз", Кульсары</li>
                <li>- месторождение Карачаганак, Аксай</li>
                <li>- Карабатан</li>
                <li>- месторождение в Мангыстауской области</li>
            </ul>
        </div>
        <h2>Отгрузка со склада:</h2>
        <div>
            <div>
                <img src={factory}/>
                <span>Завод</span>
            </div>
            <div className={'arrow'}>
                <img src={arrow}/>
            </div>
            <div>
                <img src={customs}/>
                <span>Таможенная очистка</span>
            </div>
            <div className={'arrow'}>
                <img src={arrow}/>
            </div>
            <div>
                <img src={warehouse}/>
                <span>Склад Maxima</span>
            </div>
        </div>
        <h2>Доставка груза на склад Покупателя:</h2>
        <div>
            <div>
                <img src={factory}/>
                <span>Завод</span>
            </div>
            <div className={'arrow'}>
                <img src={arrow}/>
            </div>
            <div>
                <img src={customs}/>
                <span>Таможенная очистка</span>
            </div>
            <div className={'arrow'}>
                <img src={arrow}/>
            </div>
            <div>
                <img src={warehouseClient}/>
                <span>Склад покупателя</span>
            </div>
        </div>
        <div className={'wrapperComponent-description'}>
            <span>
            Наш склад позволяет консолидировать грузы различного сортамента металлопроката, что значительно упрощает
                нашим Клиентам организовать наиболее выгодный маршрут по доставке груза до предприятия.</span>
            <span>
                Мы предоставляем бесплатное хранение металлопроката для наших Клиентов в течение 15 календарных дней.</span>


        </div>
    </div>
};

export default Delivery