import React from "react";
import {NavLink} from "react-router-dom";
import {translate} from "react-i18next";

const Menu = (props) => {
    const {t} = props;
    return <div>
        <h4>{t("footer.menu").toUpperCase()}</h4>
        <div>
            <span className={"icon-nav"}/><NavLink to={'/about'}><p>{t('navbar.about')}</p></NavLink>
        </div>
        <div>
            <span className={"icon-nav"}/><NavLink to={'/services'}><p>{t('navbar.service')}</p></NavLink>
        </div>
        <div>
            <span className={"icon-nav"}/><NavLink to={'/certificates'}><p>{t('navbar.certificates')}</p></NavLink>
        </div>
        <div>
            <span className={"icon-nav"}/><NavLink to={'/delivery'}><p>{t('navbar.shiping')}</p></NavLink>
        </div>

        <div>
            <span className={"icon-nav"}/><NavLink to={'/contacts'}><p>{t('navbar.contacts')}</p></NavLink>
        </div>
    </div>
};

export default translate("common")(Menu)
