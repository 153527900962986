import React from "react";

const About = () => {
    return <div className={'wrapperComponent'}>
        <p>О Компании</p>
        <h2>Компания MAXIMA LTD осуществляет оптовую и розничную продажу металлопроката ведущих Европейских и Российских производителей.</h2>
        <div className={'wrapperComponent-description'}>
            <span>ТОО MAXIMA LTD является специалистом в области комплексных поставок товаров и материалов для нефтедобывающих и нефтесервисных компаний Республики Казахстан.
            </span>
            <span>
                Наша компания успешно работает на рынке черного и цветного металлопроката, металлоконструкций и изделий из металла с 2013 года и является 100% Казахстанским предприятием.
            </span>
            <span>
               Компания сертифицирована по международным стандартам ISO 9001-2016 «Система менеджмента качества», ISO 14001- 2016 «Система экологического менеджмента», OHSAS 18001-2008 «Система менеджмента профессиональной безопасности и здоровья»
            </span>
            <span>
               Наша главная задача — предоставить конкурентно – способные цены и доставить материалы в кратчайшие сроки.
            </span>
            <span>
                Мы предоставляем высокое качество обслуживания и индивидуальный подход к каждому клиенту.
            </span>
            <span>
                Имеется склад для удовлетворения минимальных потребностей заказчика.
            </span>

            <span>
                Все поставляемые товары и материалы имеют Сертификат качества и сертификат соответствия Республики Казахстан.
            </span>
            <span>
            Мы работаем только с проверенными поставщиками, имеющими отличную репутацию на международном рынке.
        </span>


            <span>ТОО Максима LTD – стабильный и надежный партнер!</span>
        </div>
    </div>
};

export default About