import React from 'react'
import {Menu} from 'antd';
import {withRouter} from "react-router-dom";
import balka3 from '../../assets/img/catalog/balka3.png'
import shveller from '../../assets/img/catalog/shveller.png'
import trubapr from '../../assets/img/catalog/truba_pr.png'
import ugolok from '../../assets/img/catalog/ugol.png'
import trubakr from '../../assets/img/catalog/truba_kr.png'
import {translate} from "react-i18next";

export const data = {
    black: [
        {
            title: 'Балка',
            titleEn: 'Балка',
            img: balka3,
            link: 'beam'
        },
        {
            title: 'Швеллер',
            titleEn: 'Швеллер',
            img: shveller,
            link: 'channel'
        },
        {
            title: 'Труба профильная',
            titleEn: 'Труба профильная',
            img: trubapr,
            link: 'profilePipe'
        },
        {
            title: 'Уголок',
            titleEn: 'Уголок',
            img: ugolok,
            link: 'corner'
        },
        {
            title: 'Труба круглая',
            titleEn: 'Труба круглая',
            img: trubakr,
            link: 'roundpipe'
        }],
};


const DropMenu = (props) => {

    const {SubMenu} = Menu;

    const {t} = props;

    function handleClick(e) {
        props.history.push(`../${e.key}`);
    }

    const handleOnTitleClick = (e) => {
        props.history.push(`../${e.key}`);
    };

    return <div style={{width: 280, marginTop: 5}}>
        <Menu onClick={handleClick} mode="vertical" selectable={false} className={'titleMenu'} >
            <SubMenu key="blacksteel" onTitleClick={handleOnTitleClick} popupClassName={"wrapperMenu"}
                     title={t('menuTitle.blacksteel')} className={'titleMenu-title'} >
                {data.black.map((d, index) => {
                    return <Menu.Item key={d.link} className={"wrapper"}>
                        <div >
                            <p>{d[t('dropMenu.title')]}</p>
                            <img src={d.img}/>
                        </div>
                    </Menu.Item>
                })}
            </SubMenu>
            <Menu.Item onTitleClick={handleOnTitleClick} key="sheet-steel" className={'titleMenu-title '} ><div className={'test'}>{t('menuTitle.sheetsteel')}</div></Menu.Item>
            <Menu.Item onTitleClick={handleOnTitleClick} key="no-corrosion-steel" className={'titleMenu-title'}><div className={'test'}>{t('menuTitle.nocorrosionsteel')}</div></Menu.Item>
            <Menu.Item key="weldedgrid" onTitleClick={handleOnTitleClick} className={'titleMenu-title'}><div className={'test'}>{t('menuTitle.weldedgrid')}</div></Menu.Item>
            <Menu.Item key="flooring" onTitleClick={handleOnTitleClick} className={'titleMenu-title'}><div className={'test'}>{t('menuTitle.flooring')}</div></Menu.Item>
            <Menu.Item key="panel" onTitleClick={handleOnTitleClick} className={'titleMenu-title'}><div className={'test'}>{t('menuTitle.panel')}</div></Menu.Item>
            <Menu.Item key="prof-flooring" onTitleClick={handleOnTitleClick} className={'titleMenu-title'}><div className={'test'}>{t('menuTitle.prof-flooring')}</div></Menu.Item>
            <Menu.Item key="shtrips" onTitleClick={handleOnTitleClick} className={'titleMenu-title'}><div className={'test'}>{t('menuTitle.shtrips')}</div></Menu.Item>
        </Menu>
    </div>
};

export default translate("common")(withRouter(DropMenu));
