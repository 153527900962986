import React from 'react';
import {NavLink} from "react-router-dom";
import {translate} from "react-i18next";


const ListWrapper = (props) => {
    const {t, data, url} = props;
    return <div className={'list'}>
        <h2>{data[t('catalog.title')]}</h2>
        <p>{data[t('catalog.description')]}</p>
        {data.list.map(l => <List img={l.img} title={l[t('catalog.title')]} description={l[t('catalog.description')]} id={l.id} url={url}/>)}
    </div>
};

const List = (props) => {
    const {img, title, description, id, url} = props;
    return <div>
        <img src={img}/>
        <div>
            <h3><NavLink to={`/${url}/${id}`}>{title}</NavLink></h3>
            <p>{description}</p>
        </div>
    </div>
};

export default translate("common")(ListWrapper);