import React from "react";
import {connect} from "react-redux";
import WeldedGrid from "../ui/Catalog/WeldedGrid";


const WeldedGridController = (props) => {
    return <div>
        <WeldedGrid {...props}/>
    </div>
};

const mapState = (state) => {
   return {
       data: state.catalogPage.welded
   }
};

export default connect(mapState, null)(WeldedGridController)
