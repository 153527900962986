import React from 'react';
import {TableDetails} from "./TableDetails";
import {translate} from "react-i18next";


const DetailsProduct = (props) => {
    const {products, id, t} = props;
    const object = products.list.find(l => l.id === Number(id));

    return <div className={"content-form__list"} >
        <div style={{background: 'white'}}>
            <div style={{display: "flex", marginBottom: 30}}>
                <div>
                    <img src={object.image} style={{width: 150,marginRight: 10}}/>
                </div>
                <div>
                    <h2>{object[t("detailsProduct.title")]}</h2>
                    <p>{object[t("detailsProduct.descriptionDetails")]}</p>
                    <div>
                        <ul>
                            {object[t("detailsProduct.listDetails")].map((l, index) => <li key={index}>
                                <span style={{fontWeight: 'bold'}}>{l[0]}</span>{l.slice(1)}
                            </li>)}
                        </ul>
                        {object[t("detailsProduct.steel")]}

                    </div>
                </div>
            </div>
            <div style={{marginLeft: 20}}>
                <ul>
                    {object[t("detailsProduct.type")].map((t, index) => <li key={index}>
                        {t}
                    </li>)}
                </ul>
            </div>
        </div>
        <div style={{marginBottom: 50, position: 'relative'}}>
            <TableDetails id={id} object={object}/>
        </div>
    </div>
};

export default translate("common")(DetailsProduct)
