import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import IconButton from '@material-ui/core/IconButton';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import image1 from "../../assets/img/cert/channel-sample-of-certs.jpg";
import image2 from "../../assets/img/cert/item-3-6-mm-thk.jpg";
import image3 from "../../assets/img/cert/SA-516-Gr-60-465839-1086303.jpg";
import image4 from "../../assets/img/cert/sample-of-cert-for-ALUZINC.jpg";
import image5 from "../../assets/img/cert/sample-of-certificate-pipe-2-compressed.jpg";
import image6 from "../../assets/img/cert/sample-of-certificate-S355NL.jpg";
import image7 from "../../assets/img/cert/ss-sample-of-certificate-5mm.jpg";
import CertificateModal from "./CertificateModal";


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background: 'none'
        // 'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
}));


const tileData = [
    {img: image1}, {img: image2}, {img: image3},
    {img: image4}, {img: image5}, {img: image6},
    {img: image7}

];
export default function CertificatesShow() {
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <GridList className={classes.gridList} cols={5}>
                {tileData.map(tile => (
                    <GridListTile key={tile.img}>
                        <CertificateModal image={tile.img}/>
                    </GridListTile>
                ))}
            </GridList>
        </div>
    );
}

