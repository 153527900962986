import sheetSteel from '../assets/img/catalog/sheet-steel.jpg'
import kvintet from '../assets/img/catalog/kvintet.jpg'
import truba from '../assets/img/catalog/truba-no-corrosion.jpg'

export const noCorrosionSteel = {
    list: [
        {
            id: 1,
            img: sheetSteel,
            image: sheetSteel,

            title: 'Листовая нержавеющая сталь',
            description: '',
            columnsHeader: [
                {title: 'sheetSteelEN10025.Column1', field: 'Column1'},
                {title: 'sheetSteelEN10025.Column2', field: 'Column2'},
                {title: 'sheetSteelEN10025.Column3', field: 'Column3'},
                {title: 'sheetSteelEN10025.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Аустенитная конструкционная сталь, в состав которой добавлен молибден и никель. Благодаря этим элементам коррозионная устойчивость металла настолько высокая, что позволяет использовать сталь в криогенных условиях и агрессивных средах. ',
            type: ['Применение:', '- в химической промышленности (резервуары для хранения агрессивных веществ) и машиностроении;', '- в производстве оборудования для пищевой, фармацавтической, горнодобывающей и нефтехимической промышленности;', '- в изготовлении изделий, которые эксплуатируются в условиях повышенных температур и нагрузок;', '- в производстве бытовых и промышленных теплообменников.'],

            titleEn: 'Листовая нержавеющая сталь',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Аустенитная конструкционная сталь, в состав которой добавлен молибден и никель. Благодаря этим элементам коррозионная устойчивость металла настолько высокая, что позволяет использовать сталь в криогенных условиях и агрессивных средах. ',
            typeEn:['Применение:', '- в химической промышленности (резервуары для хранения агрессивных веществ) и машиностроении;', '- в производстве оборудования для пищевой, фармацавтической, горнодобывающей и нефтехимической промышленности;', '- в изготовлении изделий, которые эксплуатируются в условиях повышенных температур и нагрузок;', '- в производстве бытовых и промышленных теплообменников.'],
            table: [
                {title: 'Листовая нержавеющая сталь', titleEn: 'Листовая нержавеющая сталь', data: 'data1'},
            ],


            data1: [
                {Column1: 'AISI 201', Column2: '0,5-30', Column3: '1000-1500', Column4: '2000-6000'},
                {Column1: 'AISI 304', Column2: '2,0-30', Column3: '1000-2000', Column4: '2000-6000'},
                {Column1: 'AISI 310', Column2: '2,0-30', Column3: '1000-2000', Column4: '2000-6000'},
                {Column1: 'AISI 316(L, Ti)', Column2: '2,0-30', Column3: '1000-2000', Column4: '2000-6000'},
                {Column1: 'AISI 430', Column2: '2,0-30', Column3: '1000-2000', Column4: '2000-6000'},

            ]
        },
        {
            id: 2,
            img: sheetSteel,
            image: sheetSteel,

            title: 'Листовая алюминиевая сталь',
            description: '',
            columnsHeader: [
                {title: 'sheetSteelEN10025.Column1', field: 'Column1'},
                {title: 'sheetSteelEN10025.Column2', field: 'Column2'},
                {title: 'sheetSteelEN10025.Column3', field: 'Column3'},
                {title: 'sheetSteelEN10025.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Примечание: * Поставка осуществляется  по листовая и рулонная',
            type: ['Применение:', '- в химической промышленности (резервуары для хранения агрессивных веществ) и машиностроении;', '- в производстве оборудования для пищевой, фармацавтической, горнодобывающей и нефтехимической промышленности;', '- в производстве бытовых и промышленных теплообменников.'],


            titleEn: 'Листовая алюминиевая сталь',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Примечание: * Поставка осуществляется  по листовая и рулонная',
            typeEn:['Применение:', '- в химической промышленности (резервуары для хранения агрессивных веществ) и машиностроении;', '- в производстве оборудования для пищевой, фармацавтической, горнодобывающей и нефтехимической промышленности;', '- в производстве бытовых и промышленных теплообменников.'],
            table: [
                {title: 'Листовая алюминиевая сталь', titleEn: 'Листовая алюминиевая сталь', data: 'data1'},
            ],

            data1: [
                {Column1: 'АД1Н*', Column2: '0,5-5', Column3: '1200-1500', Column4: '2500-3000'},
                {Column1: 'АД1М*', Column2: '0,8-4', Column3: '1200', Column4: '3000'},
                {Column1: 'А5Н/М', Column2: '0,5-6', Column3: '1200-1500', Column4: '3000'},
                {Column1: 'АМГ5М', Column2: '0,8-10', Column3: '1200-1500', Column4: '3000-4000'},
                {Column1: 'АМГ6', Column2: '0,8-10', Column3: '1200-1500', Column4: '3000-4000'},

            ]
        },
        {
            id: 3,
            img: kvintet,
            image: kvintet,

            title: 'Лист алюминиевый квинтет',
            description: '',
            columnsHeader: [
                {title: 'sheetSteelEN10025.Column1', field: 'Column1'},
                {title: 'sheetSteelEN10025.Column2', field: 'Column2'},
                {title: 'sheetSteelEN10025.Column3', field: 'Column3'},
                {title: 'sheetSteelEN10025.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: '',
            type: ['Рифленый алюминиевый лист "КВИНТЕТ" имеет рельефную, шероховатую, матовую поверхность. Выпуклый рисунок формируется на гладкой поверхности листа в виде рифлей (ребер), которые расположены под углом друг к другу. При определении толщины такого листа, не учитывается высота рельефа.'],


            titleEn: 'Лист алюминиевый квинтет',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: '',
            typeEn: ['Рифленый алюминиевый лист "КВИНТЕТ" имеет рельефную, шероховатую, матовую поверхность. Выпуклый рисунок формируется на гладкой поверхности листа в виде рифлей (ребер), которые расположены под углом друг к другу. При определении толщины такого листа, не учитывается высота рельефа.'],
            table: [
                {title: 'Лист алюминиевый квинтет', titleEn: 'Лист алюминиевый квинтет', data: 'data1'},
            ],


            data1: [
                {Column1: 'АМГ2Н2 Р (5052 H114 QUINTET)*', Column2: '1,2-3', Column3: '1200-1500', Column4: '3000'},
                {Column1: 'АМГ3Н2 Р (5754 H114 QUINTET)*', Column2: '5', Column3: '1500', Column4: '3000'},
            ]
        },
        {
            id: 4,
            img: truba,
            image: truba,


            title: 'Труба стальная нержавеющая',
            description: '',
            columnsHeader: [
                {title: 'trubaNoCorrosion.Column1', field: 'Column1'},
                {title: 'trubaNoCorrosion.Column2', field: 'Column2'},
                {title: 'trubaNoCorrosion.Column3', field: 'Column3'},
                {title: 'trubaNoCorrosion.Column4', field: 'Column4'},
                {title: 'trubaNoCorrosion.Column5', field: 'Column5'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: '',
            type: ['Применение:','- для возведения несущих конструкций в строительстве', '- в машиностроительной и энергетической отраслях;', '- в нефтехимии;', '- в пищевой отрасли;', '- при создании дизайна уличных конструкций и оформления различных помещений.'],


            titleEn: 'Труба стальная нержавеющая',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: '',
            typeEn: ['Применение:','- для возведения несущих конструкций в строительстве', '- в машиностроительной и энергетической отраслях;', '- в нефтехимии;', '- в пищевой отрасли;', '- при создании дизайна уличных конструкций и оформления различных помещений.'],
            table: [
                {title: 'Труба стальная нержавеющая', titleEn: 'Труба стальная нержавеющая', data: 'data1'},
            ],



            data1: [
                {Column1: '12Х18Н10Т', Column2: '8', Column3: '1,5-3', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '16', Column3: '1,5-3,5', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '20', Column3: '1,5-4', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '25', Column3: '1-5', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '32', Column3: '1,5-5', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '40', Column3: '2-5', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '50', Column3: '2-6', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '60', Column3: '3-8', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '76', Column3: '3-10', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '89', Column3: '3-12', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '102', Column3: '4-14', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '108', Column3: '4-16', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '114', Column3: '4-18', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '133', Column3: '5-20', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: '12Х18Н10Т', Column2: '219', Column3: '5-20', Column4: '6000', Column5: 'Бесшовная'},
                {Column1: 'AISI 201/304', Column2: '10', Column3: '1-2', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '16', Column3: '0,6-1,5', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '21,3', Column3: '1,5-3', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '33,7', Column3: '1,5-3', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '45', Column3: '2,5-3', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304/316L', Column2: '50,8', Column3: '1,2-3', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '60,3', Column3: '1,5-3', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '76,1', Column3: '1,5-3', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '88,9', Column3: '1,5-4', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '101,6', Column3: '2-4', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
                {Column1: 'AISI 201/304', Column2: '114', Column3: '2-4', Column4: '6000', Column5: 'Электросварная-зеркало/шлиф./матов.'},
            ]
        },
    ],
    table: {
        1: {},
        2: {},
        3: {},
        4: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
        4: {},
    },

};
