import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {catalogReducer} from "./";


const reducers = combineReducers({
    catalogPage: catalogReducer
});

export const store = createStore(reducers, applyMiddleware(thunk));

window.store = store;
