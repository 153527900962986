import React, {Fragment} from 'react';
import {Categories, Contacts, Menu} from "./index";

const Footer = (props) => {
    const now = new Date();
    const year = now.getFullYear();
    return <div>
        <div className={"footerLine"}/>
        <div className={"footerWrapper"}>
            <div className={"footerBottom"}>
                <div className={"contacts"}>
                    <Contacts/>
                </div>
                <div className={"menu"}>
                    <Menu/>
                </div>
                <div className={"menu"}>
                    <Categories/>
                </div>
            </div>
        </div>
        <footer className="footer">
            <p>All rights reserved © {year}</p>
        </footer>

    </div>
};

export default Footer
