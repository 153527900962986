import React from "react";
import {ListWrapper} from "../ui/Catalog";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {roundPipe} from "../repository/round-pipe";
import DetailsProduct from "../ui/Catalog/DetailsProducts";


const RoundPipeController = (props) => {
    return <div>
        {!props.match.params.id
            ? <ListWrapper {...props} url={'roundpipe'}/>
            : <DetailsProduct products={roundPipe} id={props.match.params.id}/>}
    </div>
};

const mapState = (state) => {
   return {
       data: state.catalogPage.roundPipe
   }
};

export default withRouter(connect(mapState, null)(RoundPipeController))
