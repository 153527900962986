import panelImg from '../assets/img/catalog/panelImg.jpg'
import panel2 from '../assets/img/catalog/panel2.jpg'


export const panel = {
    list: [
        {
            id: 1,
            img: panelImg,
            image: panelImg,

            title: 'Сэндвич-панель стеновая типа ПСБ',
            description: '',
            columnsHeader: [
                {title: 'panel.Column1', field: 'Column1'},
                {title: 'panel.Column2', field: 'Column2'},
                {title: 'panel.Column3', field: 'Column3'},
                {title: 'panel.Column4', field: 'Column4'},
                {title: 'panel.Column5', field: 'Column4'},
                {title: 'panel.Column6', field: 'Column4'},
                {title: 'panel.Column7', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: '*класс пожарной опасности КО(45) по ГОСТ 30403-2012',
            type: ['E - предел огнестойкости по потере целостности;', 'I - предел огнестойкости по потере теплоизолирующей способности;', 'Цвета RAL:', '- RAL 5002 - Ультрамарин; RAL 5005 - Сигнально-синий;', '- RAL 6005 - Зеленый мох; RAL 7004 - Сигнально-серый;', '- RAL 7035 - Светло-серый; RAL 8017 - Шоколад;', '- RAL 9003 - Сигнально-белый; RAL 9006 - Светло-алюминий;'],


            titleEn: 'Сэндвич-панель стеновая типа ПСБ',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: '*класс пожарной опасности КО(45) по ГОСТ 30403-2012',
            typeEn: ['E - предел огнестойкости по потере целостности;', 'I - предел огнестойкости по потере теплоизолирующей способности;', 'Цвета RAL:', '- RAL 5002 - Ультрамарин; RAL 5005 - Сигнально-синий;', '- RAL 6005 - Зеленый мох; RAL 7004 - Сигнально-серый;', '- RAL 7035 - Светло-серый; RAL 8017 - Шоколад;', '- RAL 9003 - Сигнально-белый; RAL 9006 - Светло-алюминий;'],
            table: [
                {title: 'Сэндвич-панель стеновая типа ПСБ', titleEn: 'Сэндвич-панель стеновая типа ПСБ', data: 'data1'},
            ],


            data1: [
                {Column1: '50', Column2: '1000, 1160, 1190, 1200', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '13,5 / 16,6',  Column6: '1,38 / 1,06',  Column7: 'EI60'},
                {Column1: '80', Column2: '1000, 1160, 1190, 1200', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '16,5 / 19,7',  Column6: '2,11 / 1,75',  Column7: 'EI90'},
                {Column1: '100', Column2: '1000, 1160, 1190, 1200', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '18,5 / 21,8',  Column6: '2,62 / 2,18',  Column7: 'EI90'},
                {Column1: '120', Column2: '1000, 1160, 1190, 1200', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '20,3 / 23,5',  Column6: '3,11 / 2,65',  Column7: 'EI150'},
                {Column1: '150', Column2: '1000, 1160, 1190, 1200', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '23,6 / 26,8',  Column6: '3,89 / 3,21',  Column7: 'EI150'},
                {Column1: '200', Column2: '1000, 1160, 1190, 1200', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '28,4 / 31,6',  Column6: '5,12 / 4,33',  Column7: 'EI150'},
            ]
        },
        {
            id: 2,
            img: panel2,
            image: panel2,

            title: 'Сэндвич-панель кровельная типа ПКБ',
            description: '',
            columnsHeader: [
                {title: 'panel.Column1', field: 'Column1'},
                {title: 'panel.Column2', field: 'Column2'},
                {title: 'panel.Column3', field: 'Column3'},
                {title: 'panel.Column4', field: 'Column4'},
                {title: 'panel.Column5', field: 'Column4'},
                {title: 'panel.Column6', field: 'Column4'},
                {title: 'panel.Column7', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: '*класс пожарной опасности КО(45) по ГОСТ 30403-2012',
            type: ['RE - предел огнестойкости по потере целостности,\n' +
            'несущей, теплоизолирующей способности независи-\n' +
            'мо от того, какое из предельных состояний наступит\n' +
            'ранее', 'Цвета RAL:', '- RAL 5002 - Ультрамарин; RAL 5005 - Сигнально-синий;', '- RAL 6005 - Зеленый мох; RAL 7004 - Сигнально-серый;', '- RAL 7035 - Светло-серый; RAL 8017 - Шоколад;', '- RAL 9003 - Сигнально-белый; RAL 9006 - Светло-алюминий;'],


            titleEn: 'Сэндвич-панель кровельная типа ПКБ',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: '*класс пожарной опасности КО(45) по ГОСТ 30403-2012',
            typeEn: ['RE - предел огнестойкости по потере целостности,\n' +
            'несущей, теплоизолирующей способности независи-\n' +
            'мо от того, какое из предельных состояний наступит\n' +
            'ранее', 'Цвета RAL:', '- RAL 5002 - Ультрамарин; RAL 5005 - Сигнально-синий;', '- RAL 6005 - Зеленый мох; RAL 7004 - Сигнально-серый;', '- RAL 7035 - Светло-серый; RAL 8017 - Шоколад;', '- RAL 9003 - Сигнально-белый; RAL 9006 - Светло-алюминий;'],

            table: [
                {title: 'Сэндвич-панель кровельная типа ПКБ', titleEn: 'Сэндвич-панель кровельная типа ПКБ', data: 'data1'},
            ],

            data1: [
                {Column1: '50', Column2: '1000', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '17,7 / 19,8',  Column6: '1,21',  Column7: 'RE130'},
                {Column1: '80', Column2: '1000', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '21,4 / 23,3',  Column6: '2,01',  Column7: 'RE130'},
                {Column1: '100', Column2: '1000', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '23,8 / 25,8',  Column6: '2,42',  Column7: 'RE130'},
                {Column1: '120', Column2: '1000', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '26,3 / 28,1',  Column6: '2,95',  Column7: 'RE160'},
                {Column1: '150', Column2: '1000', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '29,9 /31,8',  Column6: '3,66',  Column7: 'RE160'},
                {Column1: '200', Column2: '1000', Column3: '2000-12000', Column4: '0,50 / 0,70',  Column5: '35,9 / 37,8',  Column6: '4,89',  Column7: 'RE160'},
            ]
        },
    ],
    table: {
        1: {},
        2: {},
        3: {},
        4: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
        4: {},
    },

};
