import React from 'react'
import {translate} from "react-i18next";


const Contacts = (props) => {
    return <div style={{marginLeft: 20}}>
        <div style={{fontWeight: 'bold', fontSize: 24, color: '#213e7e'}}>{props.t("footer.contacts")}</div>
        <div className={"contacts-page"}>
            <div>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginBottom: 10,
                    color: '#84745b'
                }}>{props.t("contacts.title1")}</div>
                <div style={{marginLeft: 20}}>
                    <span className={"icon-email"}/><a href={"mailto:sales@maximaltd.kz"}><p
                    style={{fontSize: 14, fontWeight: 600, color: 'black'}}>sales@maximaltd.kz</p></a>
                </div>
                <div style={{marginLeft: 20}}>
                    <span className={"icon-email"}/><a href={"mailto:sales2@maximaltd.kz"}><p
                    style={{fontSize: 14, fontWeight: 600, color: 'black'}}>sales2@maximaltd.kz</p></a>
                </div>
                <div style={{
                    fontWeight: 'bold',
                    fontSize: 18,
                    marginBottom: 10,
                    color: '#84745b'
                }}>{props.t("contacts.title2")}</div>
                <div style={{marginLeft: 20}}>
                    <span className="icon-phone"/><p style={{fontWeight: 'bold'}}><span
                    style={{fontSize: 14, fontWeight: 600, color: 'black'}}>7(705)791-60-11</span> <a
                    href={"https://wa.me/77057916011"} target={'_blank'}
                    style={{color: '#25D366', cursor: 'pointer'}}>(WhatsApp)</a></p>
                </div>
                <div style={{marginLeft: 20}}>
                    <span className="icon-phone"/><p style={{fontWeight: 'bold'}}><span
                    style={{fontSize: 14, fontWeight: 600, color: 'black'}}>+7(7122)76-64-96</span></p>
                </div>
                <div style={{marginLeft: 20}}>
                    <span className="icon-phone"/><p style={{fontWeight: 'bold'}}><span
                    style={{fontSize: 14, fontWeight: 600, color: 'black'}}>+7(778)298-27-08</span></p>
                </div>
                <div style={{marginLeft: 20}}>
                    <span className="icon-skype"/><p style={{fontSize: 14, fontWeight: 600, color: 'black'}}><a
                    style={{color: '#00aff0', cursor: 'pointer'}} href={'Skype:MAXIMA LTD stockholder?chat'}>MAXIMA LTD
                    stockholder</a></p>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', color: '#213e7e'}}>
                    <span style={{
                        fontWeight: 'bold',
                        fontFamily: 'Verdana',
                        fontSize: 16,
                    }}>{props.t("contacts.address1")}</span>
                    <div style={{fontFamily: 'Verdana', fontSize: 14, fontWeight: 600}}>{props.t("contacts.address2")}
                    </div>
                    <div style={{fontFamily: 'Verdana', fontSize: 14, fontWeight: 600}}>{props.t("contacts.address3")}
                    </div>
                </div>
            </div>
        </div>

    </div>

};

export default translate('common')(Contacts)
