import truba_pr from '../assets/img/catalog/Special-Section-Tube-Mild-Steel.jpg'
import truba_pr_model from '../assets/img/catalog/truba-pr-model.jpg'
import truba_kv from '../assets/img/catalog/10b94ba050aa8fa74073bfec8ea4688f.jpg'
import ovalnaya from '../assets/img/catalog/ovalnaya.png'
import oval from '../assets/img/catalog/oval2.png'
import truba_model from '../assets/img/catalog/truba-kv-model.png'

export const profilePipe = {
    list: [
        {
            id: 1,
            img: truba_kv,
            image: truba_model,

            title: 'Труба квадратная',
            description: '',
            columnsHeader: [
                {title: 'pipePrSquare.Column1', field: 'Column1'},
                {title: 'pipePrSquare.Column2', field: 'Column2'},
                {title: 'pipePrSquare.Column3', field: 'Column3'},
                {title: 'pipePrSquare.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст2пс, Ст2сп, S235JRH, S275J0H, S275J2H, S355J0H, S355J2H, S275NLH, S355NLH',
            type: ['ГОСТ 8639-82 Трубы стальные квадратные',
                'ГОСТ 30245-2003 ПРОФИЛИ СТАЛЬНЫЕ ГНУТЫЕ ЗАМКНУТЫЕ СВАРНЫЕ КВАДРАТНЫЕ И ПРЯМОУГОЛЬНЫЕ ДЛЯ СТРОИТЕЛЬНЫХ КОНСТРУКЦИЙ',
                'EN 10219 Профили полые сварные конструкционные',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Трубы поставляются длиной от 6 и до 12 м.'
            ],

            titleEn: 'Труба квадратная',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст2пс, Ст2сп, S235JRH, S275J0H, S275J2H, S355J0H, S355J2H, S275NLH, S355NLH',
            typeEn: ['ГОСТ 8639-82 Трубы стальные квадратные',
                'ГОСТ 30245-2003 ПРОФИЛИ СТАЛЬНЫЕ ГНУТЫЕ ЗАМКНУТЫЕ СВАРНЫЕ КВАДРАТНЫЕ И ПРЯМОУГОЛЬНЫЕ ДЛЯ СТРОИТЕЛЬНЫХ КОНСТРУКЦИЙ',
                'EN 10219 Профили полые сварные конструкционные',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Трубы поставляются длиной от 6 и до 12 м.'
            ],
            table: [
                {title: 'Труба квадратная', titleEn: 'Труба квадратная', data: 'data1'},
            ],

            data1: [
                {Column1: '10х10', Column2: '1,2', Column3: '0,40', Column4: '0,31'},
                {Column1: '15х15', Column2: '1,5', Column3: '0,77', Column4: '0,61'},
                {Column1: '20х20', Column2: '1,5', Column3: '1,07', Column4: '0,84'},
                {Column1: '25х25', Column2: '1,5', Column3: '1,37', Column4: '1,07'},
                {Column1: '25х25', Column2: '2,0', Column3: '1,74', Column4: '1,36'},
                {Column1: '30х30', Column2: '2,0', Column3: '2,14', Column4: '1,68'},
                {Column1: '30х30', Column2: '3,0', Column3: '3,08', Column4: '2,42'},
                {Column1: '40х40', Column2: '2,0', Column3: '2,97', Column4: '2,31'},
                {Column1: '40х40', Column2: '3,0', Column3: '4,28', Column4: '3,36'},
                {Column1: '40х40', Column2: '4,0', Column3: '5,48', Column4: '4,30'},
                {Column1: '50х50', Column2: '2,0', Column3: '3,77', Column4: '2,96'},
                {Column1: '50х50', Column2: '3,0', Column3: '5,48', Column4: '4,31'},
                {Column1: '50х50', Column2: '4,0', Column3: '7,08', Column4: '5,56'},
                {Column1: '50х50', Column2: '5,0', Column3: '8,57', Column4: '6,73'},
                {Column1: '60х60', Column2: '2,0', Column3: '4,57', Column4: '3,59'},
                {Column1: '60х60', Column2: '3,0', Column3: '6,69', Column4: '5,25'},
                {Column1: '60х60', Column2: '4,0', Column3: '8,68', Column4: '6,82'},
                {Column1: '60х60', Column2: '5,0', Column3: '10,57', Column4: '8,30'},
                {Column1: '60х60', Column2: '6,0', Column3: '12,34', Column4: '9,69'},
                {Column1: '70х70', Column2: '3,0', Column3: '7,88', Column4: '6,19'},
                {Column1: '70х70', Column2: '4,0', Column3: '10,28', Column4: '8,07'},
                {Column1: '70х70', Column2: '5,0', Column3: '12,57', Column4: '9,87'},
                {Column1: '80х80', Column2: '3,0', Column3: '9,09', Column4: '7,13'},
                {Column1: '80х80', Column2: '4,0', Column3: '11,88', Column4: '9,33'},
                {Column1: '80х80', Column2: '5,0', Column3: '14,57', Column4: '11,44'},
                {Column1: '80х80', Column2: '6,0', Column3: '17,14', Column4: '13,46'},
                {Column1: '100х100', Column2: '3,0', Column3: '11,48', Column4: '9,02'},
                {Column1: '100х100', Column2: '4,0', Column3: '15,08', Column4: '11,84'},
                {Column1: '100х100', Column2: '5,0', Column3: '18,57', Column4: '14,58'},
                {Column1: '100х100', Column2: '6,0', Column3: '21,94', Column4: '17,22'},
                {Column1: '100х100', Column2: '7,0', Column3: '25,20', Column4: '19,78'},
                {Column1: '100х100', Column2: '8,0', Column3: '28,34', Column4: '22,25'},
                {Column1: '100х100', Column2: '10,0', Column3: '32,60', Column4: '25,60'},
                {Column1: '120х120', Column2: '3,0', Column3: '13,80', Column4: '10,80'},
                {Column1: '120х120', Column2: '4,0', Column3: '18,10', Column4: '14,20'},
                {Column1: '120х120', Column2: '5,0', Column3: '22,40', Column4: '17,50'},
                {Column1: '120х120', Column2: '6,0', Column3: '26,74', Column4: '21,00'},
                {Column1: '120х120', Column2: '7,0', Column3: '30,80', Column4: '24,18'},
                {Column1: '120х120', Column2: '8,0', Column3: '34,74', Column4: '27,27'},
                {Column1: '140х140', Column2: '5,0', Column3: '26,40', Column4: '20,70'},
                {Column1: '140х140', Column2: '6,0', Column3: '31,54', Column4: '24,76'},
                {Column1: '140х140', Column2: '7,0', Column3: '36,40', Column4: '28,57'},
                {Column1: '140х140', Column2: '8,0', Column3: '41,14', Column4: '32,29'},
                {Column1: '150х150', Column2: '4,0', Column3: '22,90', Column4: '18,00'},
                {Column1: '150х150', Column2: '5,0', Column3: '28,40', Column4: '22,30'},
                {Column1: '150х150', Column2: '6,0', Column3: '33,60', Column4: '26,40'},
                {Column1: '150х150', Column2: '7,0', Column3: '39,20', Column4: '20,77'},
                {Column1: '150х150', Column2: '8,0', Column3: '44,34', Column4: '34,81'},
                {Column1: '160х160', Column2: '4,0', Column3: '24,50', Column4: '19,30'},
                {Column1: '160х160', Column2: '5,0', Column3: '30,40', Column4: '23,80'},
                {Column1: '160х160', Column2: '6,0', Column3: '36,00', Column4: '28,30'},
                {Column1: '160х160', Column2: '8,0', Column3: '46,40', Column4: '36,50'},
                {Column1: '180х180', Column2: '5,0', Column3: '39,80', Column4: '31,00'},
                {Column1: '180х180', Column2: '6,0', Column3: '41,00', Column4: '32,10'},
                {Column1: '180х180', Column2: '8,0', Column3: '52,80', Column4: '41,50'},
                {Column1: '180х180', Column2: '10,0', Column3: '64,60', Column4: '50,70'},
                {Column1: '180х180', Column2: '12,0', Column3: '74,50', Column4: '58,50'},
                {Column1: '200х200', Column2: '5,0', Column3: '38,40', Column4: '30,10'},
                {Column1: '200х200', Column2: '6,0', Column3: '45,60', Column4: '35,80'},
                {Column1: '200х200', Column2: '8,0', Column3: '59,20', Column4: '46,50'},
                {Column1: '200х200', Column2: '11,0', Column3: '72,60', Column4: '57,00'},
                {Column1: '200х200', Column2: '12,0', Column3: '84,10', Column4: '66,00'},
                {Column1: '250х250', Column2: '6,0', Column3: '57,60', Column4: '45,20'},
                {Column1: '250х250', Column2: '8,0', Column3: '75,20', Column4: '59,10'},
                {Column1: '250х250', Column2: '10,0', Column3: '92,60', Column4: '72,70'},
                {Column1: '250х250', Column2: '12,0', Column3: '108,00', Column4: '84,80'},
                {Column1: '300х300', Column2: '6,0', Column3: '69,60', Column4: '54,70'},
                {Column1: '300х300', Column2: '8,0', Column3: '91,20', Column4: '71,60'},
                {Column1: '300х300', Column2: '10,0', Column3: '113,00', Column4: '88,40'},
                {Column1: '300х300', Column2: '12,0', Column3: '132,00', Column4: '104,00'},
                {Column1: '350х350', Column2: '8,0', Column3: '107,00', Column4: '84,20'},
                {Column1: '350х350', Column2: '10,0', Column3: '133,00', Column4: '104,00'},
                {Column1: '350х350', Column2: '12,0', Column3: '156,00', Column4: '123,00'},
                {Column1: '350х350', Column2: '16,0', Column3: '203,00', Column4: '159,00'},
                {Column1: '400х400', Column2: '10,0', Column3: '153,00', Column4: '120,00'},
                {Column1: '400х400', Column2: '12,0', Column3: '180,00', Column4: '141,00'},
                {Column1: '400х400', Column2: '16,0', Column3: '235,00', Column4: '184,00'},

            ],
        },
        {
            id: 2,
            img: truba_pr,
            image: truba_pr_model,

            title: 'Труба прямоугольная',
            description: '',
            columnsHeader: [
                {title: 'pipePrSquare.Column1', field: 'Column1'},
                {title: 'pipePrSquare.Column2', field: 'Column2'},
                {title: 'pipePrSquare.Column3', field: 'Column3'},
                {title: 'pipePrSquare.Column4', field: 'Column4'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст2пс, Ст2сп, S235JRH, S275J0H, S275J2H, S355J0H, S355J2H, S275NLH, S355NLH',
            type: ['ГОСТ 8639-82 Трубы стальные квадратные',
                'ГОСТ 30245-2003 ПРОФИЛИ СТАЛЬНЫЕ ГНУТЫЕ ЗАМКНУТЫЕ СВАРНЫЕ КВАДРАТНЫЕ И ПРЯМОУГОЛЬНЫЕ ДЛЯ СТРОИТЕЛЬНЫХ КОНСТРУКЦИЙ',
                'EN 10219 Профили полые сварные конструкционные',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Трубы поставляются длиной от 6 и до 12 м.'
            ],


            titleEn: 'Труба прямоугольная',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст2пс, Ст2сп, S235JRH, S275J0H, S275J2H, S355J0H, S355J2H, S275NLH, S355NLH',
            typeEn: ['ГОСТ 8639-82 Трубы стальные квадратные',
                'ГОСТ 30245-2003 ПРОФИЛИ СТАЛЬНЫЕ ГНУТЫЕ ЗАМКНУТЫЕ СВАРНЫЕ КВАДРАТНЫЕ И ПРЯМОУГОЛЬНЫЕ ДЛЯ СТРОИТЕЛЬНЫХ КОНСТРУКЦИЙ',
                'EN 10219 Профили полые сварные конструкционные',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Трубы поставляются длиной от 6 и до 12 м.'
            ],
            table: [
                {title: 'Труба прямоугольная', titleEn: 'Труба прямоугольная', data: 'data1'},
            ],


            data1: [
                {Column1: '20х10', Column2: '1,2', Column3: '0,64', Column4: '0,50'},
                {Column1: '20х10', Column2: '1,5', Column3: '0,77', Column4: '0,61'},
                {Column1: '25х10', Column2: '1,2', Column3: '0,84', Column4: '0,68'},
                {Column1: '25х10', Column2: '1,5', Column3: '0,92', Column4: '0,72'},
                {Column1: '28х25', Column2: '1,5', Column3: '1,38', Column4: '1,05'},
                {Column1: '28х25', Column2: '2,0', Column3: '1,85', Column4: '1,35'},
                {Column1: '30х15', Column2: '1,5', Column3: '1,22', Column4: '0,96'},
                {Column1: '30х15', Column2: '2,0', Column3: '1,57', Column4: '1,23'},
                {Column1: '30х20', Column2: '1,5', Column3: '1,37', Column4: '1,08'},
                {Column1: '30х20', Column2: '2,0', Column3: '1,77', Column4: '1,39'},
                {Column1: '40х20', Column2: '2,0', Column3: '2,17', Column4: '1,70'},
                {Column1: '40х20', Column2: '2,5', Column3: '2,64', Column4: '2,07'},
                {Column1: '40х20', Column2: '3,0', Column3: '3,08', Column4: '2,42'},
                {Column1: '40х25', Column2: '2,0', Column3: '2,37', Column4: '1,86'},
                {Column1: '40х25', Column2: '2,5', Column3: '2,89', Column4: '2,27'},
                {Column1: '40х25', Column2: '3,0', Column3: '3,38', Column4: '2,66'},
                {Column1: '50х25', Column2: '2,0', Column3: '2,77', Column4: '2,17'},
                {Column1: '50х25', Column2: '2,5', Column3: '3,39', Column4: '2,66'},
                {Column1: '50х25', Column2: '3,0', Column3: '3,98', Column4: '3,13'},
                {Column1: '50х30', Column2: '2,0', Column3: '2,97', Column4: '2,32'},
                {Column1: '50х30', Column2: '2,5', Column3: '3,64', Column4: '2,86'},
                {Column1: '50х30', Column2: '3,0', Column3: '4,28', Column4: '3,36'},
                {Column1: '50х30', Column2: '3,5', Column3: '4,90', Column4: '3,85'},
                {Column1: '50х40', Column2: '2,0', Column3: '3,37', Column4: '2,65'},
                {Column1: '50х40', Column2: '3,0', Column3: '4,88', Column4: '3,83'},
                {Column1: '50х40', Column2: '4,0', Column3: '6,28', Column4: '4,93'},
                {Column1: '60х30', Column2: '2,0', Column3: '3,37', Column4: '2,65'},
                {Column1: '60х30', Column2: '3,0', Column3: '4,88', Column4: '3,83'},
                {Column1: '60х30', Column2: '4,0', Column3: '6,28', Column4: '4,93'},
                {Column1: '60х40', Column2: '2,0', Column3: '3,37', Column4: '2,96'},
                {Column1: '60х40', Column2: '3,0', Column3: '5,48', Column4: '4,30'},
                {Column1: '60х40', Column2: '4,0', Column3: '7,08', Column4: '5,56'},
                {Column1: '60х40', Column2: '5,0', Column3: '8,57', Column4: '6,73'},
                {Column1: '80х40', Column2: '2,0', Column3: '4,57', Column4: '3,59'},
                {Column1: '80х40', Column2: '3,0', Column3: '6,68', Column4: '5,25'},
                {Column1: '80х40', Column2: '4,0', Column3: '8,68', Column4: '6,82'},
                {Column1: '80х60', Column2: '3,0', Column3: '8,90', Column4: '7,00'},
                {Column1: '80х60', Column2: '4,0', Column3: '10,28', Column4: '8,07'},
                {Column1: '80х60', Column2: '5,0', Column3: '12,57', Column4: '9,87'},
                {Column1: '100х40', Column2: '3,0', Column3: '9,89', Column4: '7,85'},
                {Column1: '100х40', Column2: '4,0', Column3: '10,28', Column4: '8,07'},
                {Column1: '100х50', Column2: '3,0', Column3: '10,56', Column4: '8,25'},
                {Column1: '100х50', Column2: '4,0', Column3: '11,08', Column4: '8,70'},
                {Column1: '100х50', Column2: '5,0', Column3: '13,57', Column4: '10,65'},
                {Column1: '100х60', Column2: '3,0', Column3: '9,09', Column4: '7,13'},
                {Column1: '100х60', Column2: '4,0', Column3: '11,88', Column4: '9,33'},
                {Column1: '100х60', Column2: '5,0', Column3: '12,30', Column4: '9,86'},
                {Column1: '100х80', Column2: '4,0', Column3: '13,60', Column4: '10,70'},
                {Column1: '100х80', Column2: '5,0', Column3: '16,70', Column4: '13,10'},
                {Column1: '120х80', Column2: '4,0', Column3: '15,20', Column4: '11,90'},
                {Column1: '120х80', Column2: '5,0', Column3: '18,70', Column4: '17,40'},
                {Column1: '120х80', Column2: '6,0', Column3: '22,20', Column4: '10,60'},
                {Column1: '120х40', Column2: '4,0', Column3: '13,86', Column4: '11,44'},
                {Column1: '120х40', Column2: '5,0', Column3: '14,57', Column4: '10,70'},
                {Column1: '120х60', Column2: '4,0', Column3: '13,60', Column4: '13,00'},
                {Column1: '120х60', Column2: '5,0', Column3: '16,57', Column4: '19,05'},
                {Column1: '140х100', Column2: '4,0', Column3: '24,54', Column4: '21,08'},
                {Column1: '140х100', Column2: '6,0', Column3: '25,85', Column4: '23,50'},
                {Column1: '140х100', Column2: '7,0', Column3: '29,40', Column4: '18,60'},
                {Column1: '150х100', Column2: '5,0', Column3: '23,70', Column4: '22,10'},
                {Column1: '150х100', Column2: '6,0', Column3: '28,10', Column4: '28,90'},
                {Column1: '150х100', Column2: '8,0', Column3: '36,80', Column4: '14,40'},
                {Column1: '160х80', Column2: '4,0', Column3: '18,40', Column4: '21,20'},
                {Column1: '160х80', Column2: '6,0', Column3: '27,00', Column4: '27,60'},
                {Column1: '160х80', Column2: '8,0', Column3: '35,20', Column4: '20,60'},
                {Column1: '160х120', Column2: '4,0', Column3: '25,70', Column4: '27,00'},
                {Column1: '160х120', Column2: '6,0', Column3: '36,90', Column4: '35,00'},
                {Column1: '160х120', Column2: '8,0', Column3: '44,90', Column4: '43,00'},
                {Column1: '160х140', Column2: '6,0', Column3: '64,00', Column4: '26,00'},
                {Column1: '180х100', Column2: '6,0', Column3: '33,00', Column4: '29,00'},
                {Column1: '180х100', Column2: '7,0', Column3: '37,00', Column4: '32,60'},
                {Column1: '180х100', Column2: '8,0', Column3: '41,60', Column4: '23,70'},
                {Column1: '180х140', Column2: '5,0', Column3: '30,23', Column4: '28,10'},
                {Column1: '180х140', Column2: '6,0', Column3: '35,85', Column4: '36,60'},
                {Column1: '180х140', Column2: '8,0', Column3: '46,60', Column4: '18,20'},
                {Column1: '200х100', Column2: '4,0', Column3: '23,20', Column4: '22,60'},
                {Column1: '200х100', Column2: '5,0', Column3: '28,70', Column4: '26,80'},
                {Column1: '200х100', Column2: '6,0', Column3: '34,20', Column4: '35,00'},
                {Column1: '200х100', Column2: '7,0', Column3: '44,00', Column4: '22,90'},
                {Column1: '200х120', Column2: '4,0', Column3: '28,50', Column4: '24,10'},
                {Column1: '200х120', Column2: '5,0', Column3: '30,70', Column4: '30,00'},
                {Column1: '200х120', Column2: '6,0', Column3: '38,00', Column4: '37,60'},
                {Column1: '200х120', Column2: '8,0', Column3: '48,00', Column4: '27,65'},
                {Column1: '200х160', Column2: '5,0', Column3: '34,80', Column4: '33,00'},
                {Column1: '200х160', Column2: '6,0', Column3: '41,70', Column4: '43,50'},
                {Column1: '200х160', Column2: '8,0', Column3: '54,80', Column4: '53,70'},
                {Column1: '200х160', Column2: '10,0', Column3: '67,50', Column4: '27,65'},
                {Column1: '240х120', Column2: '5,0', Column3: '34,80', Column4: '33,00'},
                {Column1: '240х120', Column2: '6,0', Column3: '41,30', Column4: '43,50'},
                {Column1: '240х120', Column2: '8,0', Column3: '54,60', Column4: '30,80'},
                {Column1: '240х160', Column2: '5,0', Column3: '38,80', Column4: '36,80'},
                {Column1: '250х150', Column2: '6,0', Column3: '46,20', Column4: '48,54'},
                {Column1: '250х150', Column2: '8,0', Column3: '61,10', Column4: '60,00'},
                {Column1: '250х150', Column2: '10,0', Column3: '75,80', Column4: '36,80'},
                {Column1: '300х100', Column2: '6,0', Column3: '46,20', Column4: '48,54'},
                {Column1: '300х100', Column2: '8,0', Column3: '61,00', Column4: '46,26'},
                {Column1: '300х200', Column2: '6,0', Column3: '58,35', Column4: '61,18'},
                {Column1: '300х200', Column2: '8,0', Column3: '77,30', Column4: '76,00'},
                {Column1: '300х200', Column2: '10,0', Column3: '95,80', Column4: '81,30'},
                {Column1: '300х250', Column2: '10,0', Column3: '105,00', Column4: '83,80'},

            ],
        },
        {
            id: 3,
            img: ovalnaya,
            image: oval,

            title: 'Труба плоскоовальная',
            description: '',
            columnsHeader: [
                {title: 'roundPipe3.Column1', field: 'Column1'},
                {title: 'roundPipe3.Column2', field: 'Column2'},
                {title: 'roundPipe3.Column3', field: 'Column3'},
            ],
            descriptionDetails: 'Плоскоовальная труба — это одна из наиболее редких позиций на рынке металлопроката. Довольно небольшое количество заводов в мире могут предложить плоскоовальные трубы из холоднокатанного или горячекатанного штрипса.\n' +
                'Наш завод является одним из немногих на российском рынке предприятий, производящих овальные трубы по ГОСТ 8644-68. Наша труба производится из стали ст08сп или другой стали по усмотрению заказчика.\n' +
                'Область применения плоскоовальной трубы\n' +
                'Спрос на плоскоовальную трубу, как правило, обеспечивают производители наружных  и внутренних металлоконструкций и металлоизделий. Внутри помещения эти трубы чаще используются при производстве металлической мебели, а именно, в медицинских, образовательных учреждениях, прокладки инженерных коммуникаций, создании элементов декора.',
            listDetails: [''],
            steel: 'Марки стали: Ст08сп',
            type: ['ГОСТ 8644-68 Трубы стальные плоскоовальные',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'
            ],


            titleEn: 'Труба плоскоовальная',
            descriptionEn: '',
            descriptionDetailsEn: 'Плоскоовальная труба — это одна из наиболее редких позиций на рынке металлопроката. Довольно небольшое количество заводов в мире могут предложить плоскоовальные трубы из холоднокатанного или горячекатанного штрипса.\n' +
                'Наш завод является одним из немногих на российском рынке предприятий, производящих овальные трубы по ГОСТ 8644-68. Наша труба производится из стали ст08сп или другой стали по усмотрению заказчика.\n' +
                'Область применения плоскоовальной трубы\n' +
                'Спрос на плоскоовальную трубу, как правило, обеспечивают производители наружных  и внутренних металлоконструкций и металлоизделий. Внутри помещения эти трубы чаще используются при производстве металлической мебели, а именно, в медицинских, образовательных учреждениях, прокладки инженерных коммуникаций, создании элементов декора.',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст08сп',
            typeEn: ['ГОСТ 8644-68 Трубы стальные плоскоовальные',
                'ГОСТ  380-2005 Сталь конструкционная углеродистая обыкновенного качества.'
            ],
            table: [
                {title: 'Труба плоскоовальная', titleEn: 'Труба плоскоовальная', data: 'data1'},
            ],


            data1: [
                {Column1: '30x15', Column2: '0,8', Column3: '6000'},
                {Column1: '30x15', Column2: '1,0', Column3: '6000'},
                {Column1: '30x15', Column2: '1,2', Column3: '6000'},
                {Column1: '30x15', Column2: '1,4', Column3: '6000'},
                {Column1: '30x15', Column2: '1,8', Column3: '6000'},
                {Column1: '30x15', Column2: '2,0', Column3: '6000'},
                {Column1: '40x20', Column2: '1,0', Column3: '6000'},
                {Column1: '40x20', Column2: '1,2', Column3: '6000'},
                {Column1: '40x20', Column2: '1,4', Column3: '6000'},
                {Column1: '40x20', Column2: '1,8', Column3: '6000'},
                {Column1: '40x20', Column2: '2,0', Column3: '6000'},
                {Column1: '40x25', Column2: '1,2', Column3: '6000'},
                {Column1: '40x25', Column2: '1,4', Column3: '6000'},
                {Column1: '40x25', Column2: '1,8', Column3: '6000'},
                {Column1: '40x25', Column2: '2,0', Column3: '6000'},
                {Column1: '45x25', Column2: '1,2', Column3: '6000'},
                {Column1: '45x25', Column2: '1,4', Column3: '6000'},
                {Column1: '45x25', Column2: '1,8', Column3: '6000'},
                {Column1: '45x25', Column2: '2,0', Column3: '6000'},
                {Column1: '40x30', Column2: '1,2', Column3: '6000'},
                {Column1: '40x30', Column2: '1,4', Column3: '6000'},
                {Column1: '40x30', Column2: '1,8', Column3: '6000'},
                {Column1: '40x30', Column2: '2,0', Column3: '6000'},
                {Column1: '50x30', Column2: '1,2', Column3: '6000'},
                {Column1: '50x30', Column2: '1,4', Column3: '6000'},
                {Column1: '50x30', Column2: '1,8', Column3: '6000'},
                {Column1: '50x30', Column2: '2,0', Column3: '6000'},
                {Column1: '60х20', Column2: '1,2', Column3: '6000'},
                {Column1: '60х20', Column2: '1,4', Column3: '6000'},
                {Column1: '60х20', Column2: '1,8', Column3: '6000'},
                {Column1: '60х20', Column2: '2,0', Column3: '6000'},

            ],
        }
    ],
    table: {
        1: {},
        2: {},
        3: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
    }
}
