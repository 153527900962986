import React from "react";
import {withRouter} from "react-router-dom";
import {data} from './DropMenu'

const BlackSteel = (props) => {
    const handleOnTitleClick = (e) => {
        const link = e.currentTarget.dataset.link;
        props.history.push(`../${link}`);
    };
    return <div style={{marginLeft: 30, display: 'flex', width: 820, height: 100, flexWrap: 'wrap'}}>
        {data.black.map((d, index) => {
            return <div key={d.link} data-link={d.link} className={"wrapper"} style={{marginLeft:20}} onClick={handleOnTitleClick}>
                <div data-link={d.link}>
                    <p data-link={d.link}>{d.title}</p>
                    <img src={d.img} data-link={d.link}/>
                </div>
            </div>
        })}
    </div>

};

export default withRouter(BlackSteel);
