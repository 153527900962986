import React from "react";
import {ListWrapper} from "../ui/Catalog";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {beam} from '../repository/index'
import {translate} from "react-i18next";
import DetailsProduct from "../ui/Catalog/DetailsProducts";

const BeamController = (props) => {
    return <div>
        {!props.match.params.id
            ? <ListWrapper {...props} url={'beam'}/>
            : <DetailsProduct products={beam} id={props.match.params.id}/>}
    </div>
};

const mapState = (state) => {
   return {
       data: state.catalogPage.beam
   }
};

export default withRouter(connect(mapState, null)(BeamController))
