import React from 'react';
import {DropMenu} from "./index";
import {translate} from "react-i18next";


const CatalogWrapper = (props) => {
    return <div className={"menuWrapper"}>
        <div className={"menutitle"}>{props.t("mainMenuTitle")}</div>
        <DropMenu />

    </div>
};

export default translate("common")(CatalogWrapper);
