import React from "react";
import { ListWrapper} from "../ui/Catalog";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {noCorrosionSteel} from "../repository/no-corrosion-steel";
import DetailsProduct from "../ui/Catalog/DetailsProducts";


const NoCorrosionSteelController = (props) => {
    return <div>
        {!props.match.params.id
            ? <ListWrapper {...props} url={'no-corrosion-steel'}/>
            : <DetailsProduct products={noCorrosionSteel} id={props.match.params.id}/>}
    </div>
};

const mapState = (state) => {
    return {
        data: state.catalogPage.noCorrosionSteel
    }
};

export default withRouter(connect(mapState, null)(NoCorrosionSteelController));
