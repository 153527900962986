export const advantages = [
    {
        title: 'ЦЕНА',
        text: 'Мы убеждены в том, что интересы каждого нашего Клиента являются приоритетом для любого сотрудника компании - это проявляется в том числе и в гибкой ценовой политике.',
        icon: 'icon-new-app',
        titleEn: 'ЦЕНА',
        textEn: 'Мы убеждены в том, что интересы каждого нашего Клиента являются приоритетом для любого сотрудника компании - это проявляется в том числе и в гибкой ценовой политике.'
    },
    {
        title: 'КАЧЕСТВО',
        text: 'Мы стремимся всегда обеспечивать наших клиентов продукцией надлежащего качества и ставим во главу угла требования наших Клиентов.',
        icon: 'icon-amend',
        titleEn: 'КАЧЕСТВО',
        textEn: 'Мы стремимся всегда обеспечивать наших клиентов продукцией надлежащего качества и ставим во главу угла требования наших Клиентов.'
    },
    {
        title: 'ГИБКОСТЬ',
        text: 'Мы сотрудничаем с ведущими европейскими производителями, осуществляя прямые поставки со складов в Европе можем предложить широкий сортамент металлопроката.',
        icon: 'icon-license',
        titleEn: 'ГИБКОСТЬ',
        textEn: 'Мы сотрудничаем с ведущими европейскими производителями, осуществляя прямые поставки со складов в Европе можем предложить широкий сортамент металлопроката.'
    },
    {
        title: 'СКОРОСТЬ',
        text: 'Мы ответственно заявляем, что способны оперативнее других компаний обеспечивать наших Клиентов необходимой продукцией, за счет системного подхода.',
        icon: 'icon-speed',
        titleEn: 'СКОРОСТЬ',
        textEn: 'Мы ответственно заявляем, что способны оперативнее других компаний обеспечивать наших Клиентов необходимой продукцией, за счет системного подхода.'
    },
]
