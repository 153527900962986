import React, {Fragment} from 'react'
import {translate} from "react-i18next";
import '../../resources/less/styles.less'
import {compose} from "redux";
import {NavLink} from "react-router-dom";
import NavbarContainer from '../Navbar/Navbar'

const Header = (props) => {
    const {t, i18n} = props;
    return <Fragment>
        <div className={'topWrapper'}>
            <div className={'top'}>
                <div className={'top-lng'}>
                    {i18n.language === 'en' ?
                        <button onClick={() => i18n.changeLanguage('ru')}
                                className="header-actions__lang">{t("code.ru")} </button>
                        : <button onClick={() => i18n.changeLanguage('en')}
                                  className="header-actions__lang">{t("code.en")}</button>
                    }
                </div>
                <div className={'top-contacts'}>
                    <span>+7 (7122) 76-64-96  /  </span>
                    <p>+7 (705) 791-60-11 / +7 (778) 298-27-08</p>
                </div>

            </div>
        </div>
        <header className={'header'}>
            <div className="header-wrap">
                <NavLink to={"/"} className="logo">
                    {/*<span>Beta 1.0</span>*/}
                </NavLink>
                <NavbarContainer i18n={i18n}/>

            </div>

            {/*    /!*<div className="header-actions">*!/*/}


            {/*</div>*/}
        </header>
    </Fragment>
};

export default compose(
    translate("common"),
)(Header);
