import React from 'react';
import MaterialTable from "material-table";
import {translate} from "react-i18next";

let TableDetails =(props) => {
    const {t, object} = props;

    const columns = object.columnsHeader.map(c => {
        return {title: t(c.title), field: c.field}
    });


    return <div>
        {object.table.map((td, index) => {
            return <div key={index}>
                <MaterialTable
                title={td[t("detailsProduct.tableTitle")]}
                columns={columns}
                data={object[td.data]}
                onRowClick={() => console.log()}
                options={{
                    search: false,
                    emptyRowsWhenPaging: false,
                    padding: 'dense',
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 30],
                    headerStyle: {
                        minWidth: 130,
                        textAlign: 'center'
                    }
                }}
            />
            </div>
        })}

    </div>
};
TableDetails = translate("common")(TableDetails);

export {TableDetails}
