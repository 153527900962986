import React from "react";
import { ListWrapper} from "../ui/Catalog";
import {connect} from "react-redux";
import {sheet} from "../repository/sheet-steel";
import {withRouter} from "react-router-dom";
import DetailsProduct from "../ui/Catalog/DetailsProducts";


const SheetSteelController = (props) => {
    return <div>
        {!props.match.params.id
            ? <ListWrapper {...props} url={'sheet-steel'}/>
            : <DetailsProduct products={sheet} id={props.match.params.id}/>}
    </div>
};

const mapState = (state) => {
    return {
        data: state.catalogPage.sheet
    }
};

export default withRouter(connect(mapState, null)(SheetSteelController));
