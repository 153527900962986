import balka1 from '../assets/img/catalog/balkaPGP.jpg'
import balka11 from '../assets/img/catalog/heb.jpg'
import balka2 from '../assets/img/catalog/balka2.png'
import balka22 from '../assets/img/catalog/balKa_ipn17.jpg'
import balka3 from '../assets/img/catalog/balka3.png'
import balka33 from '../assets/img/catalog/heb (1).jpg'

export const beam = {
    list: [
        {
            id: 1,
            img: balka1,
            image: balka11,


            title: 'Балка двутавровая по СТО АСЧМ 20-93',
            description: 'Балка двутавровая СТО АСЧМ 20-93 – Двутавры горячекатаные с параллельными гранями полок',
            columnsHeader: [
                {title: 'beamStoAschm2093.Column1', field: 'Column1'},
                {title: 'beamStoAschm2093.Column2', field: 'Column2'},
                {title: 'beamStoAschm2093.Column3', field: 'Column3'},
                {title: 'beamStoAschm2093.Column4', field: 'Column4'},
                {title: 'beamStoAschm2093.Column5', field: 'Column5'},
                {title: 'beamStoAschm2093.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Двутавры горячекатаные с параллельными гранями полок',
            listDetails: ['Б - нормальные с параллельными гранями полок;', 'Ш - широкополочные с параллельными гранями полок;', 'К - колонные с параллельными гранями полок.'],
            steel: 'Марки стали: Ст3кп, Ст3пс, Ст3сп, С235; С245; С255; С275; С285; С345.',
            type: ['ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой обыкновенного качества',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.'
            ],

            titleEn: 'Балка двутавровая по СТО АСЧМ 20-93',
            descriptionEn: 'Балка двутавровая СТО АСЧМ 20-93 – Двутавры горячекатаные с параллельными гранями полок',
            descriptionDetailsEn: 'Двутавры горячекатаные с параллельными гранями полок',
            listDetailsEn: ['Б - нормальные с параллельными гранями полок;', 'Ш - широкополочные с параллельными гранями полок;', 'К - колонные с параллельными гранями полок.'],
            steelEn: 'Марки стали: Ст3кп, Ст3пс, Ст3сп, С235; С245; С255; С275; С285; С345.',
            typeEn: ['ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой обыкновенного качества',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.'
            ],
            table: [
                {title: 'Нормальные двутавры', titleEn: 'Нормальные двутавры', data: 'data1'},
                {title: 'Широкополочные двутавры', titleEn: 'Широкополочные двутавры', data: 'data2'},
                {title: 'Колонные двутавры', titleEn: 'Колонные двутавры', data: 'data3'},
            ],


            data1: [
                {Column1: '10Б1', Column2: '8,1', Column3: 100, Column4: 55, Column5: '4,1', Column6: '5,7'},
                {Column1: '12Б1', Column2: '8,7', Column3: '117,6', Column4: 64, Column5: '3,8', Column6: '5,1'},
                {Column1: '12Б2', Column2: '10,4', Column3: 120, Column4: 64, Column5: '4,4', Column6: '6,3'},
                {Column1: '14Б1', Column2: '10,5', Column3: '137,4', Column4: 73, Column5: '3,8', Column6: '5,6'},
                {Column1: '14Б2', Column2: '12,9', Column3: 140, Column4: 73, Column5: '4,7', Column6: '6,9'},
                {Column1: '16Б1', Column2: '12,7', Column3: 157, Column4: 82, Column5: '4,0', Column6: '5,9'},
                {Column1: '16Б2', Column2: '15,8', Column3: 160, Column4: 82, Column5: '5,0', Column6: '7,4'},
                {Column1: '18Б1', Column2: '15,4', Column3: 177, Column4: 91, Column5: '4,3', Column6: '6,5'},
                {Column1: '18Б2', Column2: '18,8', Column3: 180, Column4: 91, Column5: '5,3', Column6: '8,0'},
                {Column1: '20Б1', Column2: '21,3', Column3: 200, Column4: 100, Column5: '5,5', Column6: '8,0'},
                {Column1: '25Б1', Column2: '25,7', Column3: 248, Column4: 124, Column5: '5,0', Column6: '8,0'},
                {Column1: '25Б2', Column2: '29,6', Column3: 250, Column4: 125, Column5: '6,0', Column6: '9,0'},
                {Column1: '30Б1', Column2: '32,0', Column3: 298, Column4: 149, Column5: '5,5', Column6: '8,0'},
                {Column1: '30Б2', Column2: '36,7', Column3: 300, Column4: 150, Column5: '6,5', Column6: '9,0'},
                {Column1: '35Б1', Column2: '41,4', Column3: 346, Column4: 174, Column5: '6,0', Column6: '9,0'},
                {Column1: '35Б2', Column2: '49,6', Column3: 350, Column4: 175, Column5: '7,0', Column6: '11,0'},
                {Column1: '40Б1', Column2: '56,6', Column3: 396, Column4: 199, Column5: '8,0', Column6: '12,0'},
                {Column1: '40Б2', Column2: '66,0', Column3: 400, Column4: 200, Column5: '8,0', Column6: '13,0'},
                {Column1: '45Б1', Column2: '66,2', Column3: 446, Column4: 199, Column5: '8,0', Column6: '12,0'},
                {Column1: '45Б2', Column2: '76,0', Column3: 450, Column4: 200, Column5: '9,0', Column6: '14,0'},
                {Column1: '50Б1', Column2: '72,5', Column3: 492, Column4: 199, Column5: '8,8', Column6: '12,0'},
                {Column1: '50Б2', Column2: '79,5', Column3: 496, Column4: 199, Column5: '9,0', Column6: '14,0'},
                {Column1: '50Б3', Column2: '89,7', Column3: 500, Column4: 200, Column5: '10,0', Column6: '16,0'},

            ],
            data2: [
                {Column1: '20Ш1', Column2: '30,6', Column3: 194, Column4: 150, Column5: '6,0', Column6: '9,0'},
                {Column1: '25Ш1', Column2: '44,1', Column3: 244, Column4: 175, Column5: '7,0', Column6: '11,0'},
                {Column1: '30Ш1', Column2: '56,8', Column3: 294, Column4: 200, Column5: '11,0', Column6: '16,0'},
                {Column1: '30Ш2', Column2: '68,6', Column3: 300, Column4: 201, Column5: '9,0', Column6: '15,0'},
                {Column1: '35Ш1', Column2: '65,3', Column3: 314, Column4: 249, Column5: '8,0', Column6: '11,0'},
                {Column1: '35Ш2', Column2: '79,7', Column3: 340, Column4: 250, Column5: '9,0', Column6: '14,0'},
                {Column1: '40Ш1', Column2: '88,6', Column3: 383, Column4: 299, Column5: '9,5', Column6: '12,5'},
                {Column1: '40Ш2', Column2: '106,7', Column3: 390, Column4: 300, Column5: '10,0', Column6: '16,0'},
                {Column1: '45Ш1', Column2: '123,5', Column3: 440, Column4: 300, Column5: '11,0', Column6: '18,0'},
                {Column1: '50Ш1', Column2: '114,2', Column3: 482, Column4: 300, Column5: '11,0', Column6: '15,0'},
                {Column1: '50Ш2', Column2: '138,4', Column3: 487, Column4: 300, Column5: '14,5', Column6: '17,5'},
                {Column1: '50Ш3', Column2: '156,1', Column3: 493, Column4: 300, Column5: '15,5', Column6: '20,5'},
                {Column1: '50Ш4', Column2: '173,8', Column3: 499, Column4: 300, Column5: '16,5', Column6: '23,5'},
            ],
            data3: [
                {Column1: '20К1', Column2: '41,4', Column3: 196, Column4: 199, Column5: '6,5', Column6: '10,0'},
                {Column1: '20К2', Column2: '49,9', Column3: 200, Column4: 200, Column5: '8,0', Column6: '12,0'},
                {Column1: '25К1', Column2: '62,6', Column3: 246, Column4: 249, Column5: '8,0', Column6: '12,0'},
                {Column1: '25К2', Column2: '72,4', Column3: 250, Column4: 250, Column5: '9,0', Column6: '14,0'},
                {Column1: '25К3', Column2: '80,2', Column3: 253, Column4: 251, Column5: '10,0', Column6: '15,5'},
                {Column1: '30К1', Column2: '87,0', Column3: 298, Column4: 299, Column5: '9,0', Column6: '14,0'},
                {Column1: '30К2', Column2: '94,0', Column3: 300, Column4: 300, Column5: '10,0', Column6: '15,0'},
                {Column1: '30К3', Column2: '105,8', Column3: 300, Column4: 305, Column5: '15,0', Column6: '15,0'},
                {Column1: '30К4', Column2: '105,8', Column3: 304, Column4: 301, Column5: '11,0', Column6: '17,0'},
                {Column1: '35К1', Column2: '109,1', Column3: 342, Column4: 348, Column5: '10,0', Column6: '15,0'},
                {Column1: '35К2', Column2: '136,5', Column3: 350, Column4: 350, Column5: '12,0', Column6: '19,0'},
                {Column1: '40К1', Column2: '146,6', Column3: 394, Column4: 398, Column5: '11,0', Column6: '18,0'},
                {Column1: '40К2', Column2: '171,7', Column3: 400, Column4: 400, Column5: '13,0', Column6: '21,0'},
                {Column1: '40К3', Column2: '200,1', Column3: 406, Column4: 403, Column5: '16,0', Column6: '24,0'},
                {Column1: '40К4', Column2: '231,9', Column3: 414, Column4: 405, Column5: '18,0', Column6: '28,0'},
                {Column1: '40К5', Column2: '290,8', Column3: 429, Column4: 400, Column5: '23,0', Column6: '35,5'},
            ]
        },
        {
            id: 2,
            img: balka2,
            image: balka22,

            title: 'Балка IPN',
            description: 'Балка IPN – двутавровые балки с уклоном внутренних граней, средняя высота от 80 до 600 мм',
            columnsHeader: [
                {title: 'beamIPN.Column1', field: 'Column1'},
                {title: 'beamIPN.Column2', field: 'Column2'},
                {title: 'beamIPN.Column3', field: 'Column3'},
                {title: 'beamIPN.Column4', field: 'Column4'},
                {title: 'beamIPN.Column5', field: 'Column5'},
                {title: 'beamIPN.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Двутавровая балка с уклоном внутренних граней, средняя высота от 80 до 600 мм.',
            listDetails: [''],
            steel: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            type: ['DIN 1025',
                'Euronorm 19-57 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],


            titleEn: 'Балка IPN',
            descriptionEn: 'Балка IPN – двутавровые балки с уклоном внутренних граней, средняя высота от 80 до 600 мм',
            descriptionDetailsEn: 'Двутавровая балка с уклоном внутренних граней, средняя высота от 80 до 600 мм.',
            listDetailsEn: [''],
            steelEn: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            typeEn: ['DIN 1025',
                'Euronorm 19-57 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],
            table: [
                {title: 'Балка IPN', titleEn: 'Балка IPN', data: 'data1'},
            ],


            data1: [
                {Column1: 'IPN 80', Column2: '5,94', Column3: 80, Column4: 42, Column5: '3,9', Column6: '5,9'},
                {Column1: 'IPN 100', Column2: '8,34', Column3: 100, Column4: 50, Column5: '4,5', Column6: '6,8'},
                {Column1: 'IPN 120', Column2: '11,1', Column3: 120, Column4: 58, Column5: '5,1', Column6: '7,7'},
                {Column1: 'IPN 140', Column2: '14,3', Column3: 140, Column4: 66, Column5: '5,7', Column6: '8,6'},
                {Column1: 'IPN 160', Column2: '17,9', Column3: 160, Column4: 74, Column5: '6,3', Column6: '9,5'},
                {Column1: 'IPN 180', Column2: '21,9', Column3: 180, Column4: 82, Column5: '6,9', Column6: '10,4'},
                {Column1: 'IPN 200', Column2: '26,2', Column3: 200, Column4: 90, Column5: '7,5', Column6: '11,3'},
                {Column1: 'IPN 220', Column2: '31,1', Column3: 220, Column4: 98, Column5: '8,1', Column6: '12,2'},
                {Column1: 'IPN 240', Column2: '36,2', Column3: 240, Column4: 106, Column5: '8,7', Column6: '13,1'},
                {Column1: 'IPN 260', Column2: '41,9', Column3: 260, Column4: 113, Column5: '9,4', Column6: '14,1'},
                {Column1: 'IPN 280', Column2: '47,9', Column3: 280, Column4: 119, Column5: '10,1', Column6: '15,2'},
                {Column1: 'IPN 300', Column2: '54,2', Column3: 300, Column4: 125, Column5: '10,8', Column6: '16,2'},
                {Column1: 'IPN 320', Column2: '61,0', Column3: 320, Column4: 131, Column5: '11,5', Column6: '17,3'},
                {Column1: 'IPN 340', Column2: '68,0', Column3: 340, Column4: 137, Column5: '12,2', Column6: '18,3'},
                {Column1: 'IPN 360', Column2: '76,1', Column3: 360, Column4: 143, Column5: '13,0', Column6: '19,5'},
                {Column1: 'IPN 380', Column2: '84,0', Column3: 380, Column4: 149, Column5: '13,7', Column6: '20,5'},
                {Column1: 'IPN 400', Column2: '92,4', Column3: 400, Column4: 155, Column5: '14,4', Column6: '21,6'},
                {Column1: 'IPN 450', Column2: '115,0', Column3: 450, Column4: 170, Column5: '16,2', Column6: '24,3'},
                {Column1: 'IPN 500', Column2: '141,0', Column3: 500, Column4: 185, Column5: '18,0', Column6: '27,0'},
                {Column1: 'IPN 550', Column2: '166,0', Column3: 550, Column4: 200, Column5: '19,0', Column6: '30,0'},
                {Column1: 'IPN 600', Column2: '199,5', Column3: 600, Column4: 215, Column5: '21,6', Column6: '32,4'},

            ],
        },
        {
            id: 3,
            img: balka3,
            image: balka33,


            title: 'Балка IPE',
            description: 'Балка IPE – Двутавровая балка с параллельными гранями, средняя высота от 80 до 600 мм',
            columnsHeader: [
                {title: 'beamIPE.Column1', field: 'Column1'},
                {title: 'beamIPE.Column2', field: 'Column2'},
                {title: 'beamIPE.Column3', field: 'Column3'},
                {title: 'beamIPE.Column4', field: 'Column4'},
                {title: 'beamIPE.Column5', field: 'Column5'},
                {title: 'beamIPE.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Двутавровая балка с уклоном внутренних граней, средняя высота от 80 до 600 мм.',
            listDetails: [''],
            steel: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            type: ['DIN 1025',
                'Euronorm 19-57 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],


            titleEn: 'Балка IPE',
            descriptionEn: 'Балка IPE – Двутавровая балка с параллельными гранями, средняя высота от 80 до 600 мм',
            descriptionDetailsEn: 'Двутавровая балка с уклоном внутренних граней, средняя высота от 80 до 600 мм.',
            listDetailsEn: [''],
            steelEn: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            typeEn: ['DIN 1025',
                'Euronorm 19-57 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],
            table: [
                {title: 'Балка IPE', titleEn: 'Балка IPE', data: 'data1'},
            ],


            data1: [
                {Column1: 'IPN 80', Column2: '6', Column3: 80, Column4: 46, Column5: '3,8', Column6: '5,2'},
                {Column1: 'IPN 100', Column2: '8,1', Column3: 100, Column4: 55, Column5: '4,1', Column6: '5,7'},
                {Column1: 'IPN 120', Column2: '10,4', Column3: 120, Column4: 64, Column5: '4,4', Column6: '6,3'},
                {Column1: 'IPN 140', Column2: '12,9', Column3: 140, Column4: 73, Column5: '4,7', Column6: '6,9'},
                {Column1: 'IPN 160', Column2: '15,8', Column3: 160, Column4: 82, Column5: '5,0', Column6: '7,4'},
                {Column1: 'IPN 180', Column2: '18,8', Column3: 180, Column4: 91, Column5: '5,3', Column6: '8,0'},
                {Column1: 'IPN 200', Column2: '22,4', Column3: 200, Column4: 100, Column5: '5,6', Column6: '8,5'},
                {Column1: 'IPN 220', Column2: '26,2', Column3: 220, Column4: 110, Column5: '5,9', Column6: '9,2'},
                {Column1: 'IPN 240', Column2: '30,7', Column3: 240, Column4: 120, Column5: '6,2', Column6: '9,8'},
                {Column1: 'IPN 270', Column2: '36,1', Column3: 270, Column4: 135, Column5: '6,6', Column6: '10,2'},
                {Column1: 'IPN 300', Column2: '42,2', Column3: 300, Column4: 150, Column5: '7,1', Column6: '10,7'},
                {Column1: 'IPN 330', Column2: '49,1', Column3: 330, Column4: 160, Column5: '7,5', Column6: '11,5'},
                {Column1: 'IPN 360', Column2: '57,1', Column3: 360, Column4: 170, Column5: '8,0', Column6: '12,7'},
                {Column1: 'IPN 400', Column2: '66,3', Column3: 400, Column4: 180, Column5: '8,6', Column6: '13,5'},
                {Column1: 'IPN 450', Column2: '77,6', Column3: 450, Column4: 190, Column5: '9,4', Column6: '14,6'},
                {Column1: 'IPN 500', Column2: '90,7', Column3: 500, Column4: 200, Column5: '10,2', Column6: '16,0'},
                {Column1: 'IPN 550', Column2: '106,0', Column3: 550, Column4: 210, Column5: '11,1', Column6: '17,2'},
                {Column1: 'IPN 600', Column2: '122,0', Column3: 600, Column4: 220, Column5: '12,0', Column6: '19,0'},
            ],

        },
        {
            id: 4,
            img: balka3,
            image: balka33,


            title: 'Балка HEB',
            description: 'Балка HEB – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            columnsHeader: [
                {title: 'beamHEB.Column1', field: 'Column1'},
                {title: 'beamHEB.Column2', field: 'Column2'},
                {title: 'beamHEB.Column3', field: 'Column3'},
                {title: 'beamHEB.Column4', field: 'Column4'},
                {title: 'beamHEB.Column5', field: 'Column5'},
                {title: 'beamHEB.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Балка HEB – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            listDetails: [''],
            steel: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            type: ['DIN 1025',
                'Euronorm 53-62 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],


            titleEn: 'Балка HEB',
            descriptionEn: 'Балка HEB – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            descriptionDetailsEn: 'Балка HEB – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            listDetailsEn: [''],
            steelEn: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            typeEn:['DIN 1025',
                'Euronorm 53-62 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],
            table: [
                {title: 'Балка IPE', titleEn: 'Балка IPE', data: 'data1'},
            ],


            data1: [
                {Column1: 'HEB 100', Column2: '20,4', Column3: 100, Column4: 100, Column5: '6,0', Column6: '10'},
                {Column1: 'HEB 120', Column2: '26,7', Column3: 120, Column4: 120, Column5: '6,5', Column6: '11,0'},
                {Column1: 'HEB 140', Column2: '33,7', Column3: 140, Column4: 140, Column5: '7,0', Column6: '12,0'},
                {Column1: 'HEB 160', Column2: '42,6', Column3: 160, Column4: 160, Column5: '8,0', Column6: '13,0'},
                {Column1: 'HEB 180', Column2: '51,2', Column3: 180, Column4: 180, Column5: '8,5', Column6: '14,0'},
                {Column1: 'HEB 200', Column2: '61,3', Column3: 200, Column4: 200, Column5: '9,0', Column6: '15,0'},
                {Column1: 'HEB 220', Column2: '71,5', Column3: 220, Column4: 220, Column5: '9,5', Column6: '16,0'},
                {Column1: 'HEB 240', Column2: '83,2', Column3: 240, Column4: 240, Column5: '10,0', Column6: '17,0'},
                {Column1: 'HEB 260', Column2: '93', Column3: 260, Column4: 260, Column5: '10,0', Column6: '17,5'},
                {Column1: 'HEB 280', Column2: '103', Column3: 280, Column4: 280, Column5: '10,5', Column6: '18,0'},
                {Column1: 'HEB 300', Column2: '117', Column3: 300, Column4: 300, Column5: '11,0', Column6: '19,0'},
                {Column1: 'HEB 320', Column2: '127', Column3: 320, Column4: 300, Column5: '11,5', Column6: '20,5'},
                {Column1: 'HEB 340', Column2: '134', Column3: 340, Column4: 300, Column5: '12,0', Column6: '21,5'},
                {Column1: 'HEB 360', Column2: '142', Column3: 360, Column4: 300, Column5: '12,5', Column6: '22,5'},
                {Column1: 'HEB 400', Column2: '155', Column3: 400, Column4: 300, Column5: '13,5', Column6: '24,0'},
                {Column1: 'HEB 450', Column2: '171', Column3: 450, Column4: 300, Column5: '14,0', Column6: '26,0'},
                {Column1: 'HEB 500', Column2: '187', Column3: 500, Column4: 300, Column5: '14,5', Column6: '28,0'},
                {Column1: 'HEB 550', Column2: '199', Column3: 550, Column4: 300, Column5: '15,0', Column6: '29,0'},
                {Column1: 'HEB 600', Column2: '212', Column3: 600, Column4: 300, Column5: '15,5', Column6: '30,0'},
                {Column1: 'HEB 650', Column2: '225', Column3: 650, Column4: 300, Column5: '16,0', Column6: '31,0'},
                {Column1: 'HEB 700', Column2: '241', Column3: 700, Column4: 300, Column5: '17,0', Column6: '32,0'},
            ],

        },
        {
            id: 5,
            img: balka3,
            image: balka33,


            title: 'Балка HEA',
            description: 'Балка HEA – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            columnsHeader: [
                {title: 'beamHEB.Column1', field: 'Column1'},
                {title: 'beamHEB.Column2', field: 'Column2'},
                {title: 'beamHEB.Column3', field: 'Column3'},
                {title: 'beamHEB.Column4', field: 'Column4'},
                {title: 'beamHEB.Column5', field: 'Column5'},
                {title: 'beamHEB.Column6', field: 'Column6'},
            ],
            descriptionDetails: 'Балка HEA – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            listDetails: [''],
            steel: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            type: ['DIN 1025',
                'Euronorm 53-62 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],


            titleEn: 'Балка HEA',
            descriptionEn: 'Балка HEA – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            descriptionDetailsEn: 'Балка HEA – широкополочная двутавровая балка с параллельными гранями, средняя высота от 100 до 700 мм.',
            listDetailsEn: [''],
            steelEn: 'Марки стали: S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355ML,S355NL, S355J0, S355J2.',
            typeEn: ['DIN 1025',
                'Euronorm 53-62 (Размеры)',
                'EN 10034: 1993 (Предельные отклонения)',
                'EN 10163-3, C (Поверхность)'
            ],
            table: [
                {title: 'Балка HEA', titleEn: 'Балка HEA', data: 'data1'},
            ],


            data1: [
                {Column1: 'HEA 100', Column2: '16,7', Column3: 96, Column4: 100, Column5: '5,0', Column6: '8'},
                {Column1: 'HEA 120', Column2: '19,9', Column3: 114, Column4: 120, Column5: '5,0', Column6: '8,0'},
                {Column1: 'HEA 140', Column2: '24,7', Column3: 133, Column4: 140, Column5: '5,5', Column6: '8,5'},
                {Column1: 'HEA 160', Column2: '30,4', Column3: 152, Column4: 160, Column5: '6,0', Column6: '9,0'},
                {Column1: 'HEA 180', Column2: '35,5', Column3: 171, Column4: 180, Column5: '6,0', Column6: '9,5'},
                {Column1: 'HEA 200', Column2: '42,3', Column3: 190, Column4: 200, Column5: '6,5', Column6: '10,0'},
                {Column1: 'HEA 220', Column2: '50,5', Column3: 210, Column4: 220, Column5: '7,0', Column6: '11,0'},
                {Column1: 'HEA 240', Column2: '60,3', Column3: 230, Column4: 240, Column5: '7,5', Column6: '12,0'},
                {Column1: 'HEA 260', Column2: '68,2', Column3: 250, Column4: 260, Column5: '7,5', Column6: '12,5'},
                {Column1: 'HEA 280', Column2: '76,4', Column3: 270, Column4: 280, Column5: '8,0', Column6: '13,0'},
                {Column1: 'HEA 300', Column2: '88,3', Column3: 290, Column4: 300, Column5: '8,5', Column6: '14,0'},
                {Column1: 'HEA 320', Column2: '97,6', Column3: 310, Column4: 300, Column5: '9,0', Column6: '15,5'},
                {Column1: 'HEA 340', Column2: '105', Column3: 330, Column4: 300, Column5: '9,5', Column6: '16,5'},
                {Column1: 'HEA 360', Column2: '112', Column3: 350, Column4: 300, Column5: '10,0', Column6: '17,5'},
                {Column1: 'HEA 400', Column2: '125', Column3: 390, Column4: 300, Column5: '11,0', Column6: '19,0'},
                {Column1: 'HEA 450', Column2: '140', Column3: 440, Column4: 300, Column5: '11,5', Column6: '21,0'},
                {Column1: 'HEA 500', Column2: '155', Column3: 490, Column4: 300, Column5: '12,0', Column6: '23,0'},
                {Column1: 'HEA 550', Column2: '166', Column3: 540, Column4: 300, Column5: '12,5', Column6: '24,0'},
                {Column1: 'HEA 600', Column2: '178', Column3: 590, Column4: 300, Column5: '13,0', Column6: '25,0'},
                {Column1: 'HEA 650', Column2: '190', Column3: 640, Column4: 300, Column5: '13,5', Column6: '26,0'},
                {Column1: 'HEA 700', Column2: '204', Column3: 690, Column4: 300, Column5: '14,5', Column6: '27,0'},
            ],

        },
    ],
    table: {
        1: {},
        2: {},
        3: {},
        4: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
        4: {},
    },

};
