import ugolok from '../assets/img/catalog/ugolok.jpg'
import ugolok_nerav from '../assets/img/catalog/5555.png'
import ugolok_rav from '../assets/img/catalog/acab4df9ed811d23d05318f451977986.jpg'
import ugolok_rav1 from '../assets/img/catalog/6666.jpg'

export const corner = {
    list: [
        {
            id: 1,
            img: ugolok,
            image: ugolok_nerav,

            title: 'Уголок неравнополочный',
            description: '',
            columnsHeader: [
                {title: 'corner.Column1', field: 'Column1'},
                {title: 'corner.Column2', field: 'Column2'},
                {title: 'corner.Column3', field: 'Column3'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст3пс, Ст3сп, S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355J0, S355J2',
            type: ['ГОСТ 8510-78 Уголки стальные горячекатаные неравнополочные.',
                'DIN 1028-94 Сталь угловая горячекатаная неравнобокая.',
                'ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой ',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Уголки поставлются длиной 11,7 и 12 м.'
            ],


            titleEn: 'Уголок неравнополочный',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст3пс, Ст3сп, S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355J0, S355J2',
            typeEn:['ГОСТ 8510-78 Уголки стальные горячекатаные неравнополочные.',
                'DIN 1028-94 Сталь угловая горячекатаная неравнобокая.',
                'ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой ',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Уголки поставлются длиной 11,7 и 12 м.'
            ],
            table: [
                {title: 'Уголок неравнополочный', titleEn: 'Уголок неравнополочный', data: 'data1'},
            ],


            data1: [
                {Column1: '40х25', Column2: '4,0', Column3: '1,93'},
                {Column1: '60x30', Column2: '5,0', Column3: '3,37'},
                {Column1: '63x40', Column2: '5,0', Column3: '3,91'},
                {Column1: '63x40', Column2: '6,0', Column3: '4,63'},
                {Column1: '75x50', Column2: '5,0', Column3: '4,79'},
                {Column1: '75x50', Column2: '6,0', Column3: '5,65'},
                {Column1: '80x60', Column2: '6,0', Column3: '6,37'},
                {Column1: '80x60', Column2: '8,0', Column3: '8,34'},
                {Column1: '100x50', Column2: '6,0', Column3: '6,85'},
                {Column1: '100x63', Column2: '6,0', Column3: '7,53'},
                {Column1: '100x63', Column2: '8,0', Column3: '9,87'},
                {Column1: '125x80', Column2: '8,0', Column3: '12,58'},
                {Column1: '125x80', Column2: '10,0', Column3: '15,47'},
                {Column1: '140x90', Column2: '10,0', Column3: '17,46'},
                {Column1: '150x75', Column2: '10,0', Column3: '17,00'},
                {Column1: '150x75', Column2: '12,0', Column3: '20,20'},
                {Column1: '150x90', Column2: '10,0', Column3: '18,20'},
                {Column1: '150x90', Column2: '12,0', Column3: '21,60'},
                {Column1: '150x90', Column2: '15,0', Column3: '26,60'},
                {Column1: '160x100', Column2: '10,0', Column3: '19,85'},
                {Column1: '200x100', Column2: '10,0', Column3: '23,00'},
                {Column1: '200x100', Column2: '12,0', Column3: '27,30'},
                {Column1: '200x125', Column2: '12,0', Column3: '29,74'},
                {Column1: '200x150', Column2: '12,0', Column3: '32,00'},
                {Column1: '200x150', Column2: '15,0', Column3: '39,60'},
                {Column1: '200x150', Column2: '18,0', Column3: '47,10'},

            ],
        },
        {
            id: 2,
            img: ugolok_rav,
            image: ugolok_rav1,

            title: 'Уголок равнополочный',
            description: '',
            columnsHeader: [
                {title: 'corner.Column1', field: 'Column1'},
                {title: 'corner.Column2', field: 'Column2'},
                {title: 'corner.Column3', field: 'Column3'},
            ],
            descriptionDetails: '',
            listDetails: [''],
            steel: 'Марки стали: Ст3пс, Ст3сп, S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355J0, S355J2',
            type: ['ГОСТ 8509-78 Уголки стальные горячекатаные равнополочные.',
                'DIN 1028-94 Сталь угловая горячекатаная равнобокая.',
                'ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой обыкновенного качества',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Уголки поставлются длиной 11,7 и 12 м.'
            ],

            titleEn: 'Уголок равнополочный',
            descriptionEn: '',
            descriptionDetailsEn: '',
            listDetailsEn: [''],
            steelEn: 'Марки стали: Ст3пс, Ст3сп, S235JR, S235J0, S235J2,S275JR, S275J0, S355JR, S355J0, S355J2',
            typeEn:['ГОСТ 8509-78 Уголки стальные горячекатаные равнополочные.',
                'DIN 1028-94 Сталь угловая горячекатаная равнобокая.',
                'ГОСТ 535-88 Прокат сортовой и фасонный из стали углеродистой обыкновенного качества',
                'ГОСТ 19281-2014 Прокат из стали повышенной прочности.',
                'ГОСТ 27772-88 Прокат для строительных стальных конструкций.',
                'Уголки поставлются длиной 11,7 и 12 м.'
            ],
            table: [
                {title: 'Уголок равнополочный', titleEn: 'Уголок равнополочный', data: 'data1'},
            ],

            data1: [
                {Column1: '25x25', Column2: '4,0', Column3: '1,46'},
                {Column1: '32x32', Column2: '4,0', Column3: '1,91'},
                {Column1: '40x40', Column2: '4,0', Column3: '2,42'},
                {Column1: '40x40', Column2: '5,0', Column3: '2,98'},
                {Column1: '45x45', Column2: '4,0', Column3: '2,73'},
                {Column1: '45x45', Column2: '5,0', Column3: '3,37'},
                {Column1: '50x50', Column2: '4,0', Column3: '3,05'},
                {Column1: '50x50', Column2: '5,0', Column3: '3,77'},
                {Column1: '63x63', Column2: '5,0', Column3: '4,81'},
                {Column1: '63x63', Column2: '6,0', Column3: '5,72'},
                {Column1: '70x70', Column2: '6,0', Column3: '6,39'},
                {Column1: '70x70', Column2: '7,0', Column3: '7,39'},
                {Column1: '70x70', Column2: '8,0', Column3: '8,37'},
                {Column1: '70x70', Column2: '10,0', Column3: '10,30'},
                {Column1: '75х75', Column2: '6,0', Column3: '6,89'},
                {Column1: '75х75', Column2: '7,0', Column3: '7,96'},
                {Column1: '75х75', Column2: '8,0', Column3: '9,02'},
                {Column1: '75х75', Column2: '9,0', Column3: '10,07'},
                {Column1: '75х75', Column2: '10,0', Column3: '11,00'},
                {Column1: '80х80', Column2: '6,0', Column3: '7,36'},
                {Column1: '80х80', Column2: '8,0', Column3: '9,65'},
                {Column1: '80х80', Column2: '10,0', Column3: '11,90'},
                {Column1: '80х80', Column2: '12,0', Column3: '14,20'},
                {Column1: '90х90', Column2: '7,0', Column3: '9,64'},
                {Column1: '90х90', Column2: '8,0', Column3: '10,93'},
                {Column1: '90х90', Column2: '9,0', Column3: '12,20'},
                {Column1: '90х90', Column2: '10,0', Column3: '13,40'},
                {Column1: '90х90', Column2: '12,0', Column3: '15,94'},
                {Column1: '100х100', Column2: '7,0', Column3: '10,79'},
                {Column1: '100х100', Column2: '8,0', Column3: '12,25'},
                {Column1: '100х100', Column2: '10,0', Column3: '15,10'},
                {Column1: '100х100', Column2: '12,0', Column3: '17,90'},
                {Column1: '100х100', Column2: '14,0', Column3: '20,63'},
                {Column1: '100х100', Column2: '16,0', Column3: '23,30'},
                {Column1: '110х110', Column2: '8,0', Column3: '13,50'},
                {Column1: '110х110', Column2: '10,0', Column3: '16,69'},
                {Column1: '110х110', Column2: '12,0', Column3: '17,90'},
                {Column1: '110х110', Column2: '14,0', Column3: '22,81'},
                {Column1: '120х120', Column2: '8,0', Column3: '14,70'},
                {Column1: '120х120', Column2: '10,0', Column3: '18,20'},
                {Column1: '120х120', Column2: '12,0', Column3: '21,60'},
                {Column1: '120х120', Column2: '15,0', Column3: '26,60'},
                {Column1: '125х125', Column2: '8,0', Column3: '15,46'},
                {Column1: '125х125', Column2: '9,0', Column3: '17,30'},
                {Column1: '125х125', Column2: '10,0', Column3: '19,10'},
                {Column1: '125х125', Column2: '12,0', Column3: '22,68'},
                {Column1: '140х140', Column2: '9,0', Column3: '19,41'},
                {Column1: '140х140', Column2: '10,0', Column3: '21,45'},
                {Column1: '140х140', Column2: '12,0', Column3: '25,50'},
                {Column1: '140х140', Column2: '14,0', Column3: '29,50'},
                {Column1: '150х150', Column2: '10,0', Column3: '23,00'},
                {Column1: '150х150', Column2: '12,0', Column3: '27,30'},
                {Column1: '150х150', Column2: '14,0', Column3: '31,60'},
                {Column1: '150х150', Column2: '15,0', Column3: '33,80'},
                {Column1: '160х160', Column2: '10,0', Column3: '24,67'},
                {Column1: '160х160', Column2: '12,0', Column3: '29,35'},
                {Column1: '160х160', Column2: '14,0', Column3: '34,20'},
                {Column1: '160х160', Column2: '15,0', Column3: '36,20'},
                {Column1: '180х180', Column2: '11,0', Column3: '30,47'},
                {Column1: '180х180', Column2: '16,0', Column3: '43,50'},
                {Column1: '180х180', Column2: '18,0', Column3: '48,63'},
                {Column1: '180х180', Column2: '20,0', Column3: '53,10'},
                {Column1: '200х200', Column2: '12,0', Column3: '36,97'},
                {Column1: '200х200', Column2: '14,0', Column3: '42,80'},
                {Column1: '200х200', Column2: '16,0', Column3: '48,65'},
                {Column1: '200х200', Column2: '20,0', Column3: '60,08'},
                {Column1: '200х200', Column2: '25,0', Column3: '74,02'},
                {Column1: '200х200', Column2: '28,0', Column3: '81,87'},
                {Column1: '200х200', Column2: '30,0', Column3: '87,56'},
                {Column1: '250х250', Column2: '18,0', Column3: '68,86'},
                {Column1: '250х250', Column2: '20,0', Column3: '76,11'},
                {Column1: '250х250', Column2: '22,0', Column3: '83,31'},
                {Column1: '250х250', Column2: '24,0', Column3: '90,43'},
                {Column1: '250х250', Column2: '26,0', Column3: '97,46'},
                {Column1: '250х250', Column2: '28,0', Column3: '104,50'},

            ],
        }
    ],
    table: {
        1: {},
        2: {},
        3: {},
    },
    tableEn: {
        1: {},
        2: {},
        3: {},
    }
}
