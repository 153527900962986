import React from 'react';
import slide from '../../assets/img/main/1.jpg'
import slide2 from '../../assets/img/main/2.jpg'
import slide3 from '../../assets/img/main/3.jpg'
import Slider from 'infinite-react-carousel';

const settings = {
    dots: true,
    autoplay: true,
    autoplaySpeed: 3000,
    adaptiveHeight: true,
    accessibility: false,
    arrows: false,
    shift: 20
};

const Slide = () => {
    return <div className="content-form home-form">
        <div style={{width: 820}}>
            <Slider {...settings} >
                <div>
                    <img width={'100%'} height={360}  src={slide}/>
                </div>
                <div>
                    <img width={'100%'} height={360}  src={slide2}/>
                </div>
                <div>
                    <img width={'100%'} height={360}  src={slide3}/>
                </div>
            </Slider>
        </div>
    </div>
};

export default Slide
