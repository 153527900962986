import React from 'react'
import img1 from '../../assets/img/catalog/shtrips1.jpg'
import img2 from '../../assets/img/catalog/shtrips2.jpg'
import img3 from '../../assets/img/catalog/shtrips3.jpg'
import {translate} from "react-i18next";

const styles = {
    rootWrapper: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: 20,
        marginBottom: 60,
        justifyContent: 'space-around'
    },
    root: {
        margin: 10
    },
    title: {
        color: '#213e7e',
        fontSize: 24,
        textDecoration: 'underline',
        fontWeight: 'bold'
    },
    desc: {
        color: '#84745b',
        marginBottom: 15,
        fontSize: 16
    },
    parameters: {
        fontWeight: 'bold'
    },
    img: {
        marginTop: 20,
        width: 240
    },
    description: {
        margin: 30
    },
    descriptionTitle: {
        fontWeight: 600
    },
    titleCenter: {
        textAlign: 'center',
        fontSize: 16,
        fontWeight: 'bold',
        marginTop: 15
    }
}

const Shtrips = (props) => {
    const {shtrips, t} = props;
    return <div style={styles.rootWrapper}>
        <div >
            <div >
                <h2 style={{color: '#213e7e'}}>{shtrips[t('shtrips.title')]}</h2>
            </div>
            <div>
                <img src={img1} style={styles.img}/>
                <img src={img2} style={styles.img}/>
                <img src={img3} style={styles.img}/>
            </div>
            <div style={styles.titleCenter}>{shtrips[t('shtrips.titleCenter')]}</div>
            <div style={styles.description}>{shtrips[t('shtrips.description')].map(d => <div style={styles.descriptionTitle}>{d}</div>)}</div>
        </div>
        {shtrips[t('shtrips.catalog')].map(s => <div style={styles.root}>
            <div style={styles.title}>
                {s.title}
            </div>
            <div style={styles.desc}>
                {s.desc}
            </div>
            <div style={styles.parameters}>
                Толщина {s.width}
            </div>
            <div style={styles.parameters}>
                Вес {s.weight}
            </div>
        </div>)}
    </div>
};

export default translate("common")(Shtrips)
