import React from "react";
import {profFlooring} from "../repository/profFlooring";
import DetailsProduct from "../ui/Catalog/DetailsProducts";


const ProfFlooringlController = (props) => {
    return <div>
            <DetailsProduct products={profFlooring} id={1}/>}
    </div>
};



export default ProfFlooringlController;
