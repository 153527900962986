import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import galleryPhoto from "../../assets/img/galleryPhoto.png";
import Slider from "infinite-react-carousel/lib";
import image1 from "../../assets/img/gallery/1.jpg";
import image2 from "../../assets/img/gallery/2.jpg";
import image3 from "../../assets/img/gallery/3.jpg";
import image4 from "../../assets/img/gallery/4.jpg";
import image5 from "../../assets/img/gallery/5.jpg";
import image6 from "../../assets/img/gallery/6.jpg";
import image7 from "../../assets/img/gallery/7.jpg";
import image8 from "../../assets/img/gallery/8.jpg";
import image9 from "../../assets/img/gallery/9.jpg";
import image10 from "../../assets/img/gallery/10.jpg";
import image11 from "../../assets/img/gallery/11.jpg";
import image12 from "../../assets/img/gallery/12.jpg";
import image13 from "../../assets/img/gallery/13.jpg";
import image14 from "../../assets/img/gallery/14.jpg";
import image15 from "../../assets/img/gallery/15.jpg";
import image16 from "../../assets/img/gallery/16.jpg";
import image17 from "../../assets/img/gallery/17.jpg";
import image18 from "../../assets/img/gallery/18.jpg";
import image19 from "../../assets/img/gallery/19.jpg";

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

export default function GalleryModal() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
                <img src={galleryPhoto}  onClick={handleOpen} style={{cursor: 'pointer'}}/>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <SlideImages/>
                    </div>
                </Fade>
            </Modal>
        </div>
    );
}

const settings = {
    dots: true,
    adaptiveHeight: true,
    accessibility: false,
    arrows: false,
    shift: 20
};
const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image10,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
    image17,
    image18,
    image19,
];

const SlideImages = () => {
    return <div className="content-form home-form">
        <div style={{width: 700}}>
            <Slider {...settings} >
                {images.map(image => {
                    return  <div>
                        <img width={'100%'} height={500}  src={image}/>
                    </div>
                })}
            </Slider>
        </div>
    </div>
};
