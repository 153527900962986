import React from "react";
import {connect} from "react-redux";
import Shtrips from "../ui/Catalog/Shtrips";


const ShtripsController = (props) => {
    return <div>
        <Shtrips shtrips={props.shtrips}/>
    </div>
};

const mapState = (state) => {
    return {
        shtrips: state.catalogPage.shtrips
    }
};

export default connect(mapState, null)(ShtripsController);
