import React from 'react';
import GalleryModal from "./GalleryModal";
import {translate} from "react-i18next";

const Gallery = (props) => {
    return <div className="content-form home-form">
        <div className="content-header b-b">
            <h2 className="content-header__title content-header__title--centered-sm">{props.t('gallery.title')}</h2>
        </div>
        <div className="home-services">
            <div>
                <GalleryModal/>

            </div>
            <div/>
            <div>
                <ul>
                    <li>{props.t('gallery.li1')}</li>
                    <li>{props.t('gallery.li2')}</li>
                    <li>{props.t('gallery.li3')}</li>
                    <li>{props.t('gallery.li4')}</li>

                </ul>
            </div>
        </div>

    </div>
};


export default translate("common")(Gallery)
