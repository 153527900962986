import React from 'react'
import welded1 from '../../assets/img/catalog/welded100x100.PNG'
import welded2 from '../../assets/img/catalog/welded150x150.PNG'
import welded3 from '../../assets/img/catalog/welded200x200.PNG'
import {translate} from "react-i18next";
import WeldedDetails from "./WeldedDetails";

const WeldedGrid = (props) => {
    const {t, data} = props;
    return <div className={"content-form__list"}>
        <div style={{
            display: "flex",
            flexDirection: 'column',
            marginBottom: 30,
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{marginLeft: 20, marginRight: 20}}>
                <h2>{data[t('catalog.title')]}</h2>
                <p>{data[t('catalog.description')]}</p>
            </div>
            <div style={{textAlign: "center", width: 350, justifyContent: 'center', alignItems: 'center'}}>
                <h3>{data[t('catalog.titleCenter')]}</h3>
            </div>
            <div style={{display: "flex", marginBottom: 30, marginRight: 20}}>
                <div>
                    <img style={{width: 230}} src={welded1} alt=""/>
                    <img style={{width: 230}} src={welded2} alt=""/>
                    <img style={{width: 230}} src={welded3} alt=""/>
                </div>
            </div>

        </div>
        <div style={{marginBottom: 50, marginLeft: 20, marginRight: 20}}>
            <WeldedDetails details={data.details}/>
        </div>
    </div>
};

export default translate("common")(WeldedGrid);
