import React from "react";
import {ListWrapper} from "../ui/Catalog";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {corner} from "../repository/corner";
import DetailsProduct from "../ui/Catalog/DetailsProducts";


const CornerController = (props) => {
    return <div>
        {!props.match.params.id
            ? <ListWrapper {...props} url={'corner'}/>
            : <DetailsProduct products={corner} id={props.match.params.id}/>}
    </div>
};

const mapState = (state) => {
   return {
       data: state.catalogPage.corner
   }
};

export default withRouter(connect(mapState, null)(CornerController))
