import React from 'react';
import {advantages} from '../../repository'
import {translate} from "react-i18next";

const Advantages = (props) => {
    const {t} = props;
    return <div>
        <div className="content-header b-b">
            <h2 className="content-header__title content-header__title--centered-sm">{t("advantages.titleRoot")}</h2>
        </div>
        <div className="home-services">
            {advantages.map(( d, index) => (
                <div className="home-services__card" key={index}>
                    <div className="home-services__card-icon">
                        <i className={d.icon}/>
                    </div>
                    <p className="home-services__card-title">{d[t("advantages.title")]}</p>
                    <p className="home-services__card-info text-sm">{d[t("advantages.text")]}
                    </p>
                </div>
            ))}
        </div>
    </div>
};


export default translate("common")(Advantages)
