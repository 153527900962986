import React from "react";
import {translate} from "react-i18next";

const Contacts = (props) => {
    return <div>
        <h4>{props.t("footer.contacts").toUpperCase()}</h4>
        <div>
            <span className={"icon-email"}/><a href={"mailto:sales@maximaltd.kz"}><p>sales@maximaltd.kz</p></a>
        </div>
        <div>
            <span className={"icon-email"}/><a href={"mailto:sales2@maximaltd.kz"}><p>sales2@maximaltd.kz</p></a>
        </div>
        <div>
            <span className="icon-phone"/><p>+7(705)791-60-11 <a href={"https://wa.me/77057916011"} target={'_blank'}
                                                                 style={{
                                                                     color: '#25D366',
                                                                     cursor: 'pointer'
                                                                 }}>(WhatsApp)</a></p>

        </div>
        <div>
            <span className="icon-phone"/><p>+7(7122)76-64-96</p>
        </div>
        <div>
            <span className="icon-phone"/><p>+7(778)298-27-08</p>
        </div>
        <div>
            <span className="icon-skype"/><p><a style={{color: '#00aff0', cursor: 'pointer'}}
                                                href={'Skype:MAXIMA LTD stockholder?chat'}>MAXIMA LTD stockholder</a>
        </p>
        </div>
        <div style={{display: 'flex', flexDirection: 'column', color: '#213e7e'}}>
            <span style={{fontWeight: 'bold', fontFamily: 'Verdana', fontSize: 12,}}>{props.t("contacts.address1")}</span>
            <div style={{fontFamily: 'Verdana', fontSize: 13, fontWeight: 600}}>{props.t("contacts.address2")}
            </div>
            <div style={{fontFamily: 'Verdana', fontSize: 12, fontWeight: 600}}>{props.t("contacts.address3")}</div>
        </div>
    </div>
};

export default translate("common")(Contacts)


