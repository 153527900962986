import React from 'react';
import {Advantages, Gallery} from "./";

const HomePage = () => {
    return <div className="content-form home-form">
        <Advantages/>
        <Gallery/>
    </div>
};


export default HomePage