import React, {Fragment} from 'react'
import {NavLink, withRouter} from "react-router-dom";
import '../../resources/less/styles.less'
import {translate} from "react-i18next";

const Navbar = (props) => {
    const {classes, i18n, location, t} = props;
    return <Fragment>
        <ul className="header-menu js-sidebar">
            <li className={location.pathname === '/about' ? "header-menu__item active" : "header-menu__item js-submenu-toggler"}>
                <NavLink exact to={'/about'} className="header-menu__link">
                    <i className="icon-home"/>
                    <span>{t('navbar.about').toUpperCase()}</span>
                </NavLink>
            </li>
            <li className={location.pathname === '/services' ? "header-menu__item active" : "header-menu__item js-submenu-toggler"}>
                <NavLink exact to={'/services'} className="header-menu__link">
                    <i className="icon-home"/>
                    <span>{t('navbar.service').toUpperCase()}</span>
                </NavLink>
            </li>
            <li className={location.pathname === '/certificates' ? "header-menu__item active" : "header-menu__item js-submenu-toggler"}>
                <NavLink exact to={'/certificates'} className="header-menu__link">
                    <i className="icon-home"/>
                    <span>{t('navbar.certificates').toUpperCase()}</span>
                </NavLink>
            </li>
            <li className={location.pathname === '/delivery' ? "header-menu__item active" : "header-menu__item js-submenu-toggler"}>
                <NavLink to={'/delivery'} className="header-menu__link">
                    <i className="icon-apps"/>
                    <span>{t('navbar.shiping').toUpperCase()}</span>
                </NavLink>
            </li>
            <li className={location.pathname === '/contacts' ? "header-menu__item active" : "header-menu__item js-submenu-toggler"}>
                <NavLink to={'/contacts'} className="header-menu__link">
                    <i className="icon-services"/>
                    <span>{t('navbar.contacts').toUpperCase()}</span>
                </NavLink>
            </li>
        </ul>

    </Fragment>
};


const NavbarContainer = (props) => {
    return <Navbar {...props}/>
};

export default translate("common")(withRouter(NavbarContainer));


