import React from "react";
import {NavLink} from "react-router-dom";
import {translate} from "react-i18next";

const Categories = (props) => {
    const {t} = props;
    return <div>
        <h4>{t("footer.category").toUpperCase()}</h4>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/blacksteel'}><p>{t('menuTitle.blacksteel')}</p></NavLink></div>
        </div>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/sheetsteel'}><p>{t('menuTitle.sheetsteel')}</p></NavLink></div>
        </div>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/nocorrosionsteel'}><p>{t('menuTitle.nocorrosionsteel')}</p></NavLink></div>
        </div>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/weldedgrid'}><p>{t('menuTitle.weldedgrid')}</p></NavLink></div>
        </div>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/flooring'}><p>{t('menuTitle.flooring')}</p></NavLink></div>
        </div>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/panel'}><p>{t('menuTitle.panel')}</p></NavLink></div>
        </div>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/prof-flooring'}><p>{t('menuTitle.prof-flooring')}</p></NavLink></div>
        </div>
        <div>
            <span className={"icon-nav"}/>
            <div><NavLink to={'/shtrips'}><p>{t('menuTitle.shtrips')}</p></NavLink></div>
        </div>
    </div>
};

export default translate("common")(Categories)
